import { useQueryClient } from "@tanstack/react-query";
import { Box, Stack } from "@vapor/react-material";

import { setupActions } from "../../../../core/domain/AccountingSetup/queries";
import AccountToolbar from "../../AccountToolbar";
import Accounting from "./Accounting";
import Header from "./Header";

interface CA21Props {
    draftId: string;
}

const CA21 = ({ draftId }: CA21Props) => {
    const queryClient = useQueryClient();

    const handleModify = () => {
        console.log("TODO!!");
    };

    return (
        <Stack gap={3}>
            <Header draftId={draftId} />
            <Accounting draftId={draftId} />
            <Box ml={-2}>
                <AccountToolbar
                    draftId={draftId}
                    onAccount={() =>
                        queryClient.invalidateQueries(setupActions.all)
                    }
                    onModify={handleModify}
                    onDelete={() =>
                        queryClient.invalidateQueries(setupActions.all)
                    }
                />
            </Box>
        </Stack>
    );
};

export default CA21;
