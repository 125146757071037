import { Tab, Tabs } from "@vapor/react-extended";
import { Box } from "@vapor/react-material";
import { ReactNode, useState } from "react";
import { useBusinesses } from "../../../../../core/contexts/Businesses";
import { WorkspaceSettlement } from "../../../../../core/domain/Vat/dto";

interface BusinessSettlementSelectorProps {
    settlement: WorkspaceSettlement;
    children: (businessId: string) => ReactNode;
}

const BusinessSettlementSelector = (props: BusinessSettlementSelectorProps) => {
    const { businesses } = useBusinesses();

    const [defaultSelected] = businesses;

    if (!defaultSelected) {
        throw new Error("Business data missing");
    }

    const [selectedId, setSelectedId] = useState(defaultSelected.id);

    const handleChange = (_: unknown, id: string) => {
        setSelectedId(id);
    };

    return (
        <>
            <Box px={2}>
                <Tabs
                    size="extraSmall"
                    value={selectedId}
                    onChange={handleChange}
                >
                    {businesses.map((business) => (
                        <Tab
                            key={business.id}
                            value={business.id}
                            label={business.description}
                        />
                    ))}
                </Tabs>
            </Box>

            <Box pt={2}>{props.children(selectedId)}</Box>
        </>
    );
};

export default BusinessSettlementSelector;
