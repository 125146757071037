import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Divider, Stack } from "@vapor/react-material";

import { useRegisters } from "../../../../core/domain/Registers/queries";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../../components/ErrorBanner";
import Creatable from "./Creatable";
import Deleted from "./Deleted";
import Generated from "./Generated";
import Disabled from "./Disabled";

const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");

interface RegistersProps {
    year: string;
}

export default function Registers({ year }: RegistersProps) {
    const { data, isLoading, isSuccess, error } = useRegisters({ year });

    if (isLoading) {
        return (
            <Spinner loading={isLoading}>
                <Box height={10} marginTop={20} />
            </Spinner>
        );
    }
    if (!isSuccess) {
        return error && <ErrorBanner error={error} />;
    }

    const register = data[0];

    return (
        <Stack padding={2}>
            <Typography variant="titleSmall">{fs("VAT")}</Typography>
            <Divider orientation="horizontal" variant="fullWidth" light />
            <Stack direction="row">
                <Stack width="100%">
                    <Typography variant="body500" marginTop={2}>
                        {fs("vatRegisters")}
                    </Typography>
                    {!register && <Disabled />}
                    <Generated generated={register?.generated} year={year} />
                    <Deleted deleted={register?.deleted} />
                    <Creatable creatable={register?.creatable} year={year} />
                </Stack>
            </Stack>
        </Stack>
    );
}
