import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import isAfter from "date-fns/isAfter";
import isValid from "date-fns/isValid";
import startOfYear from "date-fns/startOfYear";

import getYear from "date-fns/getYear";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import "./style.css";

const fs = getFormattedStringWithScope("components.DateRangePicker");

export interface DateRangePickerState {
    startDate: Date | null;
    endDate: Date | null;
    error: boolean;
}

export const defaultState: DateRangePickerState = {
    startDate: startOfYear(new Date()),
    endDate: new Date(),
    error: false
};

function isYearMoreThanThreeChar(date: Date): boolean {
    return getYear(date) > 1000;
}

interface DateRangePickerProps {
    startDate: Date | null;
    endDate: Date | null;
    validate: (date: Date) => boolean;
    onChange?: (dateRange: DateRangePickerState) => void;
    onChangeStartDate?: (dateRange: Date) => void;
    onChangeEndDate?: (dateRange: Date) => void;
    disableFuture?: boolean;
    isRequired?: boolean;
    errorStartDate?: boolean;
    errorEndDate?: boolean;
    showLabels?: boolean;
    className?: string;
    separator?: string;
    readOnly?: boolean;
}

export default function DateRangePicker({
    startDate,
    endDate,
    onChange,
    onChangeStartDate,
    onChangeEndDate,
    validate,
    disableFuture,
    isRequired,
    errorStartDate,
    errorEndDate,
    showLabels = true,
    className,
    separator = "",
    readOnly
}: DateRangePickerProps) {
    const handleDisableFuture = (date: Date) => {
        return disableFuture && isAfter(date, new Date()) ? new Date() : date;
    };

    const handleStartDateChange = (date: Date) => {
        const isDateValid = isValid(date) && validate(date);

        let newStartDate = isDateValid ? handleDisableFuture(date) : startDate;
        let newEndDate = endDate;
        if (!date && onChange) {
            onChange({
                startDate: null,
                endDate: endDate,
                error: !isDateValid
            });
        }
        if (newStartDate && isYearMoreThanThreeChar(newStartDate) && date) {
            if (endDate && isAfter(newStartDate, endDate)) {
                newEndDate = newStartDate;
                newStartDate = endDate;
            }
            if (onChange) {
                onChange({
                    startDate: newStartDate,
                    endDate: newEndDate,
                    error: !isDateValid
                });
            }
            if (isDateValid && onChangeStartDate) {
                onChangeStartDate(date);
            }
        }
    };

    const handleEndDateChange = (date: Date) => {
        const isDateValid = isValid(date) && validate(date);
        let newStartDate = startDate;
        let newEndDate = isDateValid ? handleDisableFuture(date) : endDate;
        if (!date && onChange) {
            onChange({
                startDate: startDate,
                endDate: null,
                error: !isDateValid
            });
        }
        if (newEndDate && isYearMoreThanThreeChar(newEndDate) && date) {
            if (startDate && isAfter(startDate, newEndDate)) {
                newStartDate = newEndDate;
                newEndDate = startDate;
            }
            if (onChange) {
                onChange({
                    startDate: newStartDate,
                    endDate: newEndDate,
                    error: !isDateValid
                });
            }
            if (isDateValid && onChangeEndDate) {
                onChangeEndDate(date);
            }
        }
    };

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <FormControl required={isRequired} error={errorStartDate}>
                {showLabels && <InputLabel>{fs("selectStartDate")}</InputLabel>}
                <DatePicker
                    value={startDate}
                    onChange={(date: any) => handleStartDateChange(date)}
                    disableFuture={disableFuture}
                    className={className ?? ""}
                    slotProps={{
                        inputAdornment: {
                            tabIndex: -1
                        }
                    }}
                    readOnly={readOnly}
                />
            </FormControl>
            {separator}
            <FormControl required={isRequired} error={errorEndDate}>
                {showLabels && <InputLabel>{fs("selectEndDate")}</InputLabel>}
                <DatePicker
                    value={endDate}
                    onChange={(date: any) => handleEndDateChange(date)}
                    disableFuture={disableFuture}
                    className={className ?? ""}
                    slotProps={{
                        inputAdornment: {
                            tabIndex: -1
                        }
                    }}
                    readOnly={readOnly}
                />
            </FormControl>
        </Stack>
    );
}
