import { Typography } from "@vapor/react-extended";
import { Box, Card, Stack } from "@vapor/react-material";
import {
    CreditsType,
    WorkspaceSettlement
} from "../../../../../core/domain/Vat/dto";

interface WorkspaceSettlementDetailsProps {
    settlement: WorkspaceSettlement;
}

const CREDITS_TYPES = [
    "REGULAR_CREDIT",
    "REGULAR_DEBT",
    "PRIOR_REGULAR_CREDIT",
    "PRIOR_REGULAR_DEBT"
] as const satisfies CreditsType[];

const CREDITS_LABELS = {
    REGULAR_CREDIT: "Credito corrente",
    REGULAR_DEBT: "Debito corrente",
    PRIOR_REGULAR_CREDIT: "Credito precedente",
    PRIOR_REGULAR_DEBT: "Debito precente"
} as const satisfies Record<CreditsType, string>;

const MainDetail = (props: { label: string; amount: number }) => {
    return (
        <Stack>
            <Typography variant="h5" fontWeight="600">
                <span>{`${props.amount.toFixed(2)} €`}</span>
            </Typography>
            <span>{props.label}</span>
        </Stack>
    );
};

const WorkspaceSettlementDetails = (props: WorkspaceSettlementDetailsProps) => {
    return (
        <Box p={2}>
            <Card variant="outlined" sx={{ padding: 2, width: "fit-content" }}>
                <Stack direction="row" gap={8}>
                    {CREDITS_TYPES.map((creditType) => {
                        return (
                            props.settlement.creditsAmounts &&
                            props.settlement.creditsAmounts[creditType] && (
                                <MainDetail
                                    key={creditType}
                                    label={CREDITS_LABELS[creditType]}
                                    amount={
                                        props.settlement.creditsAmounts[
                                            creditType
                                        ]
                                    }
                                />
                            )
                        );
                    }).filter(Boolean)}

                    <span>
                        <MainDetail
                            label={
                                props.settlement.normalizedAmount >= 0
                                    ? "IVA a debito"
                                    : "IVA a credito"
                            }
                            amount={Math.abs(props.settlement.normalizedAmount)}
                        />
                    </span>
                </Stack>
            </Card>
        </Box>
    );
};

export default WorkspaceSettlementDetails;
