import { OneBoxPage } from "@drift/oneplatfront";
import { useWorkspaceSettlements } from "../../../../core/domain/Vat/queries";
import Loader from "../../../components/Loader";
import BusinessSettlementDetails from "./business/settlement-details";
import BusinessSettlementSelector from "./business/settlement-selector";
import WorkspaceSettlementDetails from "./workspace/settlement-details";
import WorkspaceSettlementSelector from "./workspace/settlement-selector";
import WorkspaceSettlementTabs from "./workspace/settlement-tabs";
import WorkspaceSettlementToolbar from "./workspace/settlement-toolbar";

const VatSettlements = () => {
    const { data: settlements, isLoading } = useWorkspaceSettlements();

    return (
        <Loader loading={!settlements || isLoading}>
            <OneBoxPage title="Liquidazione IVA">
                <WorkspaceSettlementSelector settlements={settlements ?? []}>
                    {(settlement) => (
                        <>
                            <WorkspaceSettlementTabs>
                                {(view) => {
                                    if (view === "OTHERS") {
                                        return (
                                            <>not yet implemented - others</>
                                        );
                                    }

                                    if (view === "BUSINESS") {
                                        return (
                                            <BusinessSettlementSelector
                                                settlement={settlement}
                                            >
                                                {(businessId) => (
                                                    <BusinessSettlementDetails
                                                        businessId={businessId}
                                                        period={
                                                            settlement.period
                                                        }
                                                    />
                                                )}
                                            </BusinessSettlementSelector>
                                        );
                                    }

                                    return (
                                        <WorkspaceSettlementDetails
                                            settlement={settlement}
                                        />
                                    );
                                }}
                            </WorkspaceSettlementTabs>
                            <WorkspaceSettlementToolbar
                                settlement={settlement}
                            />
                        </>
                    )}
                </WorkspaceSettlementSelector>
            </OneBoxPage>
        </Loader>
    );
};

export default VatSettlements;
