import { useCurrentWorkspace } from "@drift/oneplatfront";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BanksLookup } from "@onefront/workspace-forms";
import { IBankForm } from "@onefront/workspace-forms/typings/interfaces/BanksForm";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { CirclePlus } from "@vapor/react-icons";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCellProps,
    TableHead,
    TableRow,
    TableRowProps,
    useTheme,
    TableCell as VaporTableCell
} from "@vapor/react-material";
import { debounce, every, isEmpty, isNil, some, toPairs } from "lodash";
import { useEffect, useState } from "react";

import {
    useAccountingLines,
    useCreateAccountingLine,
    useCreateBankAccount,
    useDeleteAccountingLineField,
    useMutateAccountingLine
} from "../../../../core/domain/AccountingLines/queries";
import { UseAccountsOptions } from "../../../../core/domain/Accounts/queries";
import { useCanCreateAccountingLines } from "../../../../core/domain/Draft/hooks";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { useVatDestinations } from "../../../../core/domain/VatDestinations/queries";
import { AccountingLine } from "../../../../core/usecases/dtos/AccountingLinesDto";
import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import { ExemptionNature } from "../../../../core/usecases/dtos/ExemptionNaturesDto";
import { VatDestination } from "../../../../core/usecases/dtos/VatDestinationsDto";
import { VatRegime } from "../../../../core/usecases/dtos/VatRegimesDto";
import { AccountingGroup } from "../../../../core/usecases/interfaces/AccountingGroup";
import { AccountType, VatRate } from "../../../../utils/appEnums";
import { isReadOnly, isXML } from "../../../../utils/draftUtils";
import {
    hasAccountingLines,
    isVat,
    needsCompetence
} from "../../../../utils/reasonUtils";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import { isReverseCharge } from "../../../../utils/vatUtils";
import CurrencyField from "../../CurrencyField";
import CustomButton from "../../CustomButton";
import FormattedAmount from "../../FormattedAmount";
import { SmartSelectVatRegime } from "../../SelectVatRegime";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import AccountDetailButton from "./AccountDetailButton";
import AddPaymentButton from "./AddPaymentButton";
import ArticleActions from "./ArticleActions";
import AccountCell from "./cells/AccountCell";
import VatCompetencePeriodCell from "./cells/VatCompetencePeirodCell";
import DeleteButton from "./DeleteButton";
import groupAccountingLines from "./groupAccountingLines";
import SelectAccount from "./SelectAccount";
import SelectVatDestination from "./SelectVatDestination";
import SelectVatRate from "./SelectVatRate";
import {
    useAddAccountToDraft,
    useAddedAccounts,
    useClearAddedAccounts,
    useRemoveAccountFromDraft
} from "./state";
import { ArticleDescription, BanksLookupContainer } from "./styled";

const TableCell = (props: TableCellProps) => (
    <VaporTableCell {...props} size="small" />
);

const ValueHeadCell = (props: { text: string }) => (
    <TableHeadCell align="right">
        <TableHeadText>{props.text}</TableHeadText>
    </TableHeadCell>
);

const ValueCell = (props: TableCellProps) => (
    <TableCell {...props} align="right" />
);

const InnerTableRow = (props: TableRowProps) => {
    const theme = useTheme();

    return (
        <TableRow
            {...props}
            sx={{
                ...props.sx,
                bgcolor: theme.palette.primary.primary10
            }}
        />
    );
};

type VatRegimeCellProps = {
    draftId: string;
    accountingLine: AccountingLine;
    readOnly?: boolean;
};

const VatRegimeCell = ({
    draftId,
    accountingLine,
    readOnly = false
}: VatRegimeCellProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    const { mutateAsync } = useMutateAccountingLine({
        accountingLineId: accountingLine.uuid!,
        draftId
    });
    const { mutateAsync: deleteFields } = useDeleteAccountingLineField({
        accountingLineId: accountingLine.uuid!,
        draftId
    });

    const handleSelectVatRegime = async (vatRegime: VatRegime) => {
        await mutateAsync({
            regimeId: vatRegime.id
        });
        await deleteFields(["regimeExtraField"]);
    };

    const handleSelectExtraField = (extraFieldValue: string | null) => {
        if (extraFieldValue) {
            mutateAsync({
                regimeExtraField: extraFieldValue
            });
        } else {
            deleteFields(["regimeExtraField"]);
        }
    };

    if (isSuccess) {
        const { reasonId } = draft;
        return (
            <TableCell>
                <SmartSelectVatRegime
                    onChange={handleSelectVatRegime}
                    onChangeExtraField={handleSelectExtraField}
                    value={accountingLine.regimeId}
                    extraFieldValue={accountingLine?.regimeExtraField ?? null}
                    reason={reasonId}
                    disabled={readOnly}
                    transparent
                    sx={{
                        minWidth: "136px"
                    }}
                />
            </TableCell>
        );
    }

    return null;
};

type VatRateCellProps = {
    draftId: string;
    accountingLine: AccountingLine;
    readOnly?: boolean;
};

const VatRateCell = ({
    draftId,
    accountingLine,
    readOnly: readOnlyProp = false
}: VatRateCellProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateAccountingLine({
        accountingLineId: accountingLine.uuid!,
        draftId
    });

    const handleSelectVatRate = (
        vatRate: VatRate,
        exemptionNature?: ExemptionNature
    ) => {
        mutate({
            vatRate: vatRate,
            exemptionNatureId: exemptionNature?.id,
            exemptionNature: exemptionNature?.nature
        });
    };

    const handleReverseChargeRateChange = (reverseChargeRate: VatRate) => {
        mutate({
            reverseChargeRate
        });
    };

    const value =
        Object.values(VatRate).find(
            (rate) => rate === accountingLine.vatRate
        ) ?? null;

    if (isSuccess) {
        const readOnly = readOnlyProp || isXML(draft);

        return (
            <TableCell>
                <SelectVatRate
                    draftId={draftId}
                    value={value}
                    onChange={handleSelectVatRate}
                    accountingLine={accountingLine}
                    reverseCharge={isReverseCharge(
                        accountingLine,
                        draft.doc.active,
                        draft.hasReverseCharge
                    )}
                    onReverseChargeRateChange={handleReverseChargeRateChange}
                    disabled={readOnly}
                />
            </TableCell>
        );
    }

    return null;
};

type VatDestinationCellProps = {
    draftId: string;
    accountingLine: AccountingLine;
    readOnly?: boolean;
};

const VatDestinationCell = ({
    draftId,
    accountingLine,
    readOnly = false
}: VatDestinationCellProps) => {
    const { data: draft } = useDraft(draftId);
    const {
        data: vatDestinations,
        isLoading,
        isSuccess
    } = useVatDestinations(draft?.reasonId);
    const { mutate } = useMutateAccountingLine({
        accountingLineId: accountingLine.uuid!,
        draftId
    });

    if (isLoading) {
        return (
            <TableCell>
                <Skeleton variant="rectangular" />
            </TableCell>
        );
    }

    if (isSuccess) {
        const value = vatDestinations.find(
            (destination) => destination.id === accountingLine.vatDestinationId
        );

        const handleVatDestinationChange = (vatDestination: VatDestination) => {
            mutate({
                vatDestinationId: vatDestination.id
            });
        };

        return (
            <TableCell>
                <SelectVatDestination
                    value={value}
                    vatDestinations={vatDestinations}
                    onChange={handleVatDestinationChange}
                    readOnly={readOnly}
                />
            </TableCell>
        );
    }

    return null;
};

type ArticleDescCellProps = {
    draftId: string;
    accountingLine: AccountingLine;
    readOnly?: boolean;
};
const ArticleDescCell = ({
    draftId,
    accountingLine,
    readOnly = false
}: ArticleDescCellProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateAccountingLine({
        accountingLineId: accountingLine.uuid!,
        draftId
    });

    const handleArticleDescChange = (articleDesc: string) => {
        !isEmpty(articleDesc) &&
            mutate({
                articleDesc
            });
    };

    if (isSuccess) {
        if (isXML(draft) || readOnly) {
            return (
                <TableCell>
                    <Box ml={4}>
                        <Typography variant="body">
                            {accountingLine.articleDesc}
                        </Typography>
                    </Box>
                </TableCell>
            );
        }

        return (
            <TableCell>
                <Box ml={4}>
                    <ArticleDescription
                        value={accountingLine.articleDesc ?? ""}
                        onChange={handleArticleDescChange}
                    />
                </Box>
            </TableCell>
        );
    }

    return null;
};

type AccountingLineRowProps = {
    draftId: string;
    accountingLine: AccountingLine;
    readOnly?: boolean;
};
const AccountingLineRow = ({
    draftId,
    accountingLine,
    readOnly = false
}: AccountingLineRowProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (isSuccess) {
        const { reasonId } = draft;

        const isVatCompetenceVisible =
            needsCompetence(reasonId) &&
            accountingLine.accountType === AccountType.INCOME_STATEMENT &&
            isXML(draft);

        return (
            <InnerTableRow>
                <ArticleDescCell
                    accountingLine={accountingLine}
                    draftId={draftId}
                    readOnly={readOnly}
                />
                {isVat(reasonId) && (
                    <>
                        <VatRegimeCell
                            draftId={draftId}
                            accountingLine={accountingLine}
                            readOnly={readOnly}
                        />
                        <VatRateCell
                            draftId={draftId}
                            accountingLine={accountingLine}
                            readOnly={readOnly}
                        />
                        <VatDestinationCell
                            draftId={draftId}
                            accountingLine={accountingLine}
                            readOnly={readOnly}
                        />
                    </>
                )}
                <CreditAndDebitCells
                    accountingLineIds={accountingLine.uuid!}
                    draftId={draftId}
                    readOnly={readOnly}
                />
                <VatCompetencePeriodCell
                    accountingLineId={accountingLine.uuid!}
                    draftId={draftId}
                    isAccountingReadOnly={!!accountingLine.isReadOnly}
                    isVisible={isVatCompetenceVisible}
                    reason={reasonId}
                    endDate={accountingLine.endPeriodDate}
                    startDate={accountingLine.startPeriodDate}
                    readOnly={readOnly}
                />
                <TableCell>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {isXML(draft) && !readOnly && (
                            <ArticleActions
                                draftId={draftId}
                                accountingLineId={accountingLine.uuid!}
                            />
                        )}
                        {!readOnly && (
                            <DeleteButton
                                draftId={draftId}
                                accountingLineId={accountingLine.uuid!}
                            />
                        )}
                    </Stack>
                </TableCell>
            </InnerTableRow>
        );
    }

    return null;
};

export type AmountCellProps = {
    draftId: string;
    accountingLinesIds: string | string[];
    type: "debit" | "credit";
    readOnly?: boolean;
};
export const AmountCell = ({
    draftId,
    accountingLinesIds,
    type,
    readOnly: readOnlyProp = false
}: AmountCellProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);
    const { data: accountingLines } = useAccountingLines({
        draftId
    });
    const amounts = accountingLines?.filter((line) =>
        accountingLinesIds.includes(line.uuid!)
    );
    const amount = amounts?.reduce((acc, line) => acc + (line[type] || 0), 0);

    const { mutate } = useMutateAccountingLine({
        accountingLineId: accountingLinesIds,
        draftId
    });

    const handleAmountChange = debounce((value: number) => {
        mutate({
            [type]: value
        });
    }, 400);

    if (amount === undefined) return <TableCell></TableCell>;

    if (isSuccess) {
        const { refCurrency } = draft;
        const readOnly = readOnlyProp || isXML(draft);

        if (readOnly) {
            return (
                <TableCell size="small" align="right">
                    <FormattedAmount
                        variant="body"
                        amount={amount}
                        currency={refCurrency}
                        color={type === "credit" ? "primary.error" : undefined}
                    />
                </TableCell>
            );
        }

        return (
            <TableCell size="small" align="right">
                <CurrencyField
                    value={amount}
                    currency={refCurrency}
                    onChange={handleAmountChange}
                    transparent
                    red={type === "credit"}
                />
            </TableCell>
        );
    }

    return <TableCell></TableCell>;
};

type CreditAndDebitCellsProps = {
    draftId: string;
    accountingLineIds: string[] | string;
    readOnly: boolean;
};
export const CreditAndDebitCells = ({
    draftId,
    accountingLineIds,
    readOnly
}: CreditAndDebitCellsProps) => {
    const { data: allAccountingLines, isSuccess } = useAccountingLines({
        draftId
    });

    if (isSuccess) {
        const accountingLines = allAccountingLines.filter((line) =>
            accountingLineIds.includes(line.uuid!)
        );
        const isDebit = some(
            accountingLines,
            (accountingLine) => accountingLine.debit && accountingLine.debit > 0
        );

        const isZero = every(
            accountingLines,
            (accountingLine) =>
                !isNil(accountingLine.credit) &&
                accountingLine.credit === 0 &&
                !isNil(accountingLine.debit) &&
                accountingLine.debit === 0
        );

        if (isZero) {
            return (
                <>
                    <AmountCell
                        accountingLinesIds={accountingLineIds}
                        draftId={draftId}
                        type="debit"
                        readOnly={readOnly}
                    />
                    <AmountCell
                        accountingLinesIds={accountingLineIds}
                        draftId={draftId}
                        type="credit"
                        readOnly={readOnly}
                    />
                </>
            );
        } else if (isDebit) {
            return (
                <>
                    <AmountCell
                        accountingLinesIds={accountingLineIds}
                        draftId={draftId}
                        type="debit"
                        readOnly={readOnly}
                    />
                    <TableCell></TableCell>
                </>
            );
        } else {
            return (
                <>
                    <TableCell></TableCell>
                    <AmountCell
                        accountingLinesIds={accountingLineIds}
                        draftId={draftId}
                        type="credit"
                        readOnly={readOnly}
                    />
                </>
            );
        }
    }

    return null;
};

type AddAccountingLineButtonProps = {
    draftId: string;
    accountCode: string;
};
const AddAccountingLineButton = ({
    draftId,
    accountCode
}: AddAccountingLineButtonProps) => {
    const { mutate, isPending } = useCreateAccountingLine({ draftId });
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.AddAccountingLineButton"
    );

    return (
        <CustomButton
            startIcon={<CirclePlus />}
            onClick={() => mutate({ accountCode })}
            loading={isPending}
        >
            {fs("addAccountingLine")}
        </CustomButton>
    );
};

type AccountingGroupRowProps = {
    accountingGroup: AccountingGroup;
    isOpen: boolean;
    draftId: string;
    onToggleRow: () => void;
    readOnly?: boolean;
    restricted?: boolean;
};
const AccountingGroupRow = ({
    accountingGroup,
    isOpen,
    draftId,
    onToggleRow,
    readOnly = false,
    restricted = false
}: AccountingGroupRowProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (isSuccess) {
        const { reasonId } = draft;
        const accountingLinesIds = accountingGroup.children.map(
            (line) => line.uuid!
        );
        const isVatCompetenceVisible =
            needsCompetence(reasonId) &&
            accountingGroup.type === AccountType.INCOME_STATEMENT &&
            !isXML(draft);

        const canAddAccountingLine =
            !isXML(draft) && !readOnly && !accountingGroup.isReadOnly;

        return (
            <>
                <TableRow>
                    <AccountCell
                        accountingGroup={accountingGroup}
                        draftId={draftId}
                        isOpen={isOpen}
                        onToggleRow={onToggleRow}
                        readOnly={readOnly}
                    />
                    {/* for alignment */}
                    {isVat(reasonId) && (
                        <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </>
                    )}
                    <CreditAndDebitCells
                        accountingLineIds={accountingLinesIds}
                        draftId={draftId}
                        readOnly={
                            isXML(draft) ||
                            accountingGroup.isReadOnly ||
                            hasAccountingLines(reasonId) ||
                            readOnly
                        }
                    />
                    {needsCompetence(reasonId) && (
                        <VatCompetencePeriodCell
                            accountingLineId={accountingLinesIds}
                            draftId={draftId}
                            isAccountingReadOnly={!!accountingGroup.isReadOnly}
                            isVisible={isVatCompetenceVisible}
                            reason={reasonId}
                            // we take the first accounting line's value because we assume
                            // that all the accounting lines in the group have the same value
                            // in case this is a "manual" registration
                            startDate={
                                accountingGroup.children[0]?.startPeriodDate
                            }
                            endDate={accountingGroup.children[0]?.endPeriodDate}
                            readOnly={readOnly}
                        />
                    )}
                    <TableCell>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {!restricted && (
                                <AccountDetailButton
                                    draftId={draftId}
                                    accountCode={accountingGroup.accountCode}
                                    readOnly={readOnly}
                                />
                            )}
                            {(!hasAccountingLines(draft.reasonId) ||
                                accountingGroup.children[0].isBank) && (
                                <DeleteButton
                                    draftId={draftId}
                                    // when an accounting group can be deleted, it only has 0 or 1 accounting line associated
                                    accountingLineId={
                                        accountingGroup.children[0].uuid!
                                    }
                                />
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
                {isOpen &&
                    accountingGroup.children.map((accountingLine, index) => (
                        <AccountingLineRow
                            key={index}
                            draftId={draftId}
                            accountingLine={accountingLine}
                            readOnly={readOnly}
                        />
                    ))}
                {canAddAccountingLine && isOpen && (
                    <InnerTableRow>
                        <TableCell colSpan={8}>
                            <Box ml={3}>
                                <AddAccountingLineButton
                                    accountCode={accountingGroup.accountCode}
                                    draftId={draftId}
                                />
                            </Box>
                        </TableCell>
                    </InnerTableRow>
                )}
            </>
        );
    }
    return null;
};

type AddedAccountsProps = {
    draftId: string;
    readOnly?: boolean;
};
const AddedAccounts = ({ draftId, readOnly }: AddedAccountsProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);
    const { data: accountingLines } = useAccountingLines({ draftId });
    const addedAccounts = useAddedAccounts();
    const clearAddedAccounts = useClearAddedAccounts();
    const removeAddedAccountFromDraft = useRemoveAccountFromDraft();

    useEffect(() => {
        return () => {
            clearAddedAccounts();
        };
    }, [clearAddedAccounts]);

    useEffect(() => {
        if (!accountingLines) {
            return;
        }

        const addedAccountCodes = addedAccounts.map((account) => account.code);
        accountingLines.forEach((line) => {
            if (addedAccountCodes.includes(line.accountCode)) {
                removeAddedAccountFromDraft(line.accountCode);
            }
        });
    }, [accountingLines, addedAccounts, removeAddedAccountFromDraft]);

    if (!isSuccess || readOnly) {
        return null;
    }

    if (!isXML(draft)) {
        return null;
    }

    return (
        <>
            {addedAccounts.map((account) => (
                <TableRow key={`added-${account.code}`}>
                    <AccountCell
                        accountingGroup={{
                            accountCode: account.code,
                            accountDesc: account.description ?? "",
                            children: [],
                            type: AccountType.BALANCE_SHEET,
                            isReadOnly: false,
                            credit: 0,
                            debit: 0
                        }}
                        draftId={draftId}
                        isOpen={false}
                        onToggleRow={() => {}}
                        readOnly={true}
                    />
                    <TableCell colSpan={100} align="right">
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                                removeAddedAccountFromDraft(account.code)
                            }
                        >
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                color="primary"
                            />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

type AddTemporaryAccountButtonProps = {
    draftId: string;
};
const AddTemporaryAccountButton = ({
    draftId
}: AddTemporaryAccountButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.AddAccountButton"
    );
    const addAccountToDraft = useAddAccountToDraft();

    if (open) {
        const handleAddAccount = (account: AccountDto) => {
            addAccountToDraft(account);
            setOpen(false);
        };

        return (
            <Box width="500px">
                <SelectAccount
                    draftId={draftId}
                    onChange={handleAddAccount}
                    hideCustomerSupplier
                    autoFocus
                />
                <Button
                    size="small"
                    onClick={() => setOpen(false)}
                    sx={{ mt: 1 }}
                >
                    {fs("cancelAddAccount")}
                </Button>
            </Box>
        );
    }

    return (
        <CustomButton startIcon={<CirclePlus />} onClick={() => setOpen(true)}>
            {fs("account")}
        </CustomButton>
    );
};

interface AddAccountButtonProps
    extends Omit<UseAccountsOptions, "searchString"> {
    draftId: string;
}

export const AddAccountButton = ({
    draftId,
    ...options
}: AddAccountButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const canCreateAccountingLines = useCanCreateAccountingLines(draftId);
    const { mutate, isPending } = useCreateAccountingLine({ draftId });
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.AddAccountButton"
    );

    if (!canCreateAccountingLines) {
        return <AddTemporaryAccountButton draftId={draftId} />;
    }

    if (open) {
        const handleAddAccount = (accountCode: string) => {
            mutate({ accountCode });
            setOpen(false);
        };

        return (
            <Box width="500px">
                <SelectAccount
                    draftId={draftId}
                    onChange={({ code }) => handleAddAccount(code)}
                    hideCustomerSupplier
                    autoFocus
                    {...options}
                />
                <Button
                    size="small"
                    onClick={() => setOpen(false)}
                    sx={{ mt: 1 }}
                >
                    {fs("cancelAddAccount")}
                </Button>
            </Box>
        );
    }

    return (
        <CustomButton
            startIcon={<CirclePlus />}
            onClick={() => setOpen(true)}
            loading={isPending}
        >
            {fs("account")}
        </CustomButton>
    );
};

type AddBankAccountButtonProps = {
    draftId: string;
};
const AddBankAccountButton = ({ draftId }: AddBankAccountButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const { mutateAsync, isPending } = useCreateBankAccount(draftId);
    const canAddBankAccount = useCanCreateAccountingLines(draftId);
    const { id: workspaceId } = useCurrentWorkspace(true).workspace || {
        id: ""
    };
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.AddBankAccountButton"
    );

    if (!canAddBankAccount) {
        return null;
    }

    if (open) {
        const handleSelectBank = async (bank: IBankForm | null) => {
            if (!bank || !bank.id) return;

            setOpen(false);
            await mutateAsync(bank.id);
        };

        return (
            <BanksLookupContainer mb={2}>
                <BanksLookup
                    label={""} // purposefully hide label
                    workspaceId={workspaceId}
                    // by providing workspaceId, this has to be null
                    // otherwise the component throws an error
                    cslId={null}
                    editing={open}
                    onSelect={handleSelectBank}
                    onSubmit={handleSelectBank}
                    width="500px"
                    paperWidth="500px"
                />
                <Button
                    size="small"
                    onClick={() => setOpen(false)}
                    sx={{ mt: 1 }}
                >
                    {fs("cancelAddBank")}
                </Button>
            </BanksLookupContainer>
        );
    }

    return (
        <CustomButton
            startIcon={<CirclePlus />}
            onClick={() => setOpen(true)}
            loading={isPending}
        >
            {fs("bank")}
        </CustomButton>
    );
};

type TotalCellProps = {
    draftId: string;
    type: "debit" | "credit";
};
export const TotalCell = ({ type, draftId }: TotalCellProps) => {
    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);
    const { data: accountingLines, isSuccess: isAccountingLinesSuccess } =
        useAccountingLines({ draftId });

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const { refCurrency } = draft;

        const total = accountingLines.reduce(
            (acc, accountingLine) => acc + (accountingLine[type] || 0),
            0
        );

        return (
            <ValueCell>
                <FormattedAmount
                    variant="bodyLarge700"
                    amount={total}
                    currency={refCurrency}
                    color={
                        type === "credit"
                            ? "primary.baseCriticalColor"
                            : undefined
                    }
                />
            </ValueCell>
        );
    }

    return null;
};

type TableProps = {
    draftId: string;
    restricted?: boolean;
    readOnly?: boolean;
};
export const AccountingLinesTable = ({
    draftId,
    restricted = false,
    readOnly: readOnlyProp = false
}: TableProps) => {
    const {
        data: draft,
        isSuccess: isDraftSuccess,
        isLoading: isLoadingDraft
    } = useDraft(draftId);
    const {
        data: accountingLines,
        isSuccess: isAccountingLinesSuccess,
        isLoading: isLoadingAccountingLines
    } = useAccountingLines({ draftId });
    const [openRowsIds, setOpenRowsIds] = useState<string[]>([]);
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable"
    );

    const handleToggleRow = (id: string) => {
        if (openRowsIds.includes(id)) {
            setOpenRowsIds(openRowsIds.filter((rowId) => rowId !== id));
        } else {
            setOpenRowsIds([...openRowsIds, id]);
        }
    };

    if (isLoadingDraft || isLoadingAccountingLines) {
        return (
            <Box m="auto">
                <CircularProgress />
            </Box>
        );
    }

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const { reasonId } = draft;
        const readOnly = isReadOnly(draft.status) || readOnlyProp;
        const accountingGroups = groupAccountingLines(accountingLines, {
            sort: true,
            xml: isXML(draft),
            includePreviousBalance: true
        });

        return (
            <>
                <Table>
                    <TableHead shadow>
                        <TableHeadCell>
                            <TableHeadText>{fs("account")}</TableHeadText>
                        </TableHeadCell>
                        {isVat(reasonId) && (
                            <>
                                <TableHeadCell>
                                    <TableHeadText>
                                        {fs("vatRegime")}
                                    </TableHeadText>
                                </TableHeadCell>
                                <TableHeadCell>
                                    <TableHeadText>
                                        {fs("vatRate")}
                                    </TableHeadText>
                                </TableHeadCell>
                                <TableHeadCell>
                                    <TableHeadText>
                                        {fs("vatDestination")}
                                    </TableHeadText>
                                </TableHeadCell>
                            </>
                        )}
                        <ValueHeadCell text="Dare" />
                        <ValueHeadCell text="Avere" />
                        {needsCompetence(reasonId) && (
                            <TableHeadCell>
                                <TableHeadText>
                                    {fs("competencePeriod")}
                                </TableHeadText>
                            </TableHeadCell>
                        )}
                        <TableHeadCell></TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {toPairs(accountingGroups).map(
                            ([key, accountingGroup], index) => {
                                const isRowOpen =
                                    !isNil(key) && openRowsIds.includes(key);

                                return (
                                    <AccountingGroupRow
                                        key={index}
                                        isOpen={isRowOpen}
                                        accountingGroup={accountingGroup}
                                        onToggleRow={() =>
                                            handleToggleRow(key!)
                                        }
                                        draftId={draftId}
                                        readOnly={readOnly}
                                        restricted={restricted}
                                    />
                                );
                            }
                        )}
                        <AddedAccounts draftId={draftId} readOnly={readOnly} />
                        <TableRow sx={{ height: "48px !important" }}>
                            <TableCell></TableCell>
                            {isVat(reasonId) && (
                                <>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </>
                            )}
                            <TotalCell type="debit" draftId={draftId} />
                            <TotalCell type="credit" draftId={draftId} />
                            {needsCompetence(reasonId) && (
                                <TableCell></TableCell>
                            )}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {!readOnly && (
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        mt={-5.5}
                        spacing={2}
                    >
                        <AddAccountButton draftId={draftId} />
                        {!restricted && (
                            <AddBankAccountButton draftId={draftId} />
                        )}
                        {!restricted && draft.hasPayment && (
                            <AddPaymentButton draftId={draftId} />
                        )}
                    </Stack>
                )}
            </>
        );
    }

    return null;
};
