import { BusinessesProvider } from "../../../core/contexts/Businesses";
import WithholdingsProvider from "../../../core/contexts/Withholdings";
import routes from "../../commons/routes";
import AppTitle from "../../components/AppTitle";
import ViewWrapper from "../../components/ViewWrapper";
import AccountingPeriods from "../AccountingPeriods/Registers";
import AccountingSetup from "../AccountingSetup";
import AssetsList from "../AssetsList";
import Balance from "../Balance";
import ChartAccounts from "../ChartAccounts";
import Dashboard from "../Dashboard";
import Error from "../Error";
import Home from "../Home";
import Ledger from "../Ledger";
import NewRegistration from "../NewRegistration";
import SettleAccount from "../SettleAccount";
import UsedGoodsList from "../UsedGoodsList";
import VAT from "../VAT";
import VatSettlements from "../VAT/vat-settlements";
import Withholdings from "../Withholdings";
import "./style.css";

export const Root = () => [
    {
        target: "$ONE_TOOLBAR_CONTENT_LEFT",
        handler: {
            component: AppTitle
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.home(),
            element: (
                <ViewWrapper>
                    <Home />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.balance(),
            element: (
                <ViewWrapper>
                    <Balance />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.vat(),
            element: (
                <ViewWrapper>
                    <VAT />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.ledger(),
            element: (
                <ViewWrapper>
                    <Ledger />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.chartAccounts(),
            element: (
                <ViewWrapper>
                    <ChartAccounts />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.dashboard(),
            element: (
                <ViewWrapper>
                    <Dashboard />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.assetsList(),
            element: (
                <ViewWrapper>
                    <AssetsList />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.withholdings(),
            element: (
                <ViewWrapper>
                    <WithholdingsProvider>
                        <Withholdings />
                    </WithholdingsProvider>
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.usedGoods(),
            element: (
                <ViewWrapper>
                    <UsedGoodsList />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.accountingPeriods(),
            element: (
                <ViewWrapper>
                    <AccountingPeriods />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.newRegistration(),
            element: (
                <ViewWrapper>
                    <NewRegistration />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.settleAccount(),
            element: (
                <ViewWrapper>
                    <SettleAccount />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.accountingSetup(),
            element: (
                <ViewWrapper>
                    <AccountingSetup />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.error(),
            element: (
                <ViewWrapper>
                    <Error />
                </ViewWrapper>
            )
        }
    },
    {
        target: "$ONE_LAYOUT_ROUTE",
        handler: {
            exact: true,
            path: routes.vatSettlements(),
            element: (
                <ViewWrapper>
                    <BusinessesProvider>
                        <VatSettlements />
                    </BusinessesProvider>
                </ViewWrapper>
            )
        }
    }
];
