import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ASYNC_API_URL, POLLING_INTERVAL_MS } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";

import { useGet, useRequest } from "@onefront/react-sdk";

import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AvailableAccountingJournalsDto } from "../../usecases/dtos/AvailableAccountingJournal";
import { TaskStatus } from "../../usecases/dtos/TaskStatus";

interface UseGenerateAccountingJournal {
    generateAccountingJournal: (year: string) => Promise<void>;
}

interface UseCancelAccountingJournal {
    cancelAccountingJournal: (year: string) => Promise<void>;
}

const useAvailableAccountingJournal = () => {
    const customerData = useCustomerData();

    const { fetch: fetchData } = useGet<AvailableAccountingJournalsDto>(
        `${ASYNC_API_URL}/general-ledger/compliance-report`,
        {
            ...useAxiosConfigRequestParams("GetAccountingJournal"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: true
        }
    );

    const fetch = async (accountingPeriodId: string) => {
        return fetchData({
            params: {
                workspaceId: customerData.workspaceId,
                accountingPeriodId
            }
        }).then(({ data }) => data);
    };

    return {
        fetch
    };
};

const useGenerateAccountingJournal = (): UseGenerateAccountingJournal => {
    const customerData = useCustomerData();

    const { fetch: fetchData } = useRequest({
        method: "post",

        url: `${ASYNC_API_URL}/general-ledger/compliance-report`,
        ...useAxiosConfigRequestParams("GenerateAccountingJournal"),
        lazy: true
    });

    const generateAccountingJournal = (accountingPeriodId: string) => {
        return fetchData({
            data: {
                workspaceId: customerData.workspaceId,
                accountingPeriodId
            }
        });
    };

    return {
        generateAccountingJournal
    };
};

const useCancelAccountingJournal = (): UseCancelAccountingJournal => {
    const customerData = useCustomerData();

    const { fetch: fetchData } = useRequest({
        method: "delete",
        url: `${ASYNC_API_URL}/general-ledger/compliance-report`,
        ...useAxiosConfigRequestParams("CancelAccountingJournal"),
        lazy: true
    });

    const cancelAccountingJournal = (accountingPeriodId: string) => {
        return fetchData({
            params: {
                workspaceId: customerData.workspaceId,
                accountingPeriodId
            }
        });
    };

    return {
        cancelAccountingJournal
    };
};

export const accountingJournalKeys = createQueryKeys("accounting-journal", {
    all: null,
    availableAccountingJournal: (accountingPeriodId: string) => [
        accountingPeriodId
    ]
});

export const useAvailableJournal = (accountingPeriodId: string) => {
    const { fetch } = useAvailableAccountingJournal();
    return useQuery({
        ...accountingJournalKeys.availableAccountingJournal(accountingPeriodId),
        refetchInterval: ({ state }) => {
            const { data } = state;

            const generating = data?.generated?.status === TaskStatus.PENDING;
            const canceling = data?.deleted?.status === TaskStatus.PENDING;
            const pendingOperation = generating || canceling;

            return pendingOperation && POLLING_INTERVAL_MS;
        },
        queryFn: () => fetch(accountingPeriodId)
    });
};

export const useGenerateJournal = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetAccountingJournal"
    );
    const { generateAccountingJournal } = useGenerateAccountingJournal();

    return useMutation({
        mutationKey: ["generate-accounting-journal"],
        mutationFn: async (accountingPeriodId: string) => {
            return generateAccountingJournal(accountingPeriodId);
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingJournalKeys.availableAccountingJournal(
                    accountingPeriodId
                )
            );
        }
    });
};

export const useCancelJournal = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.CancelAccountingJournal"
    );
    const { cancelAccountingJournal } = useCancelAccountingJournal();

    return useMutation({
        mutationKey: ["cancel-accounting-journal"],
        mutationFn: async (accountingPeriodId: string) => {
            return cancelAccountingJournal(accountingPeriodId);
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingJournalKeys.availableAccountingJournal(
                    accountingPeriodId
                )
            );
        }
    });
};
