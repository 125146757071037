import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from "@vapor/react-material";

import { VaporTag, VaporTagProps } from "@vapor/react-custom";
import { ArrowLeft, ArrowRight } from "@vapor/react-icons";
import { ReactNode, useState } from "react";
import { WorkspaceSettlement } from "../../../../../core/domain/Vat/dto";

interface SettlementSelectorProps {
    settlements: Array<WorkspaceSettlement>;
    children: (settlement: WorkspaceSettlement) => ReactNode;
}

const ORDINAL_LABEL = {
    JANUARY: "Gennaio",
    FEBRUARY: "Febbraio",
    MARCH: "Marzo",
    APRIL: "Aprile",
    MAY: "Maggio",
    JUNE: "Giugno",
    JULY: "Luglio",
    AUGUST: "Agosto",
    SEPTEMBER: "Settembre",
    OCTOBER: "Ottobre",
    NOVEMBER: "Novembre",
    ADVANCE_MONTHLY: "Anticipo Dicembre",
    DECEMBER: "Dicembre",
    FIRST_QUARTER: "Primo trimestre",
    SECOND_QUARTER: "Secondo trimestre",
    THIRD_QUARTER: "Terzo trimestre",
    ADVANCE_QUARTERLY: "Anticipo Dicembre Trimestrali",
    FOURTH_QUARTER: "Quarto trimestre",
    ANNUAL: "Annuale"
} satisfies Record<WorkspaceSettlement["period"]["ordinal"], string>;

const STATE_TAG = {
    CLOSABLE: "russianViolet",
    CLOSED: "islamicGreen",
    CLOSING: "islamicGreen",
    JOURNALIZED: "maximumPurple",
    OPEN: "blueSapphire",
    OPENABLE: "islamicGreen",
    OPENING: "russianViolet"
} satisfies Record<WorkspaceSettlement["state"], VaporTagProps["type"]>;

const STATE_LABEL = {
    CLOSABLE: "Aperto, chiudibile",
    CLOSED: "Chiuso",
    CLOSING: "Chiusura in corso",
    JOURNALIZED: "Chiuso, con registro",
    OPEN: "Aperto",
    OPENABLE: "Chiuso, riapribile",
    OPENING: "Apertura in corso"
} satisfies Record<WorkspaceSettlement["state"], string>;

interface SettlementItemProps {
    period: WorkspaceSettlement["period"];
    state: WorkspaceSettlement["state"];
}

const SettlementItem = (props: SettlementItemProps) => {
    const periodLabel = `${ORDINAL_LABEL[props.period.ordinal]} ${
        props.period.year
    }`;

    return (
        <Stack
            direction="row"
            spacing={2}
            width="100%"
            sx={{
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <span>{periodLabel}</span>
            <VaporTag
                variant="standard"
                type={STATE_TAG[props.state]}
                label={STATE_LABEL[props.state]}
            />
        </Stack>
    );
};

const WorkspaceSettlementSelector = (props: SettlementSelectorProps) => {
    const { settlements = [] } = props;

    const closableSettlement = settlements.find(
        (settlement) => settlement.state === "CLOSABLE"
    );

    const defaultSelected =
        closableSettlement ?? settlements[settlements.length - 1];

    const [selectedId, setSelectedId] = useState(
        settlements.findIndex(({ uuid }) => uuid === defaultSelected.uuid)
    );

    const handleChange = (event: { target: { value: unknown } }) => {
        setSelectedId(event.target.value as number);
    };

    const handleClickPrevious = () => setSelectedId((index) => index - 1);

    const handleClickNext = () => setSelectedId((index) => index + 1);

    if (settlements.length === 0) {
        return <>{"Nothing to see here"}</>;
    }

    const selectedSettlement = settlements[selectedId];

    return (
        <Stack>
            <Stack p={2} direction="row" justifyContent="space-between">
                <Box mb={4} width={448}>
                    <FormControl fullWidth>
                        <InputLabel id="settlement-selector-label">
                            Periodo di riferimento:
                        </InputLabel>
                        <Stack
                            direction="row"
                            useFlexGap
                            gap={1}
                            sx={{ alignItems: "center" }}
                        >
                            <Button
                                size="small"
                                variant="outlined"
                                disabled={selectedId === 0}
                                onClick={handleClickPrevious}
                            >
                                <ArrowLeft />
                            </Button>

                            <Box>
                                <Select
                                    labelId="settlement-selector-label"
                                    label="Periodo di riferimento:"
                                    onChange={handleChange}
                                    value={selectedId}
                                    sx={{ width: 384 }}
                                >
                                    {settlements.map((settlement, index) => (
                                        <MenuItem
                                            value={index}
                                            key={settlement.uuid}
                                        >
                                            <SettlementItem {...settlement} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Button
                                size="small"
                                variant="outlined"
                                disabled={selectedId === settlements.length - 1}
                                onClick={handleClickNext}
                            >
                                <ArrowRight />
                            </Button>
                        </Stack>
                    </FormControl>
                </Box>

                <Stack direction="row" gap={1}>
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<ArrowRight />}
                        disabled
                    >
                        {"Vai ai Dichiarativi"}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<ArrowRight />}
                        disabled
                    >
                        {"Vai a F24"}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<ArrowRight />}
                        disabled
                    >
                        {"Vai al Report"}
                    </Button>
                </Stack>
            </Stack>

            {props.children(selectedSettlement)}
        </Stack>
    );
};

export default WorkspaceSettlementSelector;
