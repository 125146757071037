import { ExtendedTab, ExtendedTabs } from "@vapor/react-extended";
import { Box } from "@vapor/react-material";
import { ReactNode, useState } from "react";

type Views = "WORKSPACE" | "BUSINESS" | "OTHERS";

const VIEWS_INDEXES = {
    WORKSPACE: "0",
    BUSINESS: "1",
    OTHERS: "2"
} as const satisfies Record<Views, string>;

interface WorkspaceSettlementTabsProps {
    children: (view: Views) => ReactNode;
}

const WorkspaceSettlementTabs = (props: WorkspaceSettlementTabsProps) => {
    const [currentTab, setCurrentTab] = useState<Views>("WORKSPACE");

    const handleChange = (_: unknown, value: (typeof VIEWS_INDEXES)[Views]) => {
        switch (value) {
            case "0":
                return setCurrentTab("WORKSPACE");
            case "1":
                return setCurrentTab("BUSINESS");
            case "2":
                return setCurrentTab("OTHERS");
        }
    };

    return (
        <>
            <Box px={2}>
                <ExtendedTabs
                    size="small"
                    onChange={handleChange}
                    value={VIEWS_INDEXES[currentTab]}
                >
                    <ExtendedTab
                        value={VIEWS_INDEXES["WORKSPACE"]}
                        label="Chiusura periodo"
                    />
                    <ExtendedTab
                        value={VIEWS_INDEXES["BUSINESS"]}
                        label="Dettagli attivitá"
                    />
                    <ExtendedTab
                        value={VIEWS_INDEXES["OTHERS"]}
                        label="Stati adempimenti"
                        disabled
                    />
                </ExtendedTabs>
            </Box>

            <Box>{props.children(currentTab)}</Box>
        </>
    );
};

export default WorkspaceSettlementTabs;
