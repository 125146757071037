import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    DialogActions,
    IconButton,
    Stack
} from "@vapor/react-material";
import { cloneDeep, isEqual, isNil, omit, some, sum } from "lodash";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormState
} from "react-hook-form";

import { useSplitAccountingLine } from "../../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../../core/domain/Draft/queries";
import { useVatDestinations } from "../../../../../core/domain/VatDestinations/queries";
import { useVatRegimes } from "../../../../../core/domain/VatRegimes/queries";
import { AccountingLine } from "../../../../../core/usecases/dtos/AccountingLinesDto";
import { AccountDto } from "../../../../../core/usecases/dtos/AccountsDto";
import { VatDestination } from "../../../../../core/usecases/dtos/VatDestinationsDto";
import { VatRegime } from "../../../../../core/usecases/dtos/VatRegimesDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import omitNilProperties from "../../../../../utils/omitNilProperties";
import FormattedAmount from "../../../FormattedAmount";
import FormCurrencyField from "../../../FormFields/FormCurrencyField";
import FormSelectAccount from "../../../FormFields/FormSelectAccount";
import FormSelectVatDestination from "../../../FormFields/FormSelectVatDestination";
import FormSelectVatRate from "../../../FormFields/FormSelectVatRate";
import FormSelectVatRegime from "../../../FormFields/FormSelectVatRegime";

interface SplitAricleFormProps {
    draftId: string;
    accountingLine: AccountingLine;
    onClose: () => void;
}

interface FormArticle extends Omit<AccountingLine, "regimeId" | "accountCode"> {
    regime?: VatRegime;
    vatDestination?: VatDestination;
    account?: AccountDto;
}

interface FromValues {
    articles: FormArticle[];
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.SplitArticleDialog"
);

function convertAccouningLineInFormArticle(
    accountingLine: AccountingLine,
    vatRegimes: VatRegime[],
    vatDestinations: VatDestination[]
): FormArticle {
    return {
        ...omit(accountingLine, "regimeId", "accountCode"),
        regime: vatRegimes.find(
            (vatRegime) => vatRegime.id === accountingLine.regimeId
        ),
        regimeExtraField: accountingLine.regimeExtraField,
        vatDestination: vatDestinations.find(
            (destination) => destination.id === accountingLine.vatDestinationId
        ),
        account: {
            code: accountingLine.accountCode,
            title: accountingLine.accountDesc ?? "",
            description: accountingLine.accountDesc
        }
    };
}

function convertFormArticleInAccountingLine(
    article: FormArticle
): AccountingLine {
    return {
        ...omit(article, "regime", "vatDestination", "account", "articleDesc"),
        regimeId: article.regime?.id,
        accountCode: article.account?.code!,
        vatDestinationId: article.vatDestination?.id
    };
}

function sumArticlesAmounts(
    articles: FormArticle[],
    amountFieldName: "credit" | "debit"
) {
    const amountsSum = sum(articles.map((article) => article[amountFieldName]));
    // return the parsed value of the sting representation
    // because the algebric sum of floats cannot be a precise
    // number (e.g. 0.4 + 0.2 = 0.6000000000000001) see IEEE754
    return parseFloat((amountsSum ?? 0).toFixed(2));
}

export default function SplitAricleForm({
    draftId,
    accountingLine,
    onClose
}: SplitAricleFormProps) {
    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);
    const { data: vatRegimes } = useVatRegimes();
    const { data: vatDestinations } = useVatDestinations(draft?.reasonId);
    const { mutateAsync: split, isPending: isSplitting } =
        useSplitAccountingLine({ draftId });

    const defaultFormArticles = [
        convertAccouningLineInFormArticle(
            accountingLine,
            vatRegimes ?? [],
            vatDestinations ?? []
        )
    ];

    const methods = useForm<FromValues>({
        defaultValues: {
            articles: defaultFormArticles
        },
        reValidateMode: "onChange"
    });

    const amountFieldName = accountingLine.credit ? "credit" : "debit";

    const {
        fields: destinationArticles,
        append,
        remove
    } = useFieldArray({
        control: methods.control,
        name: "articles",
        shouldUnregister: true,
        rules: {
            validate: (articles) => {
                const sumOfArticles = sumArticlesAmounts(
                    articles,
                    amountFieldName
                );

                return sumOfArticles && !isNil(accountingLine[amountFieldName])
                    ? sumOfArticles === accountingLine[amountFieldName]
                    : false;
            }
        }
    });

    const formState = useFormState({ control: methods.control });

    const onSubmit: SubmitHandler<FromValues> = async (data) => {
        const modifiedOriginalAccountingLine = data.articles.find(
            (article) => article.uuid === accountingLine.uuid
        );
        if (modifiedOriginalAccountingLine) {
            await split({
                originalAccountingLine: convertFormArticleInAccountingLine(
                    modifiedOriginalAccountingLine
                ),
                resultingAccountingLines: data.articles
                    .filter((article) => article.uuid !== accountingLine.uuid)
                    .map(convertFormArticleInAccountingLine)
                    .map((accountingLine) => ({
                        ...accountingLine,
                        articleDesc: modifiedOriginalAccountingLine.articleDesc
                    }))
            });
            onClose();
        }
    };

    const formArticles = methods.watch().articles;

    const noModificationsWithRespectToDefaultState = isEqual(
        defaultFormArticles,
        formArticles.map((article) => omit(omitNilProperties(article), "id"))
    );

    const formHasErrors =
        !isNil(formState.errors.articles) &&
        formState.errors.articles.map &&
        some(
            formState.errors.articles.map(
                (articleError) =>
                    !isNil(articleError?.credit?.message) ||
                    !isNil(articleError?.debit?.message)
            )
        );

    const errorMessage = isNil(formState.errors.articles?.root)
        ? null
        : fs("totalMustBeTheSame");

    const residualAmount =
        (accountingLine[amountFieldName] ?? 0) -
        sumArticlesAmounts(formArticles, amountFieldName);

    if (!isDraftSuccess) {
        return null;
    }

    const { refCurrency } = draft;

    return (
        <FormProvider {...methods}>
            <Stack direction="column" spacing={2}>
                {destinationArticles.map((destinationArticle, index) => (
                    <Stack
                        key={destinationArticle.id}
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Box width="90px">
                            <FormCurrencyField
                                name={`articles.${index}.${amountFieldName}`}
                                label={fs("amount")}
                                required={true}
                                validate={(amount) => amount !== 0}
                                currency={refCurrency}
                            />
                        </Box>
                        <Box minWidth="300px">
                            <FormSelectVatRegime
                                name={`articles.${index}.regime`}
                                baseName={`articles.${index}`}
                                extraFieldValue={
                                    destinationArticle.regimeExtraField
                                }
                                reasonId={draft.reasonId}
                                label={fs("vatRegime")}
                                required={false}
                                disablePortal={false}
                            />
                        </Box>
                        <Box minWidth="80px">
                            <FormSelectVatRate
                                name={`articles.${index}.vatRate`}
                                label={fs("vatRate")}
                                required={false}
                                disabled={true}
                            />
                        </Box>
                        <Box minWidth="300px">
                            <FormSelectAccount
                                name={`articles.${index}.account`}
                                label={fs("account")}
                                required={false}
                                disablePortal={false}
                            />
                        </Box>
                        <Box width="210px">
                            <FormSelectVatDestination
                                name={`articles.${index}.vatDestination`}
                                label={fs("vatDestination")}
                                required={false}
                                disablePortal={false}
                            />
                        </Box>
                        <IconButton
                            disabled={index === 0}
                            onClick={() => remove(index)}
                        >
                            <VaporIcon
                                iconName="trash-alt"
                                color={index === 0 ? "disabled" : "primary"}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                append(
                                    cloneDeep(
                                        omit(
                                            destinationArticle,
                                            "uuid",
                                            amountFieldName
                                        )
                                    )
                                )
                            }
                        >
                            <VaporIcon iconName="plus-circle" color="primary" />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
            <Stack direction="row" alignItems="center" marginTop="16px">
                <Typography variant="body700" marginRight="8px">
                    {fs("residual")}
                </Typography>
                <FormattedAmount
                    variant="body"
                    amount={residualAmount}
                    currency={refCurrency}
                />
            </Stack>
            <Box marginTop="16px">
                <Typography variant="body" color="red">
                    {errorMessage}
                </Typography>
            </Box>
            <DialogActions
                sx={{
                    marginX: "-24px",
                    marginBottom: "-24px",
                    marginTop: "24px"
                }}
            >
                <Button variant="outlined" color="secondary" onClick={onClose}>
                    {fs("cancel")}
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        formHasErrors ||
                        noModificationsWithRespectToDefaultState ||
                        isSplitting
                    }
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {isSplitting ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            spin={isSplitting}
                            style={{ marginRight: "8px" }}
                        />
                    ) : null}
                    {fs("save")}
                </Button>
            </DialogActions>
        </FormProvider>
    );
}
