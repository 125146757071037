import { AccountType, VatRate } from "../../../utils/appEnums";

export interface AccountingLine {
    uuid?: string;
    accountCode: string;
    accountDesc?: string;
    accountType?: AccountType;
    articleDesc?: string;
    regimeId?: string;
    regimeExtraField?: string | null;
    vatRate?: string;
    vatDestinationId?: string | null;
    isAsset?: boolean;
    isReadOnly?: boolean;
    isCustomer?: boolean;
    isSupplier?: boolean;
    isVat?: boolean;
    debit?: number;
    credit?: number;
    hasChildren?: boolean;
    disabled?: boolean;
    previousBalance?: number;
    startPeriodDate?: string;
    endPeriodDate?: string;
    exemptionNatureId?: string;
    exemptionNature?: string;
    isReverseCharge?: boolean;
    reverseChargeRate?: VatRate;
    doNotApplyReverseCharge?: boolean;
    tributeCode?: string;
    tributeYear?: string;
    isBank?: boolean;
    fieldsToDelete?: FieldsToDelete[];
}

export enum FieldsToDelete {
    START_PERIOD_DATE = "startPeriodDate",
    END_PERIOD_DATE = "endPeriodDate",
    REGIME_EXTRA_FIELD = "regimeExtraField"
}

export interface DraftStatus {
    isConfirmable: boolean;
    messageCode: string;
    message: string;
}

export interface AccountingLinesResponse {
    accountingLinesByCodeApiResList: AccountingLine[];
    draftStatus: DraftStatus;
}
