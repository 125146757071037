import { useCurrentWorkspace } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { useMutation } from "@tanstack/react-query";
import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";

interface CreateBalanceOptions {
    fromDate: string;
    toDate: string;
    accrual: boolean;
    includeVariance: boolean;
}

const useCreateBalanceAPI = () => {
    const { id: workspaceId } = useCurrentWorkspace(true).workspace ?? {
        id: ""
    };
    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/general-ledger/create-financial-statement`,
        method: "post",
        ...useAxiosConfigRequestParams("createBalance"),
        lazy: true
    });

    return async ({
        fromDate,
        toDate,
        accrual,
        includeVariance
    }: CreateBalanceOptions): Promise<{}> =>
        fetch({
            data: { workspaceId, fromDate, toDate, accrual, includeVariance }
        }).then(({ data }: { data: {} }) => data);
};

export const useCreateBalance = () => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.CreateBalance");
    const fetchCreateBalance = useCreateBalanceAPI();

    return useMutation({
        mutationKey: ["create-balance"],
        mutationFn: async ({
            fromDate,
            toDate,
            accrual,
            includeVariance
        }: CreateBalanceOptions) => {
            return fetchCreateBalance({
                fromDate,
                toDate,
                accrual,
                includeVariance
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => enqueueSnackbar(fs("success"), { severity: "success" })
    });
};
