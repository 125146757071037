import { DraftDetail } from "../core/usecases/dtos/DraftDetail";
import { ReasonDto } from "../core/usecases/dtos/ReasonsListDto";
import { Reason } from "./appEnums";

const ReasonsWithVatLines = [
    Reason.FE1,
    Reason.FE3,
    Reason.FE4,
    Reason.FE401,
    Reason.FE5,
    Reason.FE8,
    Reason.FE9,
    Reason.FE10,
    Reason.CO1,
    Reason.FA1,
    Reason.FA3,
    Reason.FA4,
    Reason.FA401,
    Reason.FA5,
    Reason.FA7,
    Reason.FA8
];

export function isVat(reason: Reason) {
    return ReasonsWithVatLines.includes(reason);
}

export function needsCompetence(reason: Reason) {
    // Movimento Generico (MG1) is not a "vat reason" but it needs competence
    if (reason === Reason.MG1) {
        return true;
    }

    return (
        isVat(reason) &&
        ![Reason.PAF1, Reason.RAC1, Reason.CO1].includes(reason)
    );
}

const activeReasons = [
    Reason.FE1,
    Reason.FE3,
    Reason.FE4,
    Reason.FE401,
    Reason.FE5,
    Reason.FE8,
    Reason.FE9,
    Reason.FE10
];

export function isActive(reason: Reason) {
    return activeReasons.includes(reason);
}

const reasonsWithAccountingLineRow = [
    Reason.FE1,
    Reason.FE3,
    Reason.FE4,
    Reason.FE401,
    Reason.FE5,
    Reason.FE8,
    Reason.FE9,
    Reason.FE10,
    Reason.FA1,
    Reason.FA3,
    Reason.FA4,
    Reason.FA401,
    Reason.FA5,
    Reason.FA7,
    Reason.FA8
];

export function hasAccountingLines(reason: Reason) {
    return reasonsWithAccountingLineRow.includes(reason);
}

export function canCreateAccountingLines(
    reason: Reason,
    detail: DraftDetail | null
) {
    return [Reason.CO1].includes(reason) ? detail?.source !== "B2B" : true;
}

export function isCustomerSupplierRequired(reason: Reason) {
    return ![Reason.CG1, Reason.RG1].includes(reason);
}

const reasonsExcludedNewRegistration = [
    Reason.FE401,
    Reason.FE10,
    Reason.FA401,
    Reason.FA8,
    Reason.CR1,
    Reason.PB1,
    Reason.VB1,
    Reason.IP1,
    Reason.MI1,
    Reason.PS1
];

const ReasonsWithSpecialHandling = [Reason.DR1, Reason.CA21];

export function filteredReasonsForNewRegistration(
    reasons: ReasonDto[] | undefined
): ReasonDto[] | undefined {
    return reasons?.filter(
        (reason) =>
            !reasonsExcludedNewRegistration.includes(reason.code as Reason)
    );
}

export function isDraftReason(reason: Reason | string) {
    return (
        Object.keys(Reason).includes(reason) &&
        !ReasonsWithSpecialHandling.includes(reason as Reason)
    );
}
