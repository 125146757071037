import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import { Box } from "@vapor/react-material/Box";
import { Divider } from "@vapor/react-material/Divider";
import { FormControl } from "@vapor/react-material/FormControl";
import { FormControlLabel } from "@vapor/react-material/FormControlLabel";
import { FormGroup } from "@vapor/react-material/FormGroup";
import { Grid } from "@vapor/react-material/Grid";
import { InputLabel } from "@vapor/react-material/InputLabel";
import Link from "@vapor/react-material/Link";
import { MenuItem } from "@vapor/react-material/MenuItem";
import { Radio } from "@vapor/react-material/Radio";
import { RadioGroup } from "@vapor/react-material/RadioGroup";
import { Select } from "@vapor/react-material/Select";
import { Switch } from "@vapor/react-material/Switch";
import { TextField } from "@vapor/react-material/TextField";
import { TextareaAutosize } from "@vapor/react-material/TextareaAutosize";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { includes, isNil } from "lodash";

import { AssetDetailDto } from "../../../../core/usecases/dtos/AssetDetailDto";
import { AssetInfoResponse } from "../../../../core/usecases/dtos/AssetInfoDto";
import { AssetRelationsDto } from "../../../../core/usecases/dtos/AssetRelationsDto";
import {
    AssetSubkindDto,
    AssetSubkindsDto
} from "../../../../core/usecases/dtos/AssetSubkindsDto";
import {
    DraftAssetPatch,
    ReducedDeductibilityGoods
} from "../../../../core/usecases/usePatchDraftAsset";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { ReducedDeductibilityTypes } from "../../../views/Assets/Registry";
import AccountRelationsTab from "../../../views/AssetsList/AssetsListTable/AssetListDrawer/accountRelationsTab";
import CurrencyField from "../../CurrencyField";
import FormattedAmount from "../../FormattedAmount";
import { MenuGroupTitle } from "../../styled";
import { ShellCompany } from "../../../../utils/appEnums";
import { readOnlySelect } from "../../../../core/commons/read-only-select";
import { format } from "date-fns";
import { API_DATE_FORMAT } from "../../../../config";

const fs = getFormattedStringWithScope("views.Detail.assets.drawer");

interface AssetRegistryProps {
    openAccountRelations: boolean;
    data: AssetRelationsDto | null;
    onOpenAccountRelationsTab: () => void;
    isGroupingAsset: boolean;
    assetGroupName?: string;
    assetInfo: AssetInfoResponse | null;
    assetDetail: AssetDetailDto | null;
    currentArticleDesc?: string;
    onSetArticleDesc: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    setDataToPatch: (dataToPatch: DraftAssetPatch | null) => void;
    refCurrency: string | null;
    textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
    selectedSubkindId: string | null;
    setSelectedSubkindId: (selectedSubkindId: string | null) => void;
    subspeciesList: AssetSubkindsDto | null;
    currentDescription?: string;
    setCurrentDescription: (currentDescription?: string) => void;
    isReducedDeductibility: boolean;
    onSetReducedDeductibility: () => void;
    reducedDeductibilityGoods: ReducedDeductibilityGoods;
    setIsReducedDeductibilityGoods: (
        reducedDeductibilityGoods: ReducedDeductibilityGoods
    ) => void;
    setShowDependentMenus: (showDependentMenus: boolean) => void;
    showDependentMenus: boolean;
    showUsageMenu: ReducedDeductibilityTypes | null;
    setShowUsageMenu: (showUsageMenu: ReducedDeductibilityTypes | null) => void;
    deductibilityGoods: string[] | null;
    currentAlias?: string;
    setCurrentAlias: (currentAlias?: string) => void;
    readOnly: boolean;
    draftReadOnly: boolean;
}

export default function AssetRegistry({
    openAccountRelations,
    data,
    onOpenAccountRelationsTab,
    isGroupingAsset,
    assetGroupName,
    assetInfo,
    currentArticleDesc,
    onSetArticleDesc,
    setDataToPatch,
    refCurrency,
    textRef,
    selectedSubkindId,
    setSelectedSubkindId,
    subspeciesList,
    currentDescription,
    setCurrentDescription,
    isReducedDeductibility,
    onSetReducedDeductibility,
    reducedDeductibilityGoods,
    setIsReducedDeductibilityGoods,
    setShowDependentMenus,
    showDependentMenus,
    showUsageMenu,
    setShowUsageMenu,
    deductibilityGoods,
    currentAlias,
    setCurrentAlias,
    readOnly,
    assetDetail,
    draftReadOnly
}: Partial<AssetRegistryProps>) {
    const getExtraFieldFloatValue = (stringValue?: string) => {
        return stringValue ? parseFloat(stringValue) : 0;
    };

    return (
        <>
            <>
                {openAccountRelations && (
                    <AccountRelationsTab
                        data={data ?? null}
                        openAccountRelations={openAccountRelations}
                        onOpenAccountRelationsTab={
                            onOpenAccountRelationsTab as () => void
                        }
                    />
                )}
                {!openAccountRelations && (
                    <Box sx={{ margin: "16px 40px" }}>
                        <Typography
                            variant="titleXSmall"
                            color="richElectricBlue"
                        >
                            {fs("registry.paragraphIdentifyingData")}
                        </Typography>
                        <Grid container sx={{ marginTop: 2 }}>
                            <Grid item xs={6}>
                                <Grid container justifyContent="space-between">
                                    {readOnly ? (
                                        <Stack>
                                            <Typography
                                                color="primary.interactiveDefault"
                                                variant="button"
                                                marginTop="4"
                                            >
                                                {assetDetail?.name}
                                            </Typography>
                                            <Typography
                                                color="primary.textSubduedColor"
                                                variant="bodySmall"
                                            >
                                                {assetDetail?.accountDesc}
                                            </Typography>
                                            <Typography
                                                color="primary.textSubduedColor"
                                                variant="bodySmall"
                                            >
                                                {assetDetail?.accountCode}
                                            </Typography>
                                        </Stack>
                                    ) : (
                                        <Grid xs={6}>
                                            <InputLabel htmlFor="assetName">
                                                {fs("registry.assetName")}
                                            </InputLabel>
                                            <TextField
                                                name="assetName"
                                                variant="outlined"
                                                defaultValue={
                                                    isGroupingAsset
                                                        ? assetGroupName
                                                        : assetInfo?.articleDesc
                                                }
                                                readOnly={
                                                    !isGroupingAsset ||
                                                    draftReadOnly
                                                }
                                                onChange={(e: any) => {
                                                    if (e.target.value)
                                                        onSetArticleDesc?.(e);
                                                }}
                                                onBlur={() => {
                                                    if (
                                                        currentArticleDesc !==
                                                        assetInfo?.articleDesc
                                                    )
                                                        setDataToPatch?.({
                                                            articleDesc:
                                                                currentArticleDesc
                                                        });
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid xs={5} sx={{ marginLeft: 2 }}>
                                        <Stack alignItems={"flex-end"}>
                                            <FormattedAmount
                                                amount={
                                                    readOnly
                                                        ? assetDetail?.amount ??
                                                          0
                                                        : assetInfo?.amount ?? 0
                                                }
                                                currency={refCurrency ?? "EUR"}
                                                variant="displaySmall"
                                            />
                                            <InputLabel
                                                sx={{
                                                    marginBottom: "0 !important"
                                                }}
                                            >
                                                <Typography variant="bodySmall">
                                                    {fs("registry.value")}
                                                </Typography>
                                            </InputLabel>
                                            {assetInfo?.additionalCharge && (
                                                <>
                                                    <InputLabel
                                                        sx={{
                                                            marginTop: "1",
                                                            marginBottom:
                                                                "0 !important"
                                                        }}
                                                    >
                                                        <Typography variant="bodySmall">
                                                            {fs(
                                                                "registry.additionalCharge"
                                                            )}
                                                        </Typography>
                                                    </InputLabel>
                                                    <FormattedAmount
                                                        amount={
                                                            assetInfo?.additionalCharge ??
                                                            0
                                                        }
                                                        currency={
                                                            refCurrency ?? "EUR"
                                                        }
                                                        variant="bodySmall700"
                                                    />
                                                </>
                                            )}
                                            {assetInfo?.nonDeductibleAmount ? (
                                                <Box marginTop={1.2}>
                                                    <InputLabel
                                                        sx={{
                                                            margin: "0 !important"
                                                        }}
                                                    >
                                                        <Typography variant="bodySmall">
                                                            {fs(
                                                                "registry.nonDeductibleAmount"
                                                            )}
                                                        </Typography>
                                                    </InputLabel>
                                                    <FormattedAmount
                                                        amount={
                                                            assetInfo?.nonDeductibleAmount ??
                                                            0
                                                        }
                                                        currency={
                                                            refCurrency ?? "EUR"
                                                        }
                                                        variant="bodySmall700"
                                                    />
                                                </Box>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Box>
                                    <Link
                                        underline="hover"
                                        onClick={onOpenAccountRelationsTab}
                                    >
                                        <Typography
                                            variant="bodySmall"
                                            color="primary.interactiveDefault"
                                        >
                                            {assetInfo?.accountDesc}
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ marginTop: -1 }}>
                                    <Typography variant="bodySmall">
                                        {assetInfo?.accountCode}
                                    </Typography>
                                </Box>
                                <Box sx={{ marginTop: 10 }}>
                                    <InputLabel htmlFor="alias">
                                        {fs("registry.codeId")}
                                    </InputLabel>
                                    <TextField
                                        name="alias"
                                        variant="outlined"
                                        key={assetInfo?.alias}
                                        readOnly={readOnly || draftReadOnly}
                                        defaultValue={
                                            readOnly
                                                ? assetDetail?.alias
                                                : assetInfo?.alias
                                        }
                                        onChange={(e: any) => {
                                            if (e.target.value)
                                                setCurrentAlias?.(
                                                    e.target.value
                                                );
                                        }}
                                        onBlur={() => {
                                            if (
                                                assetInfo?.alias !==
                                                currentAlias
                                            )
                                                setDataToPatch?.({
                                                    alias: currentAlias
                                                });
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Divider
                                sx={{ margin: "0 16px" }}
                                flexItem
                                orientation="vertical"
                                variant="middle"
                            />
                            <Grid item xs={5}>
                                <Grid container direction={"column"} gap="6px">
                                    <Grid>
                                        <Typography variant="body">
                                            {fs("registry.group")}:{" "}
                                            <b>
                                                {readOnly
                                                    ? assetDetail?.groupDesc
                                                    : assetInfo?.groupDesc}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body">
                                            {fs("registry.species")}:{" "}
                                            <b>
                                                {readOnly
                                                    ? assetDetail?.kindDesc
                                                    : assetInfo?.kindDesc}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body">
                                            {fs("registry.subspecies")}:{" "}
                                        </Typography>
                                        <FormControl
                                            sx={{
                                                minWidth: "100%",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <Select
                                                {...readOnlySelect(
                                                    readOnly || draftReadOnly
                                                )}
                                                onChange={(e: any) => {
                                                    if (e.target.value) {
                                                        setSelectedSubkindId?.(
                                                            e?.target.value
                                                        );
                                                        setDataToPatch?.({
                                                            subKind:
                                                                e?.target.value
                                                        });
                                                    }
                                                }}
                                                value={selectedSubkindId}
                                                MenuProps={{
                                                    sx: {
                                                        zIndex: "7500 !important"
                                                    }
                                                }}
                                            >
                                                {subspeciesList?.favourites
                                                    .length === 0 ? null : (
                                                    <MenuGroupTitle disabled>
                                                        <Typography variant="body500">
                                                            {fs("suggestions")}
                                                        </Typography>
                                                    </MenuGroupTitle>
                                                )}
                                                {subspeciesList &&
                                                    subspeciesList.favourites
                                                        .filter(
                                                            (
                                                                subspecie: AssetSubkindDto
                                                            ) =>
                                                                subspecie?.subKind
                                                        )
                                                        .map(
                                                            (
                                                                subspecie: AssetSubkindDto,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            subspecie?.subKind
                                                                        }
                                                                    >
                                                                        {
                                                                            subspecie?.description
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}

                                                {subspeciesList?.others
                                                    .length === 0 ? null : (
                                                    <MenuGroupTitle disabled>
                                                        <Typography variant="body500">
                                                            {fs(
                                                                "otherSubkinds"
                                                            )}
                                                        </Typography>
                                                    </MenuGroupTitle>
                                                )}
                                                {subspeciesList &&
                                                    subspeciesList.others
                                                        .filter(
                                                            (
                                                                subspecie: AssetSubkindDto
                                                            ) =>
                                                                subspecie?.subKind
                                                        )
                                                        .map(
                                                            (
                                                                subspecie: AssetSubkindDto,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            subspecie?.subKind
                                                                        }
                                                                    >
                                                                        {
                                                                            subspecie?.description
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ marginTop: "8px" }}>
                                        <Typography variant="body500">
                                            {assetDetail?.materialGood ||
                                            assetInfo?.materialGood
                                                ? fs("registry.materialGood")
                                                : fs("registry.intangibleGood")}
                                        </Typography>
                                        {readOnly ? (
                                            <RadioGroup
                                                value={assetDetail?.newGood}
                                                row
                                            >
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    label={fs("registry.new")}
                                                    value={true}
                                                />
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    label={fs("registry.used")}
                                                    value={false}
                                                />
                                            </RadioGroup>
                                        ) : (
                                            <RadioGroup
                                                name="row-radio-buttons-group"
                                                onChange={(e: any) => {
                                                    if (
                                                        e.target.value !==
                                                        assetInfo?.newGood
                                                    )
                                                        setDataToPatch?.({
                                                            newGood:
                                                                e.target
                                                                    .value ===
                                                                "NEW"
                                                        });
                                                }}
                                                key={
                                                    "bene_" + assetInfo?.newGood
                                                        ? "NEW"
                                                        : "OLD"
                                                }
                                                defaultValue={
                                                    readOnly
                                                        ? assetDetail?.newGood
                                                        : assetInfo?.newGood
                                                        ? "NEW"
                                                        : "USED"
                                                }
                                                row
                                            >
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    label={fs("registry.new")}
                                                    value="NEW"
                                                    disabled={draftReadOnly}
                                                />
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    label={fs("registry.used")}
                                                    value="USED"
                                                    disabled={draftReadOnly}
                                                />
                                            </RadioGroup>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            sx={{ paddingRight: "20px", marginTop: "16px" }}
                        >
                            <Grid item xs={12}>
                                <InputLabel htmlFor="my-description">
                                    {fs("registry.description")}
                                </InputLabel>
                                <TextareaAutosize
                                    readOnly={readOnly || draftReadOnly}
                                    ref={textRef}
                                    minRows={3}
                                    placeholder={fs(
                                        readOnly || draftReadOnly
                                            ? "registry.placeholderNoDescription"
                                            : "registry.placeholderDescription"
                                    )}
                                    spellCheck="false"
                                    style={{
                                        width: "100%"
                                    }}
                                    onChange={(e: any) => {
                                        if (
                                            !isNil(e.target.value) &&
                                            e.target.value !==
                                                currentDescription
                                        )
                                            setCurrentDescription?.(
                                                e.target.value
                                            );
                                    }}
                                    onBlur={() => {
                                        setDataToPatch?.({
                                            assetFullDescription:
                                                currentDescription //TODO: passare a description quando faremo refactoring con asset
                                        });
                                    }}
                                    value={
                                        readOnly
                                            ? assetDetail?.description
                                            : currentDescription
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                            <Typography
                                variant="titleXSmall"
                                color="richElectricBlue"
                            >
                                {fs(
                                    "registry.paragraphAvailabilityDeductibility"
                                )}
                            </Typography>
                            <Grid container xs={12} marginTop={2}>
                                <Grid xs={6}>
                                    <InputLabel htmlFor="alias">
                                        {fs("registry.activation")}
                                    </InputLabel>
                                    <DatePicker
                                        readOnly={readOnly || draftReadOnly}
                                        value={
                                            readOnly
                                                ? assetDetail?.activationDate
                                                    ? new Date(
                                                          assetDetail.activationDate
                                                      )
                                                    : null
                                                : assetInfo?.activationDate
                                                ? new Date(
                                                      assetInfo.activationDate
                                                  )
                                                : null
                                        }
                                        onChange={(date: any) => {
                                            setDataToPatch?.({
                                                activationDate: date
                                            });
                                        }}
                                        slotProps={{
                                            popper: {
                                                sx: {
                                                    "&.MuiPickersPopper-root": {
                                                        zIndex: "7500 !important"
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <InputLabel htmlFor="alias">
                                        {fs("registry.availability")}
                                    </InputLabel>
                                    <DatePicker
                                        readOnly={readOnly || draftReadOnly}
                                        value={
                                            readOnly
                                                ? assetDetail?.availabilityDate
                                                    ? new Date(
                                                          assetDetail.availabilityDate
                                                      )
                                                    : null
                                                : assetInfo?.availabilityDate
                                                ? new Date(
                                                      assetInfo.availabilityDate
                                                  )
                                                : null
                                        }
                                        onChange={(date: any) => {
                                            setDataToPatch?.({
                                                availabilityDate: date
                                            });
                                        }}
                                        slotProps={{
                                            popper: {
                                                sx: {
                                                    "&.MuiPickersPopper-root": {
                                                        zIndex: "7500 !important"
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} marginTop={2}>
                                <Grid xs={6}>
                                    <Typography variant="body500">
                                        {fs("registry.hasIsa")}
                                    </Typography>
                                    {readOnly ? (
                                        <RadioGroup
                                            value={assetDetail?.hasISA}
                                            row
                                        >
                                            <FormControlLabel
                                                control={<Radio />}
                                                label={fs("registry.yes")}
                                                value={true}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                label={fs("registry.no")}
                                                value={false}
                                            />
                                        </RadioGroup>
                                    ) : (
                                        <RadioGroup
                                            name="row-radio-buttons-group"
                                            onChange={(e: any) => {
                                                if (
                                                    e.target.value !==
                                                    assetInfo?.hasISA
                                                )
                                                    setDataToPatch?.({
                                                        hasISA: e.target.value
                                                    });
                                            }}
                                            key={
                                                "hasIsa_" + assetInfo?.hasISA
                                                    ? "yes"
                                                    : "no"
                                            }
                                            defaultValue={
                                                assetInfo?.hasISA ?? true
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                control={<Radio />}
                                                label={fs("registry.yes")}
                                                value="true"
                                                disabled={draftReadOnly}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                label={fs("registry.no")}
                                                value="false"
                                                disabled={draftReadOnly}
                                            />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                <Grid xs={5}>
                                    <Typography variant="body500">
                                        {fs("registry.shellCompany")}
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            minWidth: "100%",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <Select
                                            {...readOnlySelect(
                                                readOnly || draftReadOnly
                                            )}
                                            onChange={(e: any) => {
                                                if (
                                                    e.target.value !==
                                                    assetInfo?.shellCompany
                                                )
                                                    setDataToPatch?.({
                                                        shellCompany:
                                                            e.target.value
                                                    });

                                                if (
                                                    e.target.value &&
                                                    e.target.value === -1
                                                ) {
                                                    setDataToPatch?.({
                                                        fieldsToDelete: [
                                                            "shellCompany"
                                                        ]
                                                    });
                                                }
                                            }}
                                            defaultValue={
                                                assetInfo?.shellCompany ??
                                                assetDetail?.shellCompany
                                            }
                                            MenuProps={{
                                                sx: {
                                                    zIndex: "7500 !important"
                                                }
                                            }}
                                        >
                                            <MenuItem value={-1}>
                                                {fs("registry.noShellCompany")}
                                            </MenuItem>
                                            {Object.keys(ShellCompany).map(
                                                (shellCompany, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={shellCompany}
                                                        >
                                                            {fs(
                                                                `registry.shellCompanies.${shellCompany}`
                                                            )}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container alignItems={"center"}>
                            <Grid item xs>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                readOnly={
                                                    readOnly || draftReadOnly
                                                }
                                                checked={isReducedDeductibility}
                                                onChange={
                                                    readOnly || draftReadOnly
                                                        ? undefined
                                                        : onSetReducedDeductibility
                                                }
                                            />
                                        }
                                        label={fs(
                                            "registry.reducedDeductibility"
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={7}>
                                {isReducedDeductibility &&
                                    (readOnly ? (
                                        <TextField
                                            readOnly
                                            value={fs(
                                                `registry.deductibilityGoods.${assetDetail?.reducedDeductibility}`
                                            )}
                                        />
                                    ) : (
                                        <Select
                                            {...readOnlySelect(
                                                readOnly || draftReadOnly
                                            )}
                                            sx={{ width: "100%" }}
                                            defaultValue={
                                                assetInfo?.reducedDeductibility
                                            }
                                            MenuProps={{
                                                sx: {
                                                    zIndex: "7500 !important"
                                                }
                                            }}
                                            onChange={(event: any) => {
                                                if (event.target.value)
                                                    setDataToPatch?.({
                                                        isReducedDeductibility:
                                                            isReducedDeductibility,
                                                        reducedDeductibility:
                                                            event?.target.value
                                                    });
                                                if (
                                                    [
                                                        ReducedDeductibilityTypes.BUSINESS_VEHICLES,
                                                        ReducedDeductibilityTypes.BUILDING_AND_LAND
                                                    ].includes(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setShowUsageMenu?.(
                                                        event.target.value
                                                    );
                                                } else {
                                                    setShowUsageMenu?.(
                                                        event.target.value
                                                    );
                                                }
                                            }}
                                        >
                                            {deductibilityGoods?.map(
                                                (
                                                    deductibilityItem: string,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={
                                                                deductibilityItem
                                                            }
                                                        >
                                                            {fs(
                                                                `registry.deductibilityGoods.${deductibilityItem}`
                                                            )}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    ))}
                            </Grid>
                        </Grid>
                        {!showUsageMenu ? null : showUsageMenu ===
                          ReducedDeductibilityTypes.BUILDING_AND_LAND ? (
                            <Box>
                                <FormControl>
                                    <InputLabel>
                                        {fs("registry.landValue")}
                                    </InputLabel>
                                    <CurrencyField
                                        value={getExtraFieldFloatValue(
                                            assetInfo?.reducedDeductibilityExtraField ??
                                                assetDetail?.reducedDeductibilityExtraField
                                        )}
                                        onChange={(value) =>
                                            setDataToPatch?.({
                                                reducedDeductibilityExtraField:
                                                    value.toString()
                                            })
                                        }
                                        currency={refCurrency ?? "EUR"}
                                        readOnly={readOnly || draftReadOnly}
                                    />
                                </FormControl>
                            </Box>
                        ) : showUsageMenu ===
                          ReducedDeductibilityTypes.BUSINESS_VEHICLES ? (
                            <Box
                                marginTop="20px"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <InputLabel>
                                        {fs("registry.usageAllowance")}
                                    </InputLabel>
                                    <Select
                                        defaultValue={
                                            assetInfo
                                                ?.reducedDeductibilityGoods?.[0]
                                                ?.mixedUse
                                        }
                                        MenuProps={{
                                            sx: {
                                                zIndex: "7500 !important"
                                            }
                                        }}
                                        sx={{ width: "223px" }}
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                setIsReducedDeductibilityGoods?.(
                                                    {
                                                        ...reducedDeductibilityGoods,
                                                        mixedUse: event.target
                                                            .value as string
                                                    }
                                                );

                                                setDataToPatch?.({
                                                    reducedDeductibilityGoods: [
                                                        {
                                                            mixedUse: event
                                                                ?.target
                                                                ?.value as string,
                                                            assignmentDate:
                                                                reducedDeductibilityGoods?.assignmentDate,
                                                            revocationDate:
                                                                reducedDeductibilityGoods?.revocationDate
                                                        }
                                                    ]
                                                });
                                            }
                                            if (
                                                includes(
                                                    [
                                                        ReducedDeductibilityTypes.EMPLOYEE,
                                                        ReducedDeductibilityTypes.ADMINISTRATOR,
                                                        ReducedDeductibilityTypes.BUILDING_AND_LAND
                                                    ],
                                                    event.target.value
                                                )
                                            ) {
                                                setShowDependentMenus?.(true);
                                            } else {
                                                setShowDependentMenus?.(false);
                                            }
                                        }}
                                        {...readOnlySelect(
                                            readOnly || draftReadOnly
                                        )}
                                    >
                                        <MenuItem
                                            value={
                                                ReducedDeductibilityTypes.EMPLOYEE
                                            }
                                        >
                                            {fs("registry.dependent")}
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                ReducedDeductibilityTypes.ADMINISTRATOR
                                            }
                                        >
                                            {fs("registry.administrator")}
                                        </MenuItem>
                                        <MenuItem
                                            value={ReducedDeductibilityTypes.NO}
                                        >
                                            {fs("registry.no")}
                                        </MenuItem>
                                    </Select>
                                </Box>
                                {showDependentMenus && (
                                    <>
                                        <Box>
                                            <InputLabel>
                                                {fs("registry.assignmentDate")}
                                            </InputLabel>
                                            <DatePicker
                                                value={
                                                    assetInfo
                                                        ?.reducedDeductibilityGoods?.[0]
                                                        ?.assignmentDate
                                                        ? new Date(
                                                              assetInfo?.reducedDeductibilityGoods?.[0]?.assignmentDate
                                                          )
                                                        : null
                                                }
                                                onChange={(date: any) => {
                                                    setIsReducedDeductibilityGoods?.(
                                                        {
                                                            ...reducedDeductibilityGoods,
                                                            assignmentDate:
                                                                format(
                                                                    date,
                                                                    API_DATE_FORMAT
                                                                )
                                                        }
                                                    );

                                                    setDataToPatch?.({
                                                        reducedDeductibilityGoods:
                                                            [
                                                                {
                                                                    assignmentDate:
                                                                        format(
                                                                            date,
                                                                            API_DATE_FORMAT
                                                                        ),
                                                                    mixedUse:
                                                                        reducedDeductibilityGoods?.mixedUse,
                                                                    revocationDate:
                                                                        reducedDeductibilityGoods?.revocationDate
                                                                }
                                                            ]
                                                    });
                                                }}
                                                slotProps={{
                                                    popper: {
                                                        sx: {
                                                            "&.MuiPickersPopper-root":
                                                                {
                                                                    zIndex: "7500 !important"
                                                                }
                                                        }
                                                    }
                                                }}
                                                readOnly={draftReadOnly}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel>
                                                {fs("registry.revocationDate")}
                                            </InputLabel>
                                            <DatePicker
                                                value={
                                                    assetInfo
                                                        ?.reducedDeductibilityGoods?.[0]
                                                        ?.revocationDate
                                                        ? new Date(
                                                              assetInfo?.reducedDeductibilityGoods?.[0]?.revocationDate
                                                          )
                                                        : null
                                                }
                                                onChange={(date: any) => {
                                                    setIsReducedDeductibilityGoods?.(
                                                        {
                                                            ...reducedDeductibilityGoods,
                                                            revocationDate:
                                                                format(
                                                                    date,
                                                                    API_DATE_FORMAT
                                                                )
                                                        }
                                                    );

                                                    setDataToPatch?.({
                                                        reducedDeductibilityGoods:
                                                            [
                                                                {
                                                                    revocationDate:
                                                                        format(
                                                                            date,
                                                                            API_DATE_FORMAT
                                                                        ),
                                                                    mixedUse:
                                                                        reducedDeductibilityGoods?.mixedUse,
                                                                    assignmentDate:
                                                                        reducedDeductibilityGoods?.assignmentDate
                                                                }
                                                            ]
                                                    });
                                                }}
                                                slotProps={{
                                                    popper: {
                                                        sx: {
                                                            "&.MuiPickersPopper-root":
                                                                {
                                                                    zIndex: "7500 !important"
                                                                }
                                                        }
                                                    }
                                                }}
                                                readOnly={draftReadOnly}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        ) : null}
                    </Box>
                )}
            </>
        </>
    );
}
