import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, isValid } from "date-fns";

import { API_DATE_FORMAT } from "../../../config";
import { Reason } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import { DraftDetail } from "../../usecases/dtos/DraftDetail";
import { default as useCreateDraftApi } from "../../usecases/useCreateDraft";
import { useDeleteDraft as useDeleteDraftApi } from "../../usecases/useDeleteDraft";
import { useGetPrecogDetail } from "../../usecases/useGetPrecogDetail";
import {
    MutateDraftOptions,
    usePatchDraft
} from "../../usecases/usePatchDraft";
import { usePostDraftConfirm } from "../../usecases/usePostDraftConfirm";
import { accountingLinesQueryKeys } from "../AccountingLines/queries";
import { vatLinesQueryKeys } from "../VatLines/queries";

export const draftQueryKeys = createQueryKeys("draft", {
    all: null,
    get: (draftId: string) => [{ draftId }]
});

export const useDraft = (draftId?: string) => {
    const { get } = useGetPrecogDetail({ lazy: true });

    return useQuery({
        enabled: !!draftId,
        ...draftQueryKeys.get(draftId!),
        queryFn: () => get(draftId!)
    });
};

export const useCreateDraft = () => {
    const { create } = useCreateDraftApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["create-draft"],
        mutationFn: (reason: Reason) => create(reason),
        onSuccess: (createdDraftId) => {
            // Prefetch the newly created draft
            queryClient.prefetchQuery(draftQueryKeys.get(createdDraftId));
        }
    });
};

export const useDeleteDraft = (draftId: string) => {
    const { deleteDraft } = useDeleteDraftApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["delete-draft"],
        mutationFn: () => deleteDraft(draftId),
        onSuccess: () => {
            queryClient.invalidateQueries(draftQueryKeys.all);
        }
    });
};

export const useMutateDraft = (draftId: string) => {
    const queryClient = useQueryClient();
    const { update } = usePatchDraft({ lazy: true });

    return useMutation({
        mutationKey: ["update-draft"],
        mutationFn: (data: Partial<MutateDraftOptions>) =>
            update(draftId, data),
        onMutate: async (data) => {
            await queryClient.cancelQueries(draftQueryKeys.get(draftId));

            const { queryKey } = draftQueryKeys.get(draftId);

            const previousData = queryClient.getQueryData(queryKey);

            queryClient.setQueryData(queryKey, (old: DraftDetail) => {
                const updateRegistrationDate =
                    data.registrationDate &&
                    // get timestamp from date
                    (isValid(data.registrationDate)
                        ? // convert to seconds
                          // WHY DO WE NEED TO CONVERT TO SECONDS?!
                          // can't be bothered, this works, idgaf
                          data.registrationDate.valueOf() / 1000 ||
                          old.registrationDate
                        : old.registrationDate);

                const updateData: Partial<DraftDetail> = {
                    doc: {
                        ...old.doc,
                        date: data.docDate
                            ? format(data.docDate, API_DATE_FORMAT)
                            : old.doc.date,
                        number: data.docNumber ?? old.doc.number,
                        description: data.docDescription ?? old.doc.description
                    },
                    amount: data.amount ?? old.amount,
                    businessId: data.businessId ?? old.businessId,
                    sectionalId: data.sectionalId ?? old.sectionalId,
                    regimeId: data.regimeId ?? old.regimeId,
                    vatCollectability:
                        data.vatCollectability ?? old.vatCollectability,
                    refCurrency: data.refCurrency ?? old.refCurrency,
                    registrationDate: updateRegistrationDate,
                    customerId: data.customerId ?? old.customerId,
                    customerName: data.customerName ?? old.customerName,
                    customerVATNumber:
                        data.customerVATNumber ?? old.customerVATNumber,
                    supplierId: data.supplierId ?? old.supplierId,
                    supplierName: data.supplierName ?? old.supplierName,
                    supplierVATNumber:
                        data.supplierVATNumber ?? old.supplierVATNumber,
                    bankId: data.bankId ?? old.bankId,
                    bankName: data.bankName ?? old.bankName,
                    hasReverseCharge:
                        data.hasReverseCharge ?? old.hasReverseCharge
                };

                return {
                    ...old,
                    ...omitNilProperties(updateData)
                };
            });
            return { previousData };
        },
        onError: (_error, _variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(
                    draftQueryKeys.get(draftId).queryKey,
                    context.previousData
                );
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(draftQueryKeys.get(draftId));
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId })
            );
            queryClient.invalidateQueries(vatLinesQueryKeys.get(draftId));
        }
    });
};

export const useConfirmDraft = (params: {
    draftId: string;
    revision?: string;
}) => {
    const queryClient = useQueryClient();
    const { confirm } = usePostDraftConfirm({ lazy: true });

    return useMutation({
        mutationKey: ["confirm-draft"],
        mutationFn: () => confirm(params.draftId, params.revision),
        onSuccess: () => {
            queryClient.invalidateQueries(draftQueryKeys.get(params.draftId));
        }
    });
};
