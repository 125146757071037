import Box from "@vapor/react-material/Box";
import Step from "@vapor/react-material/Step";
import StepLabel from "@vapor/react-material/StepLabel";
import Stepper from "@vapor/react-material/Stepper";
import { useEffect, useState } from "react";

import { usePostAssetSaleSimulationWithoutParam } from "../../../../../../../core/domain/Draft/Asset/queries";
import { AssetLogsDto } from "../../../../../../../core/usecases/dtos/AssetLogsDto";
import { Elements } from "../../../../../../../core/usecases/dtos/ListAssetsDto";
import { IPostData } from "../../../../../../../core/usecases/usePostAssetSaleSimulation";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import SalePage from "./salePage";
import SimulationSalePage from "./simulationSalePage";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.eliminationSubsection"
);

interface RemovalSubsectionProps {
    setShowElimination: Function;
    onDismission: () => void;
    onShowTabs: () => void;
    page: number;
    onChangeEliminationPage: (page: number) => void;
    logs: AssetLogsDto | null;
    asset: Elements | null;
    businessId?: string;
    setSelectedTab: Function;
}

export default function RemovalSubsection({
    setShowElimination,
    onDismission,
    onShowTabs,
    page,
    onChangeEliminationPage,
    logs,
    asset,
    setSelectedTab
}: RemovalSubsectionProps) {
    const [dataToPost, setDataToPost] = useState<IPostData>({
        businessId: asset?.businessId,
        date: new Date(Date.now()),
        disposalRate: 100,
        saleAmount: 0
    });

    const [hasFetched, setHasFetched] = useState<boolean>(false);

    const {
        mutateAsync: fetchPostData,
        data: simulationData,
        isPending: loading
    } = usePostAssetSaleSimulationWithoutParam({
        assetId: asset?.id,
        dataToPost: {
            businessId: dataToPost.businessId,
            date: dataToPost.date,
            disposalRate: dataToPost.disposalRate,
            operationDescription: dataToPost.operationDescription,
            saleAmount: dataToPost.saleAmount
        }
    });

    useEffect(() => {
        if (!hasFetched) {
            fetchPostData();
            setHasFetched(true);
        }
    }, [setHasFetched, fetchPostData, dataToPost, hasFetched]);

    const handleClickNext = () => {
        onChangeEliminationPage(2);
        fetchPostData();
    };

    return (
        <Box marginTop="24px">
            <Box>
                <Stepper activeStep={page - 1} alternativeLabel>
                    <Step>
                        <StepLabel>{fs("deletionData")}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{fs("accountingPreview")}</StepLabel>
                    </Step>
                </Stepper>
                {page === 1 && (
                    <SimulationSalePage
                        onClickNext={handleClickNext}
                        simulationData={simulationData?.data}
                        isLoading={loading}
                        asset={asset}
                        dataToPost={dataToPost}
                        setDataToPost={setDataToPost}
                        setHasFetched={setHasFetched}
                        logs={logs}
                    />
                )}
                {page === 2 && (
                    <SalePage
                        onDismission={onDismission}
                        isLoading={loading}
                        setShowElimination={setShowElimination}
                        assetId={asset?.id}
                        dataToPost={dataToPost}
                        setSelectedTab={setSelectedTab}
                        onChangeEliminationPage={onChangeEliminationPage}
                        simulationData={simulationData?.data}
                        onShowTabs={onShowTabs}
                    />
                )}
            </Box>
        </Box>
    );
}
