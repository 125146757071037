import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import { isValid } from "date-fns";
import { isEmpty } from "lodash";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import {
    Status,
    useGetAllUsedGoods
} from "../../../core/usecases/useGetAllUsedGoods";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import BackArrow from "../../components/BackArrow";
import FormattedAmount from "../../components/FormattedAmount";
import Loader from "../../components/Loader";
import NotFoundBanner from "../../components/NotFoundBanner";
import UsedGoodsListTable from "./UsedGoodsListTable";

const fs = getFormattedStringWithScope("views.ListUsedGoods");

export default function UsedGoodsList() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [description, setDescription] = useState<string | null>(null);
    const previousDescription = usePrevious(description);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const previousStartDate = usePrevious(startDate);

    const [endDate, setEndDate] = useState<Date | null>(null);
    const previousEndDate = usePrevious(endDate);

    const [status, setStatus] = useState<Status | null | undefined>(null);
    const previousStatus = usePrevious(status);

    const {
        fetch: fetchUsedGoods,
        hasFetched: hasFetchedUsedGoods,
        loading: loadingUsedGoods,
        usedGoods,
        error: errorUsedGoodsList
    } = useGetAllUsedGoods({
        lazy: true,
        description: description === "" ? null : description,
        startDate: startDate,
        endDate: endDate,
        status: status,
        page: 0,
        pageSize: 10
    });

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    useEffect(() => {
        if (previousCustomer !== customer) {
            setHasInitialized(false);
        }
    }, [previousCustomer, customer]);

    useEffect(() => {
        if (customer && !hasInitialized) {
            fetchUsedGoods();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchUsedGoods, customer]);

    useEffect(() => {
        if (
            hasInitialized &&
            (description !== previousDescription ||
                startDate !== previousStartDate ||
                endDate !== previousEndDate ||
                status !== previousStatus)
        ) {
            fetchUsedGoods();
        }
    }, [
        description,
        endDate,
        fetchUsedGoods,
        hasInitialized,
        previousDescription,
        previousEndDate,
        previousStartDate,
        previousStatus,
        startDate,
        status
    ]);

    const handleChangeDescription = (newDescription: string) => {
        setDescription(newDescription);
    };

    const handleStartDateChange = debounce((newStartDate: Date) => {
        if (isValid(newStartDate)) {
            setStartDate(newStartDate);
        }
    }, 300);

    const handleEndDateChange = debounce((newEndDate: Date) => {
        if (isValid(newEndDate)) {
            setEndDate(newEndDate);
        }
    }, 300);

    const handleStatusChange = (newStatus?: Status | null) => {
        setStatus(newStatus);
    };

    const totalAmount = usedGoods?.reduce((a, b) => a + b?.initialPurchase, 0);

    return (
        <Loader loading={loadingUsedGoods}>
            <OneBoxPage headerLeft={<BackArrow />} title={fs("title")}>
                <Box display="flex" gap={6} marginLeft={1.8} marginRight={1.8}>
                    <Stack>
                        <Typography
                            variant="subtitle"
                            color="primary.textTitleColor"
                        >
                            {fs("totalUsedGoods")}
                        </Typography>
                        <FormattedAmount
                            variant="displayLarge"
                            amount={totalAmount ?? 0}
                            currency="EUR"
                        />
                    </Stack>
                </Box>
                <Box marginTop={3} marginLeft={1.8} marginRight={1.8}>
                    {hasFetchedUsedGoods && isEmpty(usedGoods) ? (
                        <NotFoundBanner text={fs("notFound")} />
                    ) : usedGoods ? (
                        <UsedGoodsListTable
                            handleChangeDescription={handleChangeDescription}
                            handleStartDateChange={handleStartDateChange}
                            handleEndDateChange={handleEndDateChange}
                            handleStatusChange={handleStatusChange}
                            usedGoods={usedGoods}
                        />
                    ) : errorUsedGoodsList ? (
                        <NotFoundBanner text={fs("errorLoadingTable")} />
                    ) : null}
                </Box>
            </OneBoxPage>
        </Loader>
    );
}
