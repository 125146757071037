import { VaporToolbar } from "@vapor/react-custom";
import { Typography } from "@vapor/react-extended";
import { Times } from "@vapor/react-icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/react-material";
import { useState } from "react";
import { WorkspaceSettlement } from "../../../../../core/domain/Vat/dto";
import { useMutateSettlement } from "../../../../../core/domain/Vat/queries";

interface SettlementToolbarProps {
    settlement: WorkspaceSettlement;
}

const WorkspaceSettlementToolbar = (props: SettlementToolbarProps) => {
    const [isOpen, setOpen] = useState(false);

    const toggleOpen = () => setOpen((state) => !state);

    const mutateSettlement = useMutateSettlement(
        props.settlement.period.year,
        props.settlement.period.ordinal
    );

    const handleOpen = () => {
        mutateSettlement.mutate("open");
    };

    const handleClose = () => {
        mutateSettlement.mutate("close");
    };

    return (
        <VaporToolbar
            contentRight={
                <>
                    {props.settlement.state === "OPEN" && (
                        <Button variant="contained" disabled>
                            Chiudi periodo
                        </Button>
                    )}

                    {props.settlement.state === "CLOSABLE" && (
                        <Button variant="contained" onClick={handleClose}>
                            Chiudi periodo
                        </Button>
                    )}

                    {props.settlement.state === "CLOSING" && (
                        <Button variant="contained" disabled>
                            Chiusura in corso
                        </Button>
                    )}

                    {props.settlement.state === "OPENABLE" && (
                        <Button variant="contained" onClick={handleOpen}>
                            Riapri periodo
                        </Button>
                    )}

                    {props.settlement.state === "OPENING" && (
                        <Button variant="contained" disabled>
                            Riapertura in corso
                        </Button>
                    )}

                    {props.settlement.state === "CLOSED" && (
                        <Button variant="contained" disabled>
                            Riapri periodo
                        </Button>
                    )}

                    {props.settlement.state === "JOURNALIZED" && (
                        <>
                            <Dialog open={isOpen}>
                                <DialogTitle>
                                    {"Riapertura periodo"}
                                    <IconButton
                                        size="small"
                                        onClick={toggleOpen}
                                    >
                                        <Times />
                                    </IconButton>
                                </DialogTitle>
                                <Divider variant="fullWidth" />
                                <DialogContent>
                                    <Typography>
                                        {
                                            "Attenzione, per questo periodo sono giá stati generati i registri."
                                        }
                                        <p>
                                            {
                                                "Elimina i registri esistenti per poter riaprire."
                                            }
                                        </p>
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={toggleOpen}
                                    >
                                        {"Annulla"}
                                    </Button>
                                    <Button variant="contained">
                                        {"Vai ai Registri contabili"}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Button variant="contained" onClick={toggleOpen}>
                                Riapri periodo
                            </Button>
                        </>
                    )}
                </>
            }
        />
    );
};

export default WorkspaceSettlementToolbar;
