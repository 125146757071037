import { Typography } from "@vapor/react-extended";
import { Box, Card, CircularProgress, Stack } from "@vapor/react-material";
import { DetailGroup, Period } from "../../../../../core/domain/Vat/dto";
import { useBusinessSettlements } from "../../../../../core/domain/Vat/queries";
import FormattedAmount from "../../../../components/FormattedAmount";
import SettlementTable from "./settlement-table";

const Detail = (props: { label: string; amount: number | undefined }) => {
    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Typography variant="bodySmall">{props.label}</Typography>
            <FormattedAmount
                amount={props.amount ?? 0}
                currency="EUR"
                variant="body700"
            />
        </Stack>
    );
};

const MainDetail = (props: { label: string; amount: number }) => {
    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: "space-between",
                alignItems: "baseline",
                pt: 5
            }}
        >
            <Typography variant="bodySmall700">{props.label}</Typography>
            <Typography variant="h6" fontWeight="600">
                <FormattedAmount
                    amount={props.amount}
                    currency="EUR"
                    variant="h4"
                    fontWeight="600"
                />
            </Typography>
        </Stack>
    );
};

interface BusinessSettlementDetailsProps {
    period: Period;
    businessId: string;
}

const DETAILS = ["SALE", "PURCHASE", "RECEIPT"] satisfies Array<DetailGroup>;

// Anche sta roba, internazionaliziamo quando abbiamo le robe da internazionalizare
const DETAILS_LABELS = {
    PURCHASE: "IVA su acquisti",
    SALE: "IVA su vendite",
    RECEIPT: "IVA su corrispettivi"
};

const BusinessSettlementDetails = (props: BusinessSettlementDetailsProps) => {
    const { data: settlements, isLoading } = useBusinessSettlements(
        props.businessId,
        props.period.year,
        props.period.ordinal
    );

    if (isLoading) {
        return (
            <Box px={2}>
                <CircularProgress />
            </Box>
        );
    }

    const [settlement] = settlements ?? [];

    if (!settlement) {
        return (
            <Box px={2}>
                {"Nel periodo selezionato non sono presenti contabilizzazioni"}
            </Box>
        );
    }

    return (
        <Stack gap={8}>
            <Box px={2}>
                <Card variant="outlined" sx={{ padding: 2, width: 384 }}>
                    {DETAILS.map((detail) => {
                        return (
                            <Detail
                                key={detail}
                                label={DETAILS_LABELS[detail]}
                                amount={
                                    settlement.details[detail]
                                        ? settlement.details[detail].vatAmount
                                        : 0
                                }
                            />
                        );
                    })}

                    <MainDetail
                        label={
                            settlement.amounts["REGULAR"] >= 0
                                ? "IVA a debito"
                                : "IVA a credito"
                        }
                        amount={Math.abs(settlement.amounts["REGULAR"])}
                    />
                </Card>
            </Box>

            <SettlementTable settlement={settlement} />
        </Stack>
    );
};

export default BusinessSettlementDetails;
