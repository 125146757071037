import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Spinner,
    Table
} from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box } from "@vapor/react-material/Box";
import { Button } from "@vapor/react-material/Button";
import { Grid } from "@vapor/react-material/Grid";
import { InputLabel } from "@vapor/react-material/InputLabel";
import { Radio } from "@vapor/react-material/Radio";
import { RadioGroup } from "@vapor/react-material/RadioGroup";
import TextField from "@vapor/react-material/TextField";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { useEffect, useState } from "react";

import { Filter } from "@vapor/react-icons";
import { Elements } from "../../../core/usecases/dtos/ListAssetsDto";
import { useGetAssetsSearch } from "../../../core/usecases/useGetAssetsSearch";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import FormattedDate from "../../components/FormattedDate";

interface AssetSearchProps {
    businessId: string | undefined;
    refCurrency: string | null;
    selectedAsset: string | undefined;
    onConfirm: (selectedAsset: string | undefined) => void;
    accountCode?: string;
}

const fs = getFormattedStringWithScope("views.Detail.assets.drawer");

const AssetsSearch = ({
    businessId,
    refCurrency,
    selectedAsset,
    onConfirm,
    accountCode
}: AssetSearchProps) => {
    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string | null>(null);
    const [purchaseDateFrom, setPurchaseDateFrom] = useState<Date | null>(null);
    const [purchaseDateTo, setPurchaseDateTo] = useState<Date | null>(null);
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        fetch: fetchAssetSearch,
        assetsList,
        loading: loadingAssetList
    } = useGetAssetsSearch();

    useEffect(() => {
        if (!hasInitialized) {
            fetchAssetSearch({
                accountCode: accountCode,
                active: true,
                businessId: businessId
            });
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchAssetSearch, accountCode, businessId]);

    const handleSelect = async (newSearchString: string) => {
        setSearchString(newSearchString);
        await fetchAssetSearch({
            accountCode: accountCode,
            active: true,
            businessId: businessId,
            description: newSearchString,
            purchaseDateFrom: purchaseDateFrom?.toDateString(),
            purchaseDateTo: purchaseDateTo?.toDateString()
        });
    };

    const headCells: HeadCell[] = [
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        },
        {
            id: "subkind",
            kind: ColumnType.text,
            label: fs("search.table.subkind")
        },
        {
            id: "description",
            kind: ColumnType.text,
            label: fs("search.table.description")
        },
        {
            id: "code",
            kind: ColumnType.text,
            label: fs("search.table.code")
        },
        {
            id: "purchaseDate",
            kind: ColumnType.text,
            label: fs("search.table.purchaseDate")
        },
        {
            id: "amount",
            kind: ColumnType.text,
            label: fs("search.table.amount")
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.cta,
            render: (asset: Elements) => (
                <Radio
                    name="radio-asset"
                    value={asset.id}
                    onChange={() => {
                        onConfirm(asset.id);
                    }}
                />
            )
        },
        // subkind
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography>{asset.subKindDesc}</Typography>
            )
        },
        // description
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography>{asset.description.split("~")[0]}</Typography>
            )
        },
        // code
        {
            kind: ColumnType.text,
            render: (asset: Elements) => <Typography>{asset.alias}</Typography>
        },
        // purchaseDate
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography>
                    <FormattedDate
                        variant="body500"
                        date={asset.purchaseDate}
                    />
                </Typography>
            )
        },
        // amount
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography>
                    <FormattedAmount
                        amount={asset?.amount}
                        currency={refCurrency ?? "EUR"}
                    />
                </Typography>
            )
        }
    ];

    return (
        <Box sx={{ margin: "16px 40px" }}>
            <Grid container xs={12} spacing={2} alignItems={"flex-end"}>
                <Grid xs={6} item>
                    <Grid
                        container
                        xs={12}
                        sx={{ width: "100%" }}
                        alignItems={"flex-end"}
                    >
                        <Grid xs item>
                            <InputLabel htmlFor="assetName">
                                {fs("search.autocomplete")}
                            </InputLabel>
                            <TextField
                                value={searchString}
                                type="search"
                                onChange={(event: any) =>
                                    handleSelect(event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={4} item>
                    <Button
                        variant="text"
                        startIcon={<Filter />}
                        onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                    >
                        {fs("search.filters")}
                    </Button>
                </Grid>
            </Grid>
            {isFiltersVisible && (
                <Grid
                    container
                    xs={12}
                    alignItems={"flex-end"}
                    sx={{ margin: "16px 0", width: "100%" }}
                >
                    <Grid xs={8} item>
                        <Grid
                            container
                            xs={12}
                            alignItems={"flex-end"}
                            gap={"8px"}
                        >
                            <Grid xs={5.2} item>
                                <InputLabel htmlFor="assetName">
                                    {fs("search.dateFrom")}
                                </InputLabel>
                                <DatePicker
                                    value={purchaseDateFrom}
                                    onChange={(date: any) =>
                                        setPurchaseDateFrom(date)
                                    }
                                />
                            </Grid>
                            <Grid xs={5.2} item>
                                <InputLabel htmlFor="assetName">
                                    {fs("search.dateTo")}
                                </InputLabel>
                                <DatePicker
                                    value={purchaseDateTo}
                                    onChange={(date: any) =>
                                        setPurchaseDateTo(date)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Box marginTop="16px">
                <RadioGroup
                    value={selectedAsset || ""}
                    name="radio-asset-group"
                >
                    <Spinner loading={loadingAssetList}>
                        <Table
                            headCells={headCells}
                            columns={columns}
                            data={assetsList?._embedded.elements || []}
                        />
                    </Spinner>
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default AssetsSearch;
