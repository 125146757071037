import { createContext, ReactNode, useContext } from "react";

import { DR1DetailComponent } from "./DR1DetailComponent";

interface DR1DetailProvider {
    open: (draftId: string) => void;
}

export interface DR1DetailComponentProps {
    children: ReactNode;
}

export const DR1DetailContext = createContext<DR1DetailProvider>({
    open: () => {}
});

export const useDR1Detail = (): DR1DetailProvider => {
    return useContext(DR1DetailContext);
};

export const DR1DetailService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <DR1DetailComponent>{children}</DR1DetailComponent>
        )
    }
});

export default DR1DetailComponent;
