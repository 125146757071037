import { OneBoxPage } from "@drift/oneplatfront";
import { EmptyState, VaporHeaderBar } from "@vapor/react-custom";
import { Times } from "@vapor/react-icons";
import { Box, Button, Drawer, IconButton } from "@vapor/react-material";
import { useState } from "react";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import BackArrow from "../../components/BackArrow";
import CreateBalance from "./CreateBalance";

const fs = getFormattedStringWithScope("views.Balance");

export default function Balance() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    return (
        <OneBoxPage
            headerLeft={<BackArrow />}
            title={fs("title")}
            showCustomerSelector={false}
        >
            <Drawer anchor="right" open={isDrawerOpen} hideBackdrop width={720}>
                <VaporHeaderBar
                    divider={true}
                    rightItems={[
                        <IconButton
                            color="primary"
                            onClick={() => setIsDrawerOpen(false)}
                        >
                            <Times />
                        </IconButton>
                    ]}
                    title="Generazione report del Bilancio d’esercizio"
                    size="small"
                />
                <CreateBalance onSuccess={() => setIsDrawerOpen(false)} />
            </Drawer>

            <Box
                className="c-Balance-content"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                position="relative"
            >
                <Box
                    display="flex"
                    justifyContent="end"
                    width="100%"
                    position="absolute"
                    top={0}
                    right={0}
                    padding="1rem"
                >
                    <Button
                        variant="contained"
                        onClick={() => setIsDrawerOpen(true)}
                    >
                        {fs("buttonLabelReport")}
                    </Button>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >
                    <Box mb={2}>
                        <EmptyState
                            badgeIcon
                            description={fs("descriptionNoReport")}
                            secondaryButtonProps={{
                                children: fs("buttonLabelFirstReport"),
                                onClick: () => setIsDrawerOpen(true)
                            }}
                            title={fs("labelNoReport")}
                        />
                    </Box>
                </Box>
            </Box>
        </OneBoxPage>
    );
}
