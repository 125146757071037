/**
 * Documentation about how these headers are built can be found at:
 * https://tsspa.sharepoint.com/:x:/r/sites/G365-OnePlatform-PrimaNota/Documenti%20condivisi/Precontabilit%C3%A0/Mappatura%20causali%20contabili%20per%20FE.xlsx?d=w143a70ea618b4af5b63bb5d541e25a8e&csf=1&web=1&e=adtpJD
 */
import { useCurrentWorkspace } from "@drift/oneplatfront";
import { BanksLookup, CSLFormLookup } from "@onefront/workspace-forms";
import { CslSearchDto } from "@onefront/workspace-forms/typings/dtos/csl/CslSearchDto";
import { IBankForm } from "@onefront/workspace-forms/typings/interfaces/BanksForm";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField
} from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { fromUnixTime, parse } from "date-fns";
import { capitalize, debounce } from "lodash";
import { useEffect } from "react";
import {
    API_DATE_FORMAT,
    API_DATETIME_FORMAT_WITHOUT_MILLISEDCONDS
} from "../../../config";
import { readOnlySelect } from "../../../core/commons/read-only-select";
import { useDraft, useMutateDraft } from "../../../core/domain/Draft/queries";
import { useVatLines } from "../../../core/domain/VatLines/queries";
import { useVatRegimes } from "../../../core/domain/VatRegimes/queries";
import {
    useBusinessAndSectionals,
    useReverseChargeSectionals
} from "../../../core/domain/Workspace/BusinessAndSectionals/hooks";
import { DraftDetail } from "../../../core/usecases/dtos/DraftDetail";
import { useWorkspaceConfig } from "../../../services/WorkspaceConfigService";
import {
    CslType,
    Reason as ReasonEnum,
    VatCollectability as VatCollectabilityEnum
} from "../../../utils/appEnums";
import {
    parseBusinessSectional,
    stringifyBusinessSectionalAsStrings
} from "../../../utils/businessSectionalUtils";
import { isReadOnly, isXML } from "../../../utils/draftUtils";
import { isVat } from "../../../utils/reasonUtils";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import {
    computeTaxableAmount,
    computeTaxAmount
} from "../../../utils/vatUtils";
import CurrencyField from "../CurrencyField";
import CustomerSupplierInformation from "../CustomerSupplierInformation";
import { DocumentPreviewer } from "../DocumentPreview";
import DocumentAmountsCard from "./Graphics/DocumentAmountsCard";
import HeaderFields from "./Graphics/HeaderFields";
import SdiCode from "./Graphics/SdiCode";
import {
    CustomerSupplierSelectorContainer,
    TotalAmountSelectCurrency
} from "./Graphics/styled";

type CommonHeaderFieldProps = {
    draftId: string;
    required?: boolean;
    readOnly?: boolean;
};

type DocumentNumberProps = CommonHeaderFieldProps;
const DocumentNumber = ({
    draftId,
    readOnly,
    required
}: DocumentNumberProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { number } = draft.doc;

        const handleChange = (docNumber: string) => {
            mutate({
                docNumber
            });
        };

        return (
            <TextField
                value={number}
                onChange={(e) => handleChange(e.currentTarget.value)}
                readOnly={readOnly || isXML(draft)}
                required={required}
                placeholder={fs("insertDocumentNumber")}
            />
        );
    }

    return null;
};

type DocumentDateProps = CommonHeaderFieldProps;
const DocumentDate = ({ draftId, readOnly }: DocumentDateProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { doc } = draft;

        const handleChange = (docDate: Date) => {
            mutate({
                docDate
            });
        };

        const value = doc.date
            ? parse(doc.date, API_DATE_FORMAT, new Date())
            : null;

        return (
            <DatePicker
                value={value}
                // TODO: inspect why vapor has strange typization
                onChange={(date: any) => handleChange(date)}
                readOnly={readOnly || isXML(draft)}
            />
        );
    }

    return null;
};

type RegistrationDateProps = CommonHeaderFieldProps;
const RegistrationDate = ({
    draftId,
    readOnly = false,
    required
}: RegistrationDateProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { commencementDate } = useWorkspaceConfig();
    const { mutate } = useMutateDraft(draftId);
    // TODO: study if the translation can be differently scoped
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { registrationDate } = draft;

        const handleChange = debounce((registrationDate: Date) => {
            mutate({
                registrationDate
            });
        }, 3000);

        const value = registrationDate ? fromUnixTime(registrationDate) : null;

        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>
                    {fs("registrationDate")}
                </InputLabel>
                <DatePicker
                    value={value}
                    // TODO: inspect why vapor has strange typization
                    onChange={(date: any) => handleChange(date)}
                    minDate={commencementDate}
                    readOnly={readOnly}
                />
            </FormControl>
        );
    }

    return null;
};

type VatCompetencePeriodProps = CommonHeaderFieldProps;
const VatCompetencePeriod = ({
    draftId,
    readOnly = false,
    required
}: VatCompetencePeriodProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { vatCompetencePeriod } = draft;

        const handleChange = (vatCompetencePeriod: Date) => {
            mutate({
                vatCompetencePeriod
            });
        };

        const value = vatCompetencePeriod
            ? parse(
                  vatCompetencePeriod,
                  API_DATETIME_FORMAT_WITHOUT_MILLISEDCONDS,
                  new Date()
              )
            : null;

        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>
                    {fs("vatCompetence")}
                </InputLabel>
                <DatePicker
                    value={value}
                    format="MM/yyyy"
                    views={["month", "year"]}
                    onChange={(date: any) => handleChange(date)}
                    readOnly={readOnly}
                />
            </FormControl>
        );
    }

    return null;
};

type ReasonProps = CommonHeaderFieldProps;
const Reason = ({ draftId }: ReasonProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { reason } = draft;

        return (
            <FormControl fullWidth>
                <InputLabel>{fs("reason")}</InputLabel>
                <TextField value={reason} readOnly />
            </FormControl>
        );
    }

    return null;
};

type VatRegimeProps = CommonHeaderFieldProps;
const VatRegime = ({
    draftId,
    required = false,
    readOnly = false
}: VatRegimeProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const { data: vatRegimes } = useVatRegimes(draft?.reasonId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { regimeId } = draft;

        const handleChange = (regime: string) => {
            mutate({
                regimeId: regime
            });
        };

        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>{fs("vatRegime")}</InputLabel>
                <Select
                    // also set the key to the regimeId to force the select to update
                    key={regimeId}
                    value={regimeId}
                    onChange={(e) => handleChange(e.target.value as string)}
                    {...readOnlySelect(readOnly)}
                >
                    {vatRegimes?.map((regime) => (
                        <MenuItem key={regime.id} value={regime.id}>
                            {capitalize(regime.description)}
                        </MenuItem>
                    )) ?? []}
                </Select>
            </FormControl>
        );
    }

    return null;
};

type BusinessAndSectionalProps = CommonHeaderFieldProps;
const BusinessAndSectional = ({
    draftId,
    required = false,
    readOnly = false
}: BusinessAndSectionalProps) => {
    const {
        data: draft,
        isLoading,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const {
        data: businessesAndSectionals,
        isSuccess: isSuccessBusinessesAndSectionals
    } = useBusinessAndSectionals({
        reason: draft?.reasonId
    });
    const fs = useFormattedStringWithScope("components.Header");

    const handleSelectChange = (value: string) => {
        const { businessId: businessIdValue, sectionalId: sectionalIdValue } =
            parseBusinessSectional(value);

        // also update regime if present
        const { regime } =
            businessesAndSectionals?.find(
                ({ business }) => business.id === businessIdValue
            ) || {};

        if (regime) {
            mutate({
                businessId: businessIdValue,
                sectionalId: sectionalIdValue,
                regimeId: regime
            });
        } else {
            mutate({
                businessId: businessIdValue,
                sectionalId: sectionalIdValue
            });
        }
    };

    if (isLoading) {
        return (
            <Skeleton variant="rectangular" width="100%" height="100%">
                <Select />
            </Skeleton>
        );
    }

    if (isSuccessDraft && isSuccessBusinessesAndSectionals) {
        const { businessId, sectionalId } = draft;
        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>
                    {fs("businessAndSectional")}
                </InputLabel>
                <Select
                    value={stringifyBusinessSectionalAsStrings(
                        businessId,
                        sectionalId
                    )}
                    onChange={(e) =>
                        handleSelectChange(e.target.value as string)
                    }
                    {...readOnlySelect(readOnly)}
                >
                    {businessesAndSectionals.map(({ business, sectionals }) => {
                        return sectionals.map((sectional, index) => (
                            <MenuItem
                                key={index}
                                value={stringifyBusinessSectionalAsStrings(
                                    business.id,
                                    sectional.id
                                )}
                            >
                                {`${sectional.name} - ${business.description}`}
                            </MenuItem>
                        ));
                    })}
                </Select>
            </FormControl>
        );
    }

    return null;
};

type VatCollectabilityProps = CommonHeaderFieldProps;
const VatCollectability = ({
    draftId,
    readOnly = false,
    required
}: VatCollectabilityProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");
    const fsVatCollectability = useFormattedStringWithScope(
        "commons.vatCollectability"
    );

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { vatCollectability } = draft;

        const handleChange = (vatCollectability: string) => {
            mutate({
                vatCollectability
            });
        };

        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>
                    {fs("vatCollectability")}
                </InputLabel>
                <Select
                    value={vatCollectability}
                    onChange={(e) => handleChange(e.target.value as string)}
                    {...readOnlySelect(readOnly)}
                >
                    {Object.values(VatCollectabilityEnum).map(
                        (vatCollectability, index) => (
                            <MenuItem key={index} value={vatCollectability}>
                                {fsVatCollectability(vatCollectability)}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        );
    }

    return null;
};

type DocumentDescriptionProps = CommonHeaderFieldProps;
const DocumentDescription = ({
    draftId,
    readOnly,
    required
}: DocumentDescriptionProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { description } = draft.doc;

        const handleChange = debounce((docDescription: string) => {
            mutate({
                docDescription
            });
        }, 500);

        return (
            <FormControl required={required} fullWidth>
                <InputLabel required={required}>
                    {fs("docDescription")}
                </InputLabel>
                <TextField
                    value={description}
                    onChange={(e) => handleChange(e.currentTarget.value)}
                    readOnly={readOnly}
                />
            </FormControl>
        );
    }

    return null;
};

type DocumentAmountsProps = CommonHeaderFieldProps;
const DocumentAmounts = ({
    draftId,
    readOnly: readOnlyProp = false
}: DocumentAmountsProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const { data: vatLines } = useVatLines(draftId);

    const taxAmount = vatLines ? computeTaxAmount(vatLines) : 0;
    const taxableAmount = vatLines ? computeTaxableAmount(vatLines) : 0;

    if (!isSuccess) return null;

    const { refCurrency } = draft;
    const readOnly = isXML(draft) || readOnlyProp;

    const handleChangeCurrency = (currency: string) => {
        mutate({
            refCurrency: currency
        });
    };

    const handleChangeAmount = (amount: number) => {
        mutate({
            amount
        });
    };

    return (
        <DocumentAmountsCard
            currency={refCurrency ?? null}
            totalAmount={
                <CurrencyField
                    value={draft.amount}
                    transparent={true}
                    big={true}
                    readOnly={readOnly}
                    onChange={handleChangeAmount}
                />
            }
            totalAmountCurrency={
                <TotalAmountSelectCurrency
                    value={refCurrency ?? "EUR"}
                    readOnly={readOnly}
                    onChange={(e) =>
                        handleChangeCurrency(e.target.value as string)
                    }
                />
            }
            showTaxAmounts={isVat(draft.reasonId)}
            taxAmount={taxAmount}
            taxableAmount={taxableAmount}
        />
    );
};

type CustomerSupplierProps = CommonHeaderFieldProps & {
    type: CslType;
};
const CustomerSupplier = ({
    draftId,
    type,
    readOnly = false,
    required = true
}: CustomerSupplierProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutateAsync } = useMutateDraft(draftId);
    const { id: workspaceId } = useCurrentWorkspace(true).workspace ?? {
        id: ""
    };
    const fs = useFormattedStringWithScope(
        "components.FormCustomerSupplierLookup"
    );

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const id =
            type === CslType.CUSTOMER ? draft.customerId : draft.supplierId;
        const name =
            type === CslType.CUSTOMER ? draft.customerName : draft.supplierName;
        const taxId =
            type === CslType.CUSTOMER
                ? draft.customerTaxId
                : draft.supplierTaxId;
        const vatNumber =
            type === CslType.CUSTOMER
                ? draft.customerVATNumber
                : draft.supplierVATNumber;

        if (readOnly || isXML(draft)) {
            return (
                <CustomerSupplierInformation
                    name={name}
                    taxId={taxId ?? ""}
                    vatNumber={vatNumber ?? ""}
                    type={type}
                />
            );
        }

        const handleCustomerSupplierSelection = async (
            customerSupplier: CslSearchDto
        ) => {
            const cslId = customerSupplier?.list?.find(
                (listItem: any) => listItem.relationType === type
            )?.id;

            // early return if the selected csl is the same as the current one
            if (id && id === cslId) {
                return;
            }

            if (!!customerSupplier.personalData?.surname) {
                // consider the customer to be a "privato"
                await mutateAsync({
                    [type === CslType.CUSTOMER ? "customerId" : "supplierId"]:
                        cslId,
                    [type === CslType.CUSTOMER
                        ? "customerName"
                        : "supplierName"]: `${customerSupplier.personalData.name} ${customerSupplier.personalData.surname}`,
                    [type === CslType.CUSTOMER
                        ? "customerTaxId"
                        : "supplierTaxId"]:
                        customerSupplier.personalData.taxIdentifier,
                    [type === CslType.CUSTOMER
                        ? "customerVATNumber"
                        : "supplierVATNumber"]: undefined
                });
            } else {
                // consider the customer to be a "azienda"
                await mutateAsync({
                    [type === CslType.CUSTOMER ? "customerId" : "supplierId"]:
                        cslId,
                    [type === CslType.CUSTOMER
                        ? "customerName"
                        : "supplierName"]:
                        customerSupplier?.personalData?.businessName,
                    [type === CslType.CUSTOMER
                        ? "customerTaxId"
                        : "supplierTaxId"]:
                        customerSupplier?.personalData?.taxIdentifier,
                    [type === CslType.CUSTOMER
                        ? "customerVATNumber"
                        : "supplierVATNumber"]:
                        customerSupplier?.personalData?.vatIdentifier
                });
            }
        };

        return (
            <Stack>
                <CustomerSupplierSelectorContainer
                    error={false} // TODO: implement error handling
                    sx={{
                        width: "100%"
                    }}
                >
                    <CSLFormLookup
                        label={`${fs(type)}${required ? " *" : ""}`}
                        workspaceId={workspaceId}
                        relationType={type}
                        selectId={id}
                        onSelect={handleCustomerSupplierSelection}
                    />
                </CustomerSupplierSelectorContainer>
                <CustomerSupplierInformation
                    taxId={taxId ?? ""}
                    vatNumber={vatNumber ?? ""}
                />
            </Stack>
        );
    }

    return null;
};

type BankProps = CommonHeaderFieldProps;
const Bank = ({ draftId, readOnly = false, required = false }: BankProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const { id: workspaceId } = useCurrentWorkspace(true).workspace ?? {
        id: ""
    };
    const fs = useFormattedStringWithScope("components.FormBankLookup");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { bankId } = draft;

        const handleBankSelection = (bank: IBankForm | null) => {
            if (
                bank &&
                // only update bank if it's different from the current one
                bank.id !== bankId
            ) {
                mutate({
                    bankId: bank.id ?? "",
                    bankName: bank.bankName ?? ""
                });
            }
        };

        return (
            <Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <CustomerSupplierSelectorContainer
                        error={false} // TODO: implement error handling
                        sx={{
                            width: "100%"
                        }}
                    >
                        <BanksLookup
                            label={`${fs("bank")}${required ? " *" : ""}`}
                            cslId={null}
                            workspaceId={workspaceId}
                            onSelect={handleBankSelection}
                            selectedId={bankId}
                            editing={!readOnly}
                        />
                    </CustomerSupplierSelectorContainer>
                </Stack>
            </Stack>
        );
    }

    return null;
};

type ReverseChargeCheckBoxProps = CommonHeaderFieldProps;
const ReverseChargeCheckBox = ({
    draftId,
    readOnly
}: ReverseChargeCheckBoxProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const fs = useFormattedStringWithScope("components.Header");

    if (isLoading) {
        return (
            <Skeleton>
                <TextField />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const { hasReverseCharge } = draft;

        const handleChange = (reverseCharge: boolean) => {
            mutate({
                hasReverseCharge: reverseCharge
            });
        };

        return (
            <FormControlLabel
                checked={hasReverseCharge}
                disabled={readOnly}
                control={
                    <Checkbox
                        readOnly={readOnly}
                        onChange={(e) => handleChange(e.target.checked)}
                        color="primary"
                    />
                }
                label={fs("invoiceWithReverseCharge")}
            />
        );
    }

    return null;
};

type ReverseChargeProps = CommonHeaderFieldProps;
const ReverseCharge = ({ draftId, readOnly = false }: ReverseChargeProps) => {
    const { data: draft, isSuccess: isSuccessDraft } = useDraft(draftId);
    const { mutate } = useMutateDraft(draftId);
    const { data: reverseChargeSectionals } = useReverseChargeSectionals(
        draft?.businessId
    );
    const fs = useFormattedStringWithScope("components.Header");

    useEffect(() => {
        if (
            reverseChargeSectionals &&
            draft &&
            !draft?.reverseChargeSectionalId
        ) {
            const [firstReverseChargeSectional] = reverseChargeSectionals;

            firstReverseChargeSectional &&
                mutate({
                    reverseChargeSectionalId: firstReverseChargeSectional.id
                });
        }
    }, [draft, mutate, reverseChargeSectionals]);

    const options = reverseChargeSectionals
        ? reverseChargeSectionals.map((sectional) => (
              <MenuItem key={sectional.id} value={sectional.id}>
                  {sectional.name}
              </MenuItem>
          ))
        : [];

    if (!isSuccessDraft) return null;

    const { hasReverseCharge, reverseChargeSectionalId } = draft;

    return (
        <FormControl disabled={!hasReverseCharge || readOnly}>
            <InputLabel disabled={!hasReverseCharge || readOnly}>
                {fs("selectReverseChargeSectional")}
            </InputLabel>
            <Select
                value={reverseChargeSectionalId}
                // TODO: inspect if this works as expected (spoiler: it doesn't)
                {...readOnlySelect(!hasReverseCharge || readOnly)}
                onChange={(e) =>
                    mutate({
                        reverseChargeSectionalId: e.target.value as string
                    })
                }
            >
                {options}
            </Select>
        </FormControl>
    );
};

type CommonHeaderProps = {
    draftId: string;
    readOnly?: boolean;
    withGoOnButton?: boolean;
    onGoOn?: () => void;
};

type GoOnButtonProps = {
    onClick: () => void;
    draft: DraftDetail;
    requiredFields: (keyof DraftDetail)[];
    docRequiredFields: (keyof DraftDetail["doc"])[];
};
const GoOnButton = ({
    onClick,
    draft,
    requiredFields,
    docRequiredFields
}: GoOnButtonProps) => {
    const fs = useFormattedStringWithScope("components.Header");

    const isGoOnButtonDisabled =
        requiredFields.some((field) => !draft[field]) ||
        !draft.doc ||
        docRequiredFields.some((field) => !draft.doc[field]);

    return (
        <Button
            onClick={onClick}
            variant="contained"
            color="primary"
            disabled={isGoOnButtonDisabled}
            sx={{
                alignSelf: "flex-end"
            }}
        >
            {fs("goOn")}
        </Button>
    );
};

type FatturaEmessaHeaderProps = CommonHeaderProps;
const FatturaEmessaHeader = ({
    draftId,
    readOnly = false,
    withGoOnButton = false,
    onGoOn = () => {}
}: FatturaEmessaHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                customer={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.CUSTOMER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCollectability={
                    <VatCollectability
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCompetence={
                    <VatCompetencePeriod
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                vatRegime={
                    <VatRegime
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate",
                        "vatCollectability",
                        "regimeId"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type FatturaAcquistoHeaderProps = CommonHeaderProps;
const FatturaAcquistoHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton = false
}: FatturaAcquistoHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc, showReverseCharge } = draft;

    const getReverseChargeFields = () => {
        if (showReverseCharge) {
            return {
                hasReverseCharge: (
                    <ReverseChargeCheckBox
                        draftId={draftId}
                        readOnly={readOnly}
                    />
                ),
                reverseCharge: (
                    <ReverseCharge draftId={draftId} readOnly={readOnly} />
                )
            };
        }

        return {};
    };

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                supplier={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.SUPPLIER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCollectability={
                    <VatCollectability
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCompetence={
                    <VatCompetencePeriod
                        draftId={draftId}
                        readOnly={readOnly}
                    />
                }
                vatRegime={
                    <VatRegime
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
                {...getReverseChargeFields()}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "supplierId",
                        "registrationDate",
                        "vatCollectability",
                        "regimeId"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type CorrispettiviGioranlieriHeaderProps = CommonHeaderProps;
const CorrispettiviGiornalieriHeader = ({
    draftId,
    readOnly = false,
    withGoOnButton = false,
    onGoOn = () => {}
}: CorrispettiviGioranlieriHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCollectability={
                    <VatCollectability
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                vatCompetence={
                    <VatCompetencePeriod
                        draftId={draftId}
                        readOnly={readOnly}
                    />
                }
                vatRegime={
                    <VatRegime
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate",
                        "vatCollectability",
                        "regimeId"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type RicaviNonIvaHeaderProps = CommonHeaderProps;
const RicaviNonIvaHeader = ({
    draftId,
    readOnly = false,
    withGoOnButton = false,
    onGoOn = () => {}
}: RicaviNonIvaHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                customer={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.CUSTOMER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type CostiGenericiNonIvaHeaderProps = CommonHeaderProps;
const CostiGenericiNonIvaHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton = false
}: CostiGenericiNonIvaHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                supplier={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.SUPPLIER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "supplierId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type MovimentoGenericoHeaderProps = CommonHeaderProps;
const MovimentoGenericoHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: MovimentoGenericoHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type RiscossaFatturaHeaderProps = CommonHeaderProps;
const RiscossaFatturaHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: RiscossaFatturaHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                customer={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.CUSTOMER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type PagamentoHeaderProps = CommonHeaderProps;
const PagamentoHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: PagamentoHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                supplier={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.SUPPLIER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "supplierId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type RiscossoAnticipoDaClienteProps = CommonHeaderProps;
const RiscossoAnticipoDaCliente = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: RiscossoAnticipoDaClienteProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                customer={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.CUSTOMER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type PagamentoNotaCreditoAClienteProps = CommonHeaderProps;
const PagamentoNotaCreditoACliente = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: PagamentoNotaCreditoAClienteProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                customer={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.CUSTOMER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "customerId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type MovimentiBancariGenericiHeaderProps = CommonHeaderProps;
const MovimentiBancariGenericiHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: MovimentiBancariGenericiHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type RiscossioneNotaCreditoDaFornitoreHeaderProps = CommonHeaderProps;
const RiscossioneNotaCreditoDaFornitoreHeader = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: RiscossioneNotaCreditoDaFornitoreHeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                supplier={
                    <CustomerSupplier
                        draftId={draftId}
                        type={CslType.SUPPLIER}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDescription={
                    <DocumentDescription
                        draftId={draftId}
                        readOnly={readOnly}
                        required={false}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "supplierId",
                        "registrationDate"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

type F24HeaderProps = CommonHeaderProps;
const F24Header = ({
    draftId,
    readOnly = false,
    onGoOn = () => {},
    withGoOnButton
}: F24HeaderProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) return null;

    const { doc } = draft;

    return (
        <Stack spacing={2}>
            <HeaderFields
                bank={
                    <Bank
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                businessAndSectional={
                    <BusinessAndSectional
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentNumber={
                    <DocumentNumber
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                reason={<Reason draftId={draftId} readOnly={readOnly} />}
                registrationDate={
                    <RegistrationDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                totalAmount={
                    <DocumentAmounts
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                documentDate={
                    <DocumentDate
                        draftId={draftId}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                docPreviewer={<DocumentPreviewer draftId={draftId} />}
                sdiCode={<SdiCode sdiCode={doc.sdiCode} />}
            />
            {withGoOnButton && (
                <GoOnButton
                    draft={draft}
                    onClick={onGoOn}
                    requiredFields={[
                        "amount",
                        "businessId",
                        "sectionalId",
                        "registrationDate",
                        "bankId"
                    ]}
                    docRequiredFields={["number", "date"]}
                />
            )}
        </Stack>
    );
};

const getReasonHeader = (reason: ReasonEnum) => {
    switch (reason) {
        case ReasonEnum.FE1:
        case ReasonEnum.FE3:
        case ReasonEnum.FE4:
        case ReasonEnum.FE401:
        case ReasonEnum.FE5:
        case ReasonEnum.FE8:
        case ReasonEnum.FE9:
        case ReasonEnum.FE10:
            return FatturaEmessaHeader;
        case ReasonEnum.FA1:
        case ReasonEnum.FA3:
        case ReasonEnum.FA4:
        case ReasonEnum.FA401:
        case ReasonEnum.FA5:
        case ReasonEnum.FA7:
        case ReasonEnum.FA8:
            return FatturaAcquistoHeader;
        case ReasonEnum.CO1:
            return CorrispettiviGiornalieriHeader;
        case ReasonEnum.RG1:
            return RicaviNonIvaHeader;
        case ReasonEnum.CG1:
            return CostiGenericiNonIvaHeader;
        case ReasonEnum.MG1:
        case ReasonEnum.GC1:
            return MovimentoGenericoHeader;
        case ReasonEnum.RF1:
            return RiscossaFatturaHeader;
        case ReasonEnum.PF1:
        case ReasonEnum.PAF1:
            return PagamentoHeader;
        case ReasonEnum.RAC1:
            return RiscossoAnticipoDaCliente;
        case ReasonEnum.PB1:
        case ReasonEnum.VB1:
        case ReasonEnum.IP1:
        case ReasonEnum.MI1:
        case ReasonEnum.PS1:
            return MovimentiBancariGenericiHeader;
        case ReasonEnum.PNC1:
            return PagamentoNotaCreditoACliente;
        case ReasonEnum.RNF1:
            return RiscossioneNotaCreditoDaFornitoreHeader;
        case ReasonEnum.PF24:
            return F24Header;
        default:
            return () => null; // ignored reason
    }
};

type HeaderProps = {
    draftId: string;
    withGoOnButton?: boolean;
    onGoOn?: () => void;
};
export const Header = ({ draftId, withGoOnButton, onGoOn }: HeaderProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isSuccess) {
        const { reasonId } = draft;

        if (reasonId) {
            return getReasonHeader(reasonId)({
                draftId,
                readOnly: isReadOnly(draft.status),
                onGoOn,
                withGoOnButton
            });
        }
    }

    return null;
};

export default Header;
