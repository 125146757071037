import Backdrop from "@mui/material/Backdrop/Backdrop";
import { CircularProgress } from "@vapor/react-material";
import { ReactNode } from "react";

interface LoaderProps {
    loading: boolean;
    children?: ReactNode;
}
const SIZE = 64;

export default function Loader({ loading, children }: LoaderProps) {
    if (loading)
        return (
            <Backdrop
                sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress size={SIZE} />
            </Backdrop>
        );
    return <>{children}</>;
}
