import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faFileLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import { uniq } from "lodash";
import { useCancelRegister } from "../../../../core/domain/Registers/queries";
import { AvailableRegistersDto } from "../../../../core/usecases/dtos/AvailableRegistersDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");
const fsPeriods = getFormattedStringWithScope("commons.period");
interface GeneratedProps {
    generated: AvailableRegistersDto["generated"];
    year: string;
}

const Generating = ({ generated, year }: GeneratedProps) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
        >
            <Typography variant="body">
                {`${uniq(generated?.periods.map(fsPeriods)).join(
                    " - "
                )}  ${year}`}
            </Typography>

            <CustomButton
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faFileLines} />}
                loading
            >
                {fs("generate")}
            </CustomButton>
        </Stack>
    );
};

export default function Generated({ generated, year }: GeneratedProps) {
    const { mutate, isPending } = useCancelRegister(year);

    if (!generated) return null;

    if (generated.status === "PENDING")
        return <Generating generated={generated} year={year} />;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
        >
            <Typography variant="body">
                {`${uniq(generated.periods.map(fsPeriods)).join(
                    " - "
                )} ${year}`}
            </Typography>

            <Stack direction="row" gap={2}>
                <CustomButton
                    color="error"
                    onClick={() => mutate()}
                    loading={isPending}
                    startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                >
                    {fs("cancel")}
                </CustomButton>
                <CustomButton
                    variant="contained"
                    loading={false}
                    startIcon={<FontAwesomeIcon icon={faDownload} />}
                    onClick={() =>
                        window.open(generated?.link, "_blank")?.focus()
                    }
                >
                    {fs("download")}
                </CustomButton>
            </Stack>
        </Stack>
    );
}
