import { Spinner } from "@comic/precog-components";
import { VaporHeaderBar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Times, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    CircularProgress,
    Drawer,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { capitalize, isEmpty, isNil } from "lodash";
import { useState } from "react";

import { usePayments } from "../../../../core/domain/Draft/Payments/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { DraftDetail } from "../../../../core/usecases/dtos/DraftDetail";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import { PaymentType } from "../../../../utils/appEnums";
import getFormattedDate from "../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import FormattedAmount from "../../FormattedAmount";
import FormattedDate from "../../FormattedDate";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import AccountDetailButton from "../AccountingLinesTable/AccountDetailButton";
import ContextualPayments from "./ContextualPayments";
import DeletePaymentDialog from "./DeletePaymentDialog";
import FiscalPayments from "./FiscalPayments";

const fs = getFormattedStringWithScope("components.Accounting.Payments");

type PaymentsDrawerProps = {
    open: boolean;
    reason: DraftDetail["reason"];
    doc: DraftDetail["doc"];
    onClose: () => void;
    children?: React.ReactNode;
};
const PaymentsDrawer = ({
    open,
    reason,
    doc,
    onClose,
    children
}: PaymentsDrawerProps) => {
    const fs = useFormattedStringWithScope(
        "components.Accounting.PaymentsDrawer"
    );
    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            sx={{
                zIndex: "2300"
            }}
        >
            <Box minWidth="688px">
                <VaporHeaderBar
                    title={fs("paymentData")}
                    description={`${reason} ${doc.number} ${fs("of")} ${
                        doc.date ? getFormattedDate(doc.date) : undefined
                    }`}
                    rightItems={
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={onClose}
                        >
                            <Times />
                        </IconButton>
                    }
                />
                <Box padding="16px">{children}</Box>
            </Box>
        </Drawer>
    );
};

type ManualPaymentsDetailDrawerProps = {
    draftId: string;
    open: boolean;
    onClose: () => void;
};
export const ManualPaymentsDetailDrawer = ({
    draftId,
    open,
    onClose
}: ManualPaymentsDetailDrawerProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) {
        return null;
    }

    return (
        <PaymentsDrawer
            open={open}
            doc={draft.doc}
            onClose={onClose}
            reason={draft.reason}
        >
            <ContextualPayments draftId={draftId} onCreate={onClose} />
        </PaymentsDrawer>
    );
};

type FiscalPaymentsDetailDrawerProps = {
    draftId: string;
    type: "CREDIT_NOTE" | "ADVANCE";
    open: boolean;
    onClose: () => void;
};
export const FiscalPaymentsDetailDrawer = ({
    draftId,
    type,
    open,
    onClose
}: FiscalPaymentsDetailDrawerProps) => {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (!isSuccess) {
        return null;
    }

    return (
        <PaymentsDrawer
            open={open}
            doc={draft.doc}
            onClose={onClose}
            reason={draft.reason}
        >
            <FiscalPayments
                draftId={draftId}
                type={type}
                onCreationSuccess={onClose}
            />
        </PaymentsDrawer>
    );
};

type ManualPaymentsDetailButtonProps = {
    draftId: string;
};
const ManualPaymentsDetailButton = ({
    draftId
}: ManualPaymentsDetailButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Stack direction="row" alignItems="center">
            <IconButton
                size="small"
                color="primary"
                onClick={() => setOpen(true)}
            >
                <VaporIcon color="primary" iconName="edit" />
            </IconButton>
            <ManualPaymentsDetailDrawer
                draftId={draftId}
                open={open}
                onClose={() => setOpen(false)}
            />
        </Stack>
    );
};

type FiscalPaymentsDetailButtonProps = {
    draftId: string;
    type: "CREDIT_NOTE" | "ADVANCE";
};
const FiscalPaymentsDetailButton = ({
    draftId,
    type
}: FiscalPaymentsDetailButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Stack direction="row" alignItems="center">
            <IconButton color="primary" onClick={() => setOpen(true)}>
                <VaporIcon color="primary" iconName="edit" />
            </IconButton>
            <FiscalPaymentsDetailDrawer
                draftId={draftId}
                type={type}
                open={open}
                onClose={() => setOpen(false)}
            />
        </Stack>
    );
};

type DeleteManualPaymentButtonProps = {
    draftId: string;
    payment: Payment;
};
const DeleteManualPaymentButton = ({
    draftId,
    payment
}: DeleteManualPaymentButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} size="small">
                <VaporIcon color="primary" iconName="trash-alt" />
            </IconButton>
            <DeletePaymentDialog
                draftId={draftId}
                isOpen={open}
                payment={payment}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </>
    );
};

type ManualPaymentsTableProps = {
    draftId: string;
    readOnly?: boolean;
};
const ManualPaymentsTable = ({
    draftId,
    readOnly = false
}: ManualPaymentsTableProps) => {
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    const {
        data: manualPayments,
        isLoading,
        isSuccess
    } = usePayments({
        draftId: draftId,
        paymentType: "MANUAL"
    });

    if (!isSuccess || !isSuccessDraft) {
        return null;
    }

    if (isLoading || isLoadingDraft) {
        return <CircularProgress />;
    }

    if (manualPayments.length === 0) {
        return null;
    }

    const { refCurrency } = draft;

    return (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell>
                        <TableHeadText>{fs("account")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("give")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("take")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("date")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {manualPayments.map((manualPayment) => {
                    const isPaymentReadOnly =
                        manualPayment.isResidual ||
                        manualPayment.lineDisabled ||
                        readOnly;

                    return (
                        <TableRow key={manualPayment.uuid}>
                            <TableCell size="small">
                                <Stack>
                                    <Stack direction="row" alignItems="center">
                                        <Typography
                                            variant="body"
                                            marginRight="4px"
                                        >
                                            {capitalize(
                                                manualPayment.accountDesc
                                            )}
                                        </Typography>
                                        {!isPaymentReadOnly && (
                                            <ManualPaymentsDetailButton
                                                draftId={draftId}
                                            />
                                        )}
                                    </Stack>
                                    {!isNil(manualPayment.previousBalance) && (
                                        <Stack direction="row">
                                            <Typography
                                                variant="bodySmall"
                                                marginRight="4px"
                                            >
                                                {fs("previousBalance")}
                                            </Typography>
                                            <FormattedAmount
                                                variant="bodySmall"
                                                amount={
                                                    manualPayment.previousBalance
                                                }
                                                currency={refCurrency}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                            </TableCell>
                            <TableCell align="right" size="small">
                                {!isNil(manualPayment.debit) && (
                                    <FormattedAmount
                                        amount={manualPayment.debit}
                                        currency={refCurrency}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right" size="small">
                                {!isNil(manualPayment.credit) && (
                                    <FormattedAmount
                                        amount={manualPayment.credit}
                                        currency={refCurrency}
                                    />
                                )}
                            </TableCell>
                            <TableCell sx={{ width: "300px" }} size="small">
                                <FormattedDate date={manualPayment.date} />
                            </TableCell>
                            <TableCell sx={{ width: "56px" }} size="small">
                                <Stack direction="row" alignItems="center">
                                    <AccountDetailButton
                                        draftId={draftId}
                                        accountCode={manualPayment.accountCode}
                                        readOnly={readOnly}
                                    />
                                    {!isPaymentReadOnly && (
                                        <DeleteManualPaymentButton
                                            draftId={draftId}
                                            payment={manualPayment}
                                        />
                                    )}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

type FiscalPaymentsTableProps = {
    draftId: string;
    type: "CREDIT_NOTE" | "ADVANCE";
    readOnly?: boolean;
};
const FiscalPaymentsTable = ({
    draftId,
    type,
    readOnly = false
}: FiscalPaymentsTableProps) => {
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    const {
        data: fiscalPayments,
        isLoading,
        isSuccess
    } = usePayments({
        draftId: draftId,
        paymentType: type
    });
    const paymentType =
        type === "CREDIT_NOTE" ? PaymentType.CREDIT_NOTE : PaymentType.PAYMENT;

    if (!isSuccess || !isSuccessDraft) {
        return null;
    }

    if (isLoading || isLoadingDraft) {
        return <CircularProgress />;
    }

    if (fiscalPayments.length === 0) {
        return null;
    }

    const { refCurrency } = draft;

    return (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell>
                        <TableHeadText>
                            {fs(`tableHead.${paymentType}`)}
                        </TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("amount")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>
                            {fs(`date`)} {fs(paymentType)}
                        </TableHeadText>
                    </TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fiscalPayments.map((fiscalPayment) => (
                    <TableRow key={fiscalPayment.uuid}>
                        <TableCell size="small">
                            <Stack direction="row" alignItems="center">
                                <Typography>
                                    {`${capitalize(fs(fiscalPayment.type))} ${
                                        fiscalPayment.docNumber
                                    }`}
                                </Typography>
                                {!readOnly && (
                                    <FiscalPaymentsDetailButton
                                        draftId={draftId}
                                        type={type}
                                    />
                                )}
                            </Stack>
                        </TableCell>
                        <TableCell align="right" size="small">
                            <FormattedAmount
                                amount={fiscalPayment.amount}
                                currency={refCurrency}
                            />
                        </TableCell>
                        <TableCell sx={{ width: "412px" }} size="small">
                            <FormattedDate date={fiscalPayment.date} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

interface PaymentsProps {
    draftId: string;
    readOnly?: boolean;
}

export default function Payments({ draftId, readOnly }: PaymentsProps) {
    const {
        data: payments,
        isLoading,
        isSuccess
    } = usePayments({
        draftId
    });

    if (!isSuccess) {
        return null;
    }

    // don't bother rendering anything further if there are no payments
    if (payments.length === 0) {
        return null;
    }

    return (
        <Spinner loading={isLoading}>
            <Box marginTop="32px">
                {payments && !isEmpty(payments) && (
                    <Stack direction="column" spacing="24px">
                        <Typography variant="titleMedium">
                            {fs("payments")}
                        </Typography>
                        <ManualPaymentsTable
                            draftId={draftId}
                            readOnly={readOnly}
                        />
                        <FiscalPaymentsTable
                            draftId={draftId}
                            type={"CREDIT_NOTE"}
                            readOnly={readOnly}
                        />
                        <FiscalPaymentsTable
                            draftId={draftId}
                            type={"ADVANCE"}
                            readOnly={readOnly}
                        />
                    </Stack>
                )}
            </Box>
        </Spinner>
    );
}
