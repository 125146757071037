import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { Typography } from "@vapor/react-extended";
import { AngleDown, AngleRight, AngleUp } from "@vapor/react-icons";
import {
    Box,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { PropsWithChildren, useMemo } from "react";
import { BusinessSettlement } from "../../../../../core/domain/Vat/dto";
import FormattedAmount from "../../../../components/FormattedAmount";

interface SettlementTableProps {
    settlement: BusinessSettlement;
}

const HeaderTableCell = (props: PropsWithChildren) => {
    return (
        <TableCell>
            <Box px={1}>
                <Typography color="Highlight" fontSize={14}>
                    {props.children}
                </Typography>
            </Box>
        </TableCell>
    );
};

interface VatDetailRow {
    detailName?: string;
    vatRate?: string;
    vatRateDescription?: string;
    taxableAmount: number;
    vatAmount: number;
    totalAmount: number;
    rates?: Array<VatDetailRow>;
}

const columnHelper = createColumnHelper<VatDetailRow>();

const columns = [
    columnHelper.accessor("detailName", {
        header: () => "Operazioni",
        cell: (props) =>
            props.row.getCanExpand() && (
                <Stack direction="row" gap={2} alignItems="center">
                    <IconButton
                        size="small"
                        onClick={props.row.getToggleExpandedHandler()}
                    >
                        {props.row.getIsExpanded() ? (
                            <AngleUp />
                        ) : (
                            <AngleDown />
                        )}
                    </IconButton>
                    <span>{props.getValue()}</span>
                </Stack>
            )
    }),
    columnHelper.accessor("vatRate", {
        header: () => "Aliquota/Esenzioni",
        cell: (props) => props.getValue()
    }),
    columnHelper.accessor("vatRateDescription", {
        header: () => "Descrizione Aliquota",
        cell: (props) => props.getValue()
    }),
    columnHelper.accessor("taxableAmount", {
        header: () => "Imponibile",
        cell: (props) => (
            <FormattedAmount currency="EUR" amount={props.getValue()} />
        )
    }),
    columnHelper.accessor("vatAmount", {
        header: () => "IVA",
        cell: (props) => (
            <FormattedAmount currency="EUR" amount={props.getValue()} />
        )
    }),
    columnHelper.accessor("totalAmount", {
        header: () => "Totale",
        cell: (props) => (
            <FormattedAmount currency="EUR" amount={props.getValue()} />
        )
    }),
    columnHelper.display({
        id: "link",
        cell: () => (
            <IconButton size="small">
                <AngleRight />
            </IconButton>
        )
    })
];

const SettlementTable = (props: SettlementTableProps) => {
    const data = useMemo(() => {
        return Object.entries(props.settlement.details).map(
            ([detailGroup, vatDetail]): VatDetailRow => {
                return {
                    ...vatDetail,
                    detailName: detailGroup,
                    rates: vatDetail.rates.map((rate) => ({
                        ...rate,
                        vatRateDescription: rate.vatRate //TODO: localization
                    }))
                };
            }
        );
    }, [props.settlement.details]);

    const table = useReactTable({
        columns,
        data,
        getSubRows: (row) => row.rates,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    });

    if (!props.settlement?.details) {
        return <Box px={2}>{"Non sono presenti dettagli"}</Box>;
    }

    return (
        <>
            <Table>
                <TableHead shadow>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <HeaderTableCell key={header.id}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </HeaderTableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <TableCell key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default SettlementTable;
