import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, CircularProgress, Divider, Stack } from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import { AllocateGainAccountingLinesTable } from "../../components/Accounting/AllocateGainAccountingLinesTable/AllocateGainAccountingLinesTable";
import { useDraft, useMutateDraft } from "../../../core/domain/Draft/queries";
import { fromUnixTime, isAfter, isBefore, isSameDay } from "date-fns";
import { useLastResultDestination } from "../../../core/domain/AccountingPeriods/last-result-destination.queries";
import AccountToolbar from "../../components/AccountToolbar";
import { isReadOnly } from "../../../utils/draftUtils";
import { useAccountingPeriod } from "../../../core/domain/AccountingPeriods/queries";

interface AllocateGainDetailProps {
    onClose: () => void;
    draftId: string;
    accountingPeriodId?: string;
}

const fs = getFormattedStringWithScope("views.AccountingPeriods.AllocateGain");

export function AllocateGainDetail({
    onClose,
    draftId,
    accountingPeriodId
}: AllocateGainDetailProps) {
    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);

    const { mutate } = useMutateDraft(draftId);

    const { data: accountingPeriod } = useAccountingPeriod(accountingPeriodId);

    const handleChangeDate = (registrationDate: Date) => {
        mutate({
            registrationDate
        });
    };
    const startDate = new Date(accountingPeriod?.startDate || 0);
    const endDate = new Date(accountingPeriod?.endDate || 0);
    const checkDate = (date: Date) => {
        return (
            (isBefore(date as Date, startDate) &&
                !isSameDay(date as Date, startDate)) ||
            (isAfter(date as Date, endDate) &&
                !isSameDay(date as Date, endDate))
        );
    };
    const readOnly = isReadOnly(draft?.status) || !accountingPeriodId;

    if (!isDraftSuccess) {
        return null;
    }
    return (
        <>
            <Box padding={2}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack spacing={1}>
                        <Typography variant="body500">
                            {fs("reason")}
                        </Typography>
                        <Typography variant="bodyLarge500">
                            {fs("transfer")}
                        </Typography>
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <Divider orientation="vertical" flexItem />
                        <DatePicker
                            readOnly={readOnly}
                            label={fs("registrationDate")}
                            value={fromUnixTime(draft.registrationDate)}
                            onChange={(date: any) => handleChangeDate(date)}
                            shouldDisableDate={(date) =>
                                !!accountingPeriod
                                    ? checkDate(date as Date)
                                    : false
                            }
                        />
                    </Stack>
                </Stack>
                <AllocateGainAccountingLinesTable
                    readOnly={readOnly}
                    draftId={draftId}
                />
            </Box>
            <AccountToolbar
                draftId={draftId}
                onDelete={onClose}
                onAccount={onClose}
                isEnabledDelete={false}
                isEnabledModify={false}
            />
        </>
    );
}

type AllocateGainProps = {
    onClose: () => void;
    accountingPeriodId: string;
};
export const AllocateGain = (props: AllocateGainProps) => {
    const {
        data: draftId,
        isLoading,
        isSuccess
    } = useLastResultDestination(props.accountingPeriodId);

    if (isLoading) {
        return (
            <Box justifyContent="center" display="flex" alignContent="center">
                <CircularProgress />
            </Box>
        );
    }

    if (!isSuccess) {
        return null;
    }

    return (
        <AllocateGainDetail
            accountingPeriodId={props.accountingPeriodId}
            onClose={props.onClose}
            draftId={draftId}
        />
    );
};
