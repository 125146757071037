import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet } from "@onefront/react-sdk";
import { useQuery } from "@tanstack/react-query";

import { ASYNC_API_URL } from "../../../../config";
import { Reason } from "../../../../utils/appEnums";
import useAxiosConfigRequestParams from "../../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../../commons/hooks/useCustomerData";
import { BusinessAndSectionals } from "../../../usecases/dtos/BusinessAndSectionalsDto";

type UseBusinessAndSectionalsParams = {
    reverseCharge?: boolean;
    businessId?: string;
    reason?: Reason;
};

export const businessAndSectionalsQueryKeys = createQueryKeys(
    "business-and-sectionals",
    {
        all: null,
        get: (params?: UseBusinessAndSectionalsParams) => [{ ...params }],
        reverseChargeSectionals: (businessId: string) => [{ businessId }]
    }
);

export const useBusinessAndSectionals = (
    params?: UseBusinessAndSectionalsParams
) => {
    const { fetch } = useGet(
        `${ASYNC_API_URL}/workspace/businesses-and-sectionals`,
        {
            ...useAxiosConfigRequestParams("FormBusinessAndSectional"),
            params: {
                ...useCustomerData(),
                ...params
            },
            lazy: true
        }
    );
    const customerData = useCustomerData();

    return useQuery({
        ...businessAndSectionalsQueryKeys.get(params),
        queryFn: () =>
            fetch({
                params: {
                    ...customerData,
                    ...params
                }
            }),
        select: ({ data }) => (data as BusinessAndSectionals[]) ?? []
    });
};

export const useReverseChargeSectionals = (businessId?: string) => {
    const { fetch } = useGet<BusinessAndSectionals[]>(
        `${ASYNC_API_URL}/workspace/businesses-and-sectionals`,
        {
            ...useAxiosConfigRequestParams("FormReverseCharge"),
            params: {
                ...useCustomerData(),
                reverseCharge: true,
                businessId
            },
            lazy: true
        }
    );

    return useQuery({
        enabled: !!businessId && businessId !== "",
        ...businessAndSectionalsQueryKeys.reverseChargeSectionals(businessId!),
        queryFn: () => fetch(),
        select: ({ data }) => data[0]?.sectionals
    });
};
