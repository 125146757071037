import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet, useRequest } from "@onefront/react-sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { API_URL } from "../../../../config";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import { useSnackbar } from "../../../../services/SnackbarService";
import { PaymentType } from "../../../../utils/appEnums";
import omitNilProperties from "../../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../../commons/hooks/useCustomerData";
import {
    CreateContextualPaymentsData,
    CreatePaymentsData
} from "../../../usecases/dtos/CreatePaymentsDto";

// API

const useGetTotalToPay = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<{
        amountToPay: number;
    }>(`ignored`, {
        ...useAxiosConfigRequestParams("TotalToPay"),
        params: omitNilProperties({
            ...customerData
        }),
        lazy: true
    });

    const get = (draftId: string) =>
        fetch({
            url: `${API_URL}/v2/precog/drafts/${draftId}/total-to-pay`
        }).then(({ data }) => data);

    return { get };
};

const useGetPayments = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<Payment[]>(`ignored`, {
        ...useAxiosConfigRequestParams("Payments"),
        lazy: true
    });

    const get = (draftId: string) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments`,
            params: {
                ...customerData
            }
        }).then(({ data }) => data);

    return {
        fetch: get
    };
};

const useCreatePayments = () => {
    const customerData = useCustomerData();
    const { fetch } = useRequest({
        method: "POST",
        ...useAxiosConfigRequestParams("Payments"),
        lazy: true
    });

    const createContextual = (
        draftId: string,
        data: CreateContextualPaymentsData[],
        writeOffResidual: boolean = false
    ) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments/contextual`,
            params: {
                ...customerData
            },
            data: {
                payments: data,
                hasResidual: writeOffResidual
            }
        });

    const create = (
        draftId: string,
        data: CreatePaymentsData[],
        overwrite?: boolean
    ) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments`,
            params: {
                ...customerData
            },
            data: omitNilProperties({
                cleanPreviousAssociations: overwrite,
                payments: [...data]
            })
        });

    return {
        create,
        createContextual
    };
};

// HOOKS

export const paymentsQueryKeys = createQueryKeys("payments", {
    all: null,
    get: (draftId: string) => [{ draftId }],
    totalToPay: (draftId: string) => [{ draftId }]
});

export const usePayments = (params: {
    draftId: string;
    paymentType?: "MANUAL" | "CREDIT_NOTE" | "ADVANCE";
}) => {
    const { fetch } = useGetPayments();

    return useQuery({
        enabled: !!params.draftId,
        ...paymentsQueryKeys.get(params.draftId),
        queryFn: () => fetch(params.draftId),
        select: (data) => {
            switch (params.paymentType) {
                case "MANUAL":
                    return data.filter(
                        (payment) =>
                            !payment.registeredPaymentId &&
                            payment.type === PaymentType.PAYMENT
                    );
                case "CREDIT_NOTE":
                    return data.filter(
                        (payment) =>
                            payment.registeredPaymentId &&
                            payment.type === PaymentType.CREDIT_NOTE
                    );
                case "ADVANCE":
                    return data.filter(
                        (payment) =>
                            payment.registeredPaymentId &&
                            payment.type === PaymentType.PAYMENT
                    );
                default:
                    return data;
            }
        }
    });
};

export const useCreatePayment = (draftId: string) => {
    const queryClient = useQueryClient();
    const { create } = useCreatePayments();

    return useMutation({
        mutationFn: (params: {
            data: CreatePaymentsData[];
            overwrite?: boolean;
        }) => create(draftId, params.data, !!params.overwrite),
        onSettled: () => {
            queryClient.invalidateQueries(paymentsQueryKeys.get(draftId));
            queryClient.invalidateQueries(
                paymentsQueryKeys.totalToPay(draftId)
            );
        }
    });
};

export const useCreateContextualPayment = (params: {
    draftId: string;
    showNotificationOnSuccess?: boolean;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.CreatePayments");
    const queryClient = useQueryClient();
    const { createContextual } = useCreatePayments();

    return useMutation({
        mutationFn: (mutationParams: {
            data: CreateContextualPaymentsData[];
            writeOffResidual?: boolean;
            residual?: string;
        }) =>
            createContextual(
                params.draftId,
                mutationParams.data,
                mutationParams.writeOffResidual
            ),
        onSuccess: (_, { writeOffResidual, residual }) => {
            if (!params.showNotificationOnSuccess) {
                return;
            }

            if (writeOffResidual && residual) {
                enqueueSnackbar(
                    {
                        title: fs("success.title"),
                        content: `${fs("success.content")} ${residual}`
                    },
                    { severity: "info" }
                );
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(
                paymentsQueryKeys.get(params.draftId)
            );

            queryClient.invalidateQueries(
                paymentsQueryKeys.totalToPay(params.draftId)
            );
        }
    });
};

export const useDeletePayment = (params: {
    draftId: string;
    paymentIds: string | string[];
}) => {
    const queryClient = useQueryClient();
    const customerData = useCustomerData();
    const { fetch } = useRequest({
        url: `${API_URL}/v1/precog/drafts/${params.draftId}/fiscalPayments`,
        params: {
            ...customerData
        },
        method: "DELETE",
        ...useAxiosConfigRequestParams("Payments"),
        lazy: true
    });

    return useMutation({
        mutationFn: () =>
            fetch({
                data: Array.isArray(params.paymentIds)
                    ? params.paymentIds
                    : [params.paymentIds]
            }),
        onSettled: () => {
            queryClient.invalidateQueries(
                paymentsQueryKeys.get(params.draftId)
            );

            queryClient.invalidateQueries(
                paymentsQueryKeys.totalToPay(params.draftId)
            );
        }
    });
};

export const useDeleteContextualPayment = (params: {
    draftId: string;
    paymentId: string;
}) => {
    const queryClient = useQueryClient();
    const customerData = useCustomerData();
    const { fetch } = useRequest({
        method: "DELETE",
        ...useAxiosConfigRequestParams("Payments"),
        params: {
            ...customerData
        },
        lazy: true
    });

    return useMutation({
        mutationFn: () =>
            fetch({
                url: `${API_URL}/v1/precog/drafts/${params.draftId}/fiscalPayments/${params.paymentId}/contextual`
            }),
        onSettled: () => {
            queryClient.invalidateQueries(
                paymentsQueryKeys.get(params.draftId)
            );

            queryClient.invalidateQueries(
                paymentsQueryKeys.totalToPay(params.draftId)
            );
        }
    });
};

export const useTotalToPay = (draftId: string) => {
    const { get } = useGetTotalToPay();

    return useQuery({
        enabled: !!draftId,
        ...paymentsQueryKeys.totalToPay(draftId),
        queryFn: () => get(draftId),
        select: (data) => data.amountToPay
    });
};
