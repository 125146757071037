import { faFileLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");

export default function Disabled() {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
        >
            <Typography variant="body">-</Typography>

            <CustomButton
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faFileLines} />}
                disabled={true}
            >
                {fs("generate")}
            </CustomButton>
        </Stack>
    );
}
