import {
    Box,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell as VaporTableCell,
    TableCellProps,
    TableHead,
    TableRow
} from "@vapor/react-material";

import { useAccountingLines } from "../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { isReadOnly } from "../../../../utils/draftUtils";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import DeleteButton from "../AccountingLinesTable/DeleteButton";
import {
    AddAccountButton,
    AmountCell,
    TotalCell
} from "../AccountingLinesTable/table";
import { AccountingLine } from "../../../../core/usecases/dtos/AccountingLinesDto";
import { AccountingCodeType } from "../../../../utils/appEnums";
import groupAccountingLines from "../AccountingLinesTable/groupAccountingLines";
import { fromPairs, toPairs } from "lodash";
import { AccountingGroup } from "../../../../core/usecases/interfaces/AccountingGroup";
import AccountCell from "../AccountingLinesTable/cells/AccountCell";

const TableCell = (props: TableCellProps) => (
    <VaporTableCell {...props} size="small" />
);

const ValueHeadCell = (props: { text: string }) => (
    <TableHeadCell align="right">
        <TableHeadText>{props.text}</TableHeadText>
    </TableHeadCell>
);

type AllocateGainCreditAndDebitCellsProps = {
    draftId: string;
    accountingLine: AccountingLine;
    type: "debit" | "credit";
    readOnly: boolean;
};
const AllocateGainCreditAndDebitCells = ({
    draftId,
    accountingLine,
    type,
    readOnly
}: AllocateGainCreditAndDebitCellsProps) => {
    if (type === "credit") {
        return (
            <>
                <TableCell></TableCell>
                <AmountCell
                    accountingLinesIds={accountingLine.uuid!}
                    draftId={draftId}
                    type="credit"
                    readOnly={readOnly}
                />
            </>
        );
    } else {
        return (
            <>
                <AmountCell
                    accountingLinesIds={accountingLine.uuid!}
                    draftId={draftId}
                    type="debit"
                    readOnly={readOnly}
                />
                <TableCell></TableCell>
            </>
        );
    }
};

type DR1RowProps = {
    accountingGroup: AccountingGroup;
    draftId: string;
    type: "debit" | "credit";
    readOnly?: boolean;
};
const DR1Row = ({
    accountingGroup,
    draftId,
    type,
    readOnly = false
}: DR1RowProps) => {
    return (
        <>
            <TableRow>
                <AccountCell
                    accountingGroup={accountingGroup}
                    draftId={draftId}
                    readOnly={readOnly}
                    isOpen={false}
                    onToggleRow={() => {}}
                    showAccountType={false}
                    includeBalanceSheetAccounts={true}
                />
                <AllocateGainCreditAndDebitCells
                    accountingLine={accountingGroup.children[0]}
                    draftId={draftId}
                    type={type}
                    readOnly={readOnly}
                />
                <TableCell>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {!readOnly && (
                            <DeleteButton
                                draftId={draftId}
                                accountingLineId={
                                    accountingGroup.children[0].uuid!
                                }
                            />
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
        </>
    );
};

type AllocateGainAccountingLinesTableProps = {
    draftId: string;
    readOnly?: boolean;
};
export const AllocateGainAccountingLinesTable = ({
    draftId,
    readOnly: readOnlyProp = false
}: AllocateGainAccountingLinesTableProps) => {
    const {
        data: draft,
        isSuccess: isDraftSuccess,
        isLoading: isLoadingDraft
    } = useDraft(draftId);
    const {
        data: accountingLines,
        isSuccess: isAccountingLinesSuccess,
        isLoading: isLoadingAccountingLines
    } = useAccountingLines({ draftId });

    const fs = useFormattedStringWithScope(
        "components.Accounting.AllocateGainAccountingLinesTable"
    );

    if (isLoadingDraft || isLoadingAccountingLines) {
        return (
            <Box justifyContent="center" display="flex" alignContent="center">
                <CircularProgress />
            </Box>
        );
    }

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const accountingGroups = groupAccountingLines(accountingLines, {
            sort: false,
            includePreviousBalance: false
        });

        const lastResultDestinationAccountingGroup = toPairs(
            accountingGroups
        ).find(([_key, accountingGroup]) =>
            accountingGroup.accountCode.includes(
                AccountingCodeType.LAST_RESULT_DESTINATION
            )
        );

        const othersAccountingGroups = fromPairs(
            toPairs(accountingGroups).filter(
                ([_key, accountingGroup]) =>
                    !accountingGroup.accountCode.includes(
                        AccountingCodeType.LAST_RESULT_DESTINATION
                    )
            )
        );

        const isLastResultDestinationDebit =
            !!lastResultDestinationAccountingGroup &&
            lastResultDestinationAccountingGroup[1].debit > 0;

        const readOnly = isReadOnly(draft.status) || readOnlyProp;
        return (
            <Box marginTop={5}>
                <Table>
                    <TableHead shadow>
                        <TableHeadCell>
                            <TableHeadText>{fs("account")}</TableHeadText>
                        </TableHeadCell>
                        <ValueHeadCell text={fs("give")} />
                        <ValueHeadCell text={fs("take")} />
                        <TableHeadCell></TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {lastResultDestinationAccountingGroup && (
                            <DR1Row
                                accountingGroup={
                                    lastResultDestinationAccountingGroup[1]
                                }
                                type={
                                    isLastResultDestinationDebit
                                        ? "debit"
                                        : "credit"
                                }
                                draftId={draftId}
                                readOnly={true}
                            />
                        )}
                        {toPairs(othersAccountingGroups).map(
                            ([_key, accountingGroup], index) => (
                                <DR1Row
                                    key={index}
                                    type={
                                        isLastResultDestinationDebit
                                            ? "credit"
                                            : "debit"
                                    }
                                    accountingGroup={accountingGroup}
                                    draftId={draftId}
                                    readOnly={readOnly}
                                />
                            )
                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TotalCell type="debit" draftId={draftId} />
                            <TotalCell type="credit" draftId={draftId} />
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {!readOnly && (
                    <AddAccountButton
                        includeBalanceSheetAccounts={true}
                        draftId={draftId}
                    />
                )}
            </Box>
        );
    }

    return null;
};
