import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Select, Tooltip } from "@vapor/react-material";
import { Box } from "@vapor/react-material/Box";
import { Divider } from "@vapor/react-material/Divider";
import { FormControl } from "@vapor/react-material/FormControl";
import { FormControlLabel } from "@vapor/react-material/FormControlLabel";
import { FormGroup } from "@vapor/react-material/FormGroup";
import { Grid } from "@vapor/react-material/Grid";
import InputLabel from "@vapor/react-material/InputLabel";
import { MenuItem } from "@vapor/react-material/MenuItem";
import { Radio } from "@vapor/react-material/Radio";
import { RadioGroup } from "@vapor/react-material/RadioGroup";
import { Stack } from "@vapor/react-material/Stack";
import { Switch } from "@vapor/react-material/Switch";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { format } from "date-fns";
import { isNil } from "lodash";
import { useEffect, useState } from "react";

import {
    API_DATE_FORMAT,
    FT_SHOW_DEPRECIATION_FIELDS
} from "../../../../config";
import { readOnlySelect } from "../../../../core/commons/read-only-select";
import { AssetDetailDto } from "../../../../core/usecases/dtos/AssetDetailDto";
import { AssetInfoResponse } from "../../../../core/usecases/dtos/AssetInfoDto";
import { AssetSubkindsDepriciationDto } from "../../../../core/usecases/dtos/AssetSubkindsDepricitionDto";
import { GroupKindSubkind } from "../../../../core/usecases/dtos/GroupKindSubkind";
import { useGetAssetTaxReliefs } from "../../../../core/usecases/useGetAssetTaxReliefs";
import { DraftAssetPatch } from "../../../../core/usecases/usePatchDraftAsset";
import {
    isInRangeTaxReliefDate,
    rangeDateTaxRelief,
    showTaxReliefAmount,
    TaxRelief
} from "../../../../utils/assetUtils";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { statutoryDepreciationTypeEnum } from "../../../views/Assets/Depreciation";
import CurrencyField from "../../CurrencyField";
import FormattedDate from "../../FormattedDate";
import PercentageField from "../../PercentageField";

const fs = getFormattedStringWithScope("views.Detail.assets.drawer");

interface AssetDepreciationProps {
    refCurrency: string | null;
    assetInfo: AssetInfoResponse | null;
    assetDetail: AssetDetailDto | null;
    onTaxDepreciationRateTypeChange: (taxDepreciationRateType: string) => void;
    setDataToPatch: (dataToPatch: DraftAssetPatch | null) => void;
    taxDepreciationRateType?: string;
    taxDepreciationRate: number | null;
    groupKindSubkind: GroupKindSubkind | null;
    taxDepreciationRateRerender: string;
    handleChangeTaxDepreciationRate: (newRate: number) => void;
    statutoryDepreciationType?: string;
    onChangeStatutoryDepreciationType: (
        statutoryDepreciationType: string
    ) => void;
    statutoryDepreciationValue?: number;
    statutoryDepreciationRerender: string;
    handleChangeStatutoryDepreciationValue: (event: any) => void;
    currentSellingDepreciation?: boolean;
    setCurrentSellingDepreciation: (
        currentSellingDepreciation?: boolean
    ) => void;
    selectedTaxBreak?: TaxRelief;
    setSelectedTaxBreak: (selectedTaxBreak?: TaxRelief) => void;
    readOnly: boolean;
    depreciation: AssetSubkindsDepriciationDto | null;
    draftReadOnly: boolean;
    showReportInDays: boolean;
}

export default function AssetDepreciation({
    refCurrency,
    assetInfo,
    assetDetail,
    onTaxDepreciationRateTypeChange,
    setDataToPatch,
    taxDepreciationRateType,
    taxDepreciationRate,
    groupKindSubkind,
    handleChangeTaxDepreciationRate,
    statutoryDepreciationType,
    onChangeStatutoryDepreciationType,
    statutoryDepreciationValue,
    handleChangeStatutoryDepreciationValue,
    currentSellingDepreciation,
    setCurrentSellingDepreciation,
    selectedTaxBreak,
    setSelectedTaxBreak,
    readOnly,
    depreciation,
    draftReadOnly,
    showReportInDays
}: Partial<AssetDepreciationProps>) {
    const [hasInitialized, setHasInitalized] = useState<boolean>(false);
    const [isWithinIntervalTaxReliefDate, setIsWithinIntervalTaxReliefDate] =
        useState<boolean>(true);
    const [selectedTaxReliefType, setSelectedTaxReliefType] = useState<
        string | undefined
    >(assetInfo?.taxReliefType);

    const { data: taxReliefs, fetch: getTaxReliefs } = useGetAssetTaxReliefs({
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            getTaxReliefs();
            setHasInitalized(true);
        }
    }, [getTaxReliefs, hasInitialized]);

    useEffect(() => {
        setSelectedTaxBreak?.(assetInfo?.taxRelief);
        setSelectedTaxReliefType(assetInfo?.taxReliefType);
    }, [assetInfo?.taxRelief, assetInfo?.taxReliefType, setSelectedTaxBreak]);

    const handleChangeTaxReliefDate = (date: Date) => {
        if (selectedTaxBreak) {
            if (isInRangeTaxReliefDate(date, selectedTaxBreak)) {
                setDataToPatch?.({
                    taxReliefDate: format(date, API_DATE_FORMAT)
                });
                setIsWithinIntervalTaxReliefDate(true);
            } else {
                setIsWithinIntervalTaxReliefDate(false);
            }
        }
    };

    return (
        <Box sx={{ margin: "16px 40px" }}>
            <Box>
                <Typography variant="titleXSmall" color="richElectricBlue">
                    {fs("depreciation.paragraphPercentage")}
                </Typography>
                {readOnly ? (
                    <Grid container alignItems={"flex-end"}>
                        <Grid item>
                            <RadioGroup
                                value={assetDetail?.taxDepreciationRateType}
                            >
                                <FormControlLabel
                                    control={<Radio />}
                                    label={`${fs("depreciation.ORDINARY")} ${
                                        depreciation?.ordinary
                                    }%`}
                                    value="ORDINARY"
                                />
                                <Stack direction="row">
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={fs("depreciation.MANUAL")}
                                        value="MANUAL"
                                    />
                                    <PercentageField
                                        value={
                                            assetDetail?.taxDepreciationRate ??
                                            0
                                        }
                                        readOnly
                                        onChange={() => {}}
                                    />
                                </Stack>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={fs("depreciation.DYNAMIC")}
                                    value="DYNAMIC"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid container gap="24px">
                            <Grid item>
                                <Typography variant="bodySmall" color="black">
                                    {fs("depreciation.minimum")}{" "}
                                    {depreciation?.reduced
                                        ? depreciation?.reduced + "%"
                                        : ""}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="bodySmall" color="black">
                                    {fs("depreciation.accelerated")}{" "}
                                    {depreciation?.accelerated
                                        ? depreciation?.accelerated + "%"
                                        : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item marginY={2}>
                            <Typography
                                variant="titleXSmall"
                                color="richElectricBlue"
                            >
                                {fs("depreciation.paragraphIRAP")}
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: "15px",
                                    marginBottom: "15px"
                                }}
                            >
                                <InputLabel>
                                    {fs("depreciation.percentIRAP")}
                                </InputLabel>
                                <PercentageField
                                    value={assetDetail?.irapRate ?? 0}
                                    readOnly
                                    onChange={() => {}}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems={"flex-end"}>
                        <Grid item>
                            <RadioGroup
                                defaultValue={
                                    assetInfo?.taxDepreciationRateType
                                }
                                name="radio-buttons-group"
                                onChange={(event: any) => {
                                    onTaxDepreciationRateTypeChange?.(
                                        event.target.value
                                    );
                                }}
                            >
                                <FormControlLabel
                                    disabled={draftReadOnly}
                                    control={<Radio />}
                                    label={`${fs("depreciation.ORDINARY")} ${
                                        groupKindSubkind?.ordinary
                                            ? groupKindSubkind?.ordinary + "%"
                                            : ""
                                    }`}
                                    value="ORDINARY"
                                />
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <FormControlLabel
                                        disabled={draftReadOnly}
                                        control={<Radio />}
                                        label={fs("depreciation.MANUAL")}
                                        value="MANUAL"
                                    />
                                    {taxDepreciationRateType === "MANUAL" && (
                                        <PercentageField
                                            value={taxDepreciationRate ?? 0}
                                            onChange={(newRate) => {
                                                handleChangeTaxDepreciationRate?.(
                                                    newRate ?? 0
                                                );
                                            }}
                                            readOnly={draftReadOnly}
                                        />
                                    )}
                                </Box>

                                {FT_SHOW_DEPRECIATION_FIELDS ? (
                                    <Box>
                                        <FormControlLabel
                                            control={<Radio />}
                                            label={fs("depreciation.DYNAMIC")}
                                            value="DYNAMIC"
                                        />
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title="TODO"
                                            componentsProps={{
                                                popper: {
                                                    sx: {
                                                        zIndex: "7500"
                                                    }
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="v-AssetsListTable-icon"
                                                icon={faCircleInfo}
                                            />
                                        </Tooltip>
                                    </Box>
                                ) : null}
                            </RadioGroup>
                        </Grid>
                        <Grid container gap="24px">
                            <Grid item>
                                <Typography variant="bodySmall" color="black">
                                    {fs("depreciation.minimum")}{" "}
                                    {groupKindSubkind?.reduced
                                        ? groupKindSubkind?.reduced + "%"
                                        : ""}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="bodySmall" color="black">
                                    {fs("depreciation.accelerated")}{" "}
                                    {groupKindSubkind?.accelerated
                                        ? groupKindSubkind?.accelerated + "%"
                                        : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <Divider variant="middle" style={{ marginLeft: "-40px" }} />
            <Box sx={{ marginBottom: "16px", marginTop: "16px" }}>
                <Typography variant="titleXSmall" color="richElectricBlue">
                    {fs("depreciation.paragraphStatutoryDepreciation")}
                </Typography>

                {readOnly ? (
                    <Grid container alignItems={"flex-end"}>
                        <Grid item>
                            <FormControl sx={{ marginTop: "5px" }}>
                                <RadioGroup
                                    value={
                                        assetDetail?.statutoryDepreciationType
                                    }
                                    row
                                >
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={fs("depreciation.taxCriteria")}
                                        value={
                                            statutoryDepreciationTypeEnum.TAX_CRITERIA
                                        }
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={fs(
                                            "depreciation.fixedPercentage"
                                        )}
                                        value={
                                            statutoryDepreciationTypeEnum.FIXED_PERCENTAGE
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems={"flex-end"}>
                        <Grid item>
                            <FormControl>
                                <RadioGroup
                                    name="statuatoryDepreciation"
                                    value={statutoryDepreciationType}
                                    onChange={(_, value) => {
                                        onChangeStatutoryDepreciationType?.(
                                            value
                                        );
                                    }}
                                >
                                    <Box display="flex">
                                        <FormControlLabel
                                            disabled={draftReadOnly}
                                            control={<Radio />}
                                            label={fs(
                                                "depreciation.taxCriteria"
                                            )}
                                            value={
                                                statutoryDepreciationTypeEnum.TAX_CRITERIA
                                            }
                                        />
                                        <FormControlLabel
                                            disabled={draftReadOnly}
                                            sx={{ marginLeft: "39px" }}
                                            control={<Radio />}
                                            label={fs(
                                                "depreciation.fixedPercentage"
                                            )}
                                            value={
                                                statutoryDepreciationTypeEnum.FIXED_PERCENTAGE
                                            }
                                        />

                                        {FT_SHOW_DEPRECIATION_FIELDS ? (
                                            <FormControlLabel
                                                disabled={draftReadOnly}
                                                sx={{ marginLeft: "10px" }}
                                                control={<Radio />}
                                                label={fs(
                                                    "depreciation.descMethod"
                                                )}
                                                value={
                                                    statutoryDepreciationTypeEnum.DESC_METHOD
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                    {FT_SHOW_DEPRECIATION_FIELDS ? (
                                        <Box>
                                            <FormControlLabel
                                                disabled={draftReadOnly}
                                                control={<Radio />}
                                                label={fs(
                                                    "depreciation.period"
                                                )}
                                                value={
                                                    statutoryDepreciationTypeEnum.PERIOD
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={draftReadOnly}
                                                sx={{ marginLeft: "39px" }}
                                                control={<Radio />}
                                                label={fs(
                                                    "depreciation.fixedPercentage"
                                                )}
                                                value={
                                                    statutoryDepreciationTypeEnum.FIXED_PERCENTAGE
                                                }
                                            />
                                        </Box>
                                    ) : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {statutoryDepreciationType ===
                                "FIXED_PERCENTAGE" && (
                                <PercentageField
                                    value={statutoryDepreciationValue ?? 0}
                                    onChange={(value) => {
                                        handleChangeStatutoryDepreciationValue?.(
                                            value
                                        );
                                    }}
                                    readOnly={draftReadOnly}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
            {assetInfo?.iraPercentProperties.isVisible && (
                <Box maxWidth="200px">
                    <Typography variant="titleXSmall" color="richElectricBlue">
                        {fs("depreciation.paragraphIRAP")}
                    </Typography>
                    <Box sx={{ marginTop: "15px", marginBottom: "15px" }}>
                        <InputLabel>
                            {fs("depreciation.percentIRAP")}
                        </InputLabel>
                        <PercentageField
                            value={assetInfo?.irapPercent ?? 0}
                            onChange={(e) => {
                                setDataToPatch?.({
                                    irapPercent: e
                                });
                            }}
                            readOnly={
                                !assetInfo.iraPercentProperties.isEditable
                            }
                        />
                    </Box>
                </Box>
            )}
            {readOnly ? null : (
                <Box marginBottom="15px">
                    <Typography variant="titleXSmall" color="richElectricBlue">
                        {fs("depreciation.depreciationCriteria")}
                    </Typography>
                    <Grid container>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={
                                                assetInfo?.sellingDepreciation
                                            }
                                            onChange={() => {
                                                setDataToPatch?.({
                                                    sellingDepreciation:
                                                        !currentSellingDepreciation
                                                });
                                                setCurrentSellingDepreciation?.(
                                                    !currentSellingDepreciation
                                                );
                                            }}
                                            readOnly={draftReadOnly}
                                        />
                                    }
                                    label={fs(
                                        "depreciation.sellingDepreciation"
                                    )}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={
                                                assetInfo?.halfFirstYear
                                            }
                                            onChange={(_, value) => {
                                                setDataToPatch?.({
                                                    halfFirstYear: value
                                                });
                                            }}
                                            readOnly={draftReadOnly}
                                        />
                                    }
                                    label={fs("depreciation.halfFirstYear")}
                                />
                            </FormGroup>
                        </Grid>
                        {showReportInDays && (
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                defaultChecked={
                                                    assetInfo?.reportInDays
                                                }
                                                onChange={(e) =>
                                                    setDataToPatch?.({
                                                        reportInDays:
                                                            e.target.checked
                                                    })
                                                }
                                                readOnly={draftReadOnly}
                                            />
                                        }
                                        label={
                                            <Box display="flex">
                                                <Typography>
                                                    {fs(
                                                        "depreciation.reportInDays"
                                                    )}
                                                </Typography>
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={fs(
                                                        "depreciation.reportInDaysTooltip"
                                                    )}
                                                    componentsProps={{
                                                        popper: {
                                                            sx: {
                                                                zIndex: "7500"
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            marginTop: "4px",
                                                            marginLeft: "15px"
                                                        }}
                                                    >
                                                        <VaporIcon
                                                            color="primary"
                                                            icon={faCircleInfo}
                                                            iconWeight="regular"
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            {readOnly ? (
                <Box marginTop="25px">
                    <Typography variant="titleXSmall" color="richElectricBlue">
                        {fs("depreciation.paragraphOthers")}
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch />}
                            label={fs("depreciation.halfFirstYear")}
                            checked={assetDetail?.halfFirstYear}
                        />
                    </FormGroup>
                </Box>
            ) : (
                <Box>
                    <Typography variant="titleXSmall" color="richElectricBlue">
                        {fs("depreciation.paragraphTaxBreaks")}
                    </Typography>
                    <Grid marginTop="5px" container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel>
                                {fs("depreciation.taxBreaks")}
                            </InputLabel>
                            <Select
                                defaultValue={assetInfo?.taxRelief ?? -1}
                                MenuProps={{
                                    sx: {
                                        zIndex: "7500 !important"
                                    }
                                }}
                                sx={{ width: "223px" }}
                                onChange={(event: any) => {
                                    if (event.target.value) {
                                        setSelectedTaxBreak?.(
                                            event.target.value
                                        );
                                        setDataToPatch?.({
                                            taxRelief: event.target.value,
                                            fieldsToDelete: [
                                                "taxReliefAmount",
                                                "taxReliefType",
                                                "taxReliefDate"
                                            ]
                                        });
                                    }
                                    if (
                                        event.target.value &&
                                        event.target.value === -1
                                    ) {
                                        setDataToPatch?.({
                                            fieldsToDelete: [
                                                "taxRelief",
                                                "taxReliefAmount",
                                                "taxReliefType",
                                                "taxReliefDate"
                                            ]
                                        });
                                    }
                                }}
                                {...readOnlySelect(draftReadOnly)}
                            >
                                <MenuItem value={-1}>
                                    {fs("depreciation.notTaxBreaks")}
                                </MenuItem>
                                {taxReliefs?.map((taxRelief, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={taxRelief.code}
                                        >
                                            {taxRelief.description}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel>
                                {fs("depreciation.taxBreakType")}
                            </InputLabel>
                            <Select
                                defaultValue={selectedTaxReliefType}
                                MenuProps={{
                                    sx: {
                                        zIndex: "7500 !important"
                                    }
                                }}
                                sx={{ width: "223px" }}
                                onChange={(event: any) => {
                                    if (event.target.value) {
                                        setDataToPatch?.({
                                            taxReliefType: event.target.value
                                        });
                                    }
                                }}
                                {...readOnlySelect(draftReadOnly)}
                            >
                                {taxReliefs
                                    ?.find(
                                        (taxRelief) =>
                                            taxRelief.code === selectedTaxBreak
                                    )
                                    ?.type?.map((type, index) => {
                                        return (
                                            <MenuItem key={index} value={type}>
                                                {fs(
                                                    `depreciation.taxReliefs.${type}`
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel>
                                {fs("depreciation.taxBreakDate")}
                            </InputLabel>
                            <DatePicker
                                value={
                                    assetInfo && assetInfo.taxReliefDate
                                        ? new Date(assetInfo.taxReliefDate)
                                        : null
                                }
                                onChange={(date: any) =>
                                    handleChangeTaxReliefDate(date)
                                }
                                slotProps={{
                                    popper: {
                                        sx: {
                                            "&.MuiPickersPopper-root": {
                                                zIndex: "7500 !important"
                                            }
                                        }
                                    }
                                }}
                                readOnly={draftReadOnly || !selectedTaxBreak}
                            />
                        </Grid>
                        {!isNil(selectedTaxBreak) &&
                            showTaxReliefAmount(selectedTaxBreak) && (
                                <Grid item xs={4}>
                                    <InputLabel>
                                        {fs("depreciation.taxBreakValue")}
                                    </InputLabel>
                                    <CurrencyField
                                        currency={refCurrency ?? "EUR"}
                                        value={assetInfo?.taxReliefAmount ?? 0}
                                        onChange={(value: number) =>
                                            setDataToPatch?.({
                                                taxReliefAmount: value
                                            })
                                        }
                                        readOnly={draftReadOnly}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            {!isWithinIntervalTaxReliefDate &&
                                selectedTaxBreak && (
                                    <Typography variant="body" color="error">
                                        <Stack direction={"row"}>
                                            {`${fs(
                                                "depreciation.errorTaxReliefDateRange"
                                            )}:`}
                                            <FormattedDate
                                                variant="body"
                                                color="error"
                                                marginRight={1}
                                                date={rangeDateTaxRelief
                                                    .get(selectedTaxBreak)
                                                    ?.start.toISOString()}
                                            />
                                            {"-"}
                                            <FormattedDate
                                                marginLeft={1}
                                                variant="body"
                                                color="error"
                                                date={rangeDateTaxRelief
                                                    .get(selectedTaxBreak)
                                                    ?.end.toISOString()}
                                            />
                                        </Stack>
                                    </Typography>
                                )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
