import Grid from "@vapor/react-material/Grid";
import endOfYear from "date-fns/endOfYear";
import getYear from "date-fns/getYear";
import setYear from "date-fns/setYear";
import startOfYear from "date-fns/startOfYear";
import isNil from "lodash/isNil";
import { useState } from "react";

import { VaporToolbar } from "@vapor/react-custom";
import { Typography } from "@vapor/react-extended/Typography/Typography";
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Stack
} from "@vapor/react-material";
import format from "date-fns/format";
import { useCreateBalance } from "../../../../core/domain/Balance/queries";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import DateRangePicker, {
    DateRangePickerState
} from "../../../components/DateRangePicker";
import SelectYear from "../../../components/SelectYear";

interface CreateBalanceInterface {
    onSuccess?: () => void;
}

const fs = getFormattedStringWithScope("views.BalanceDrawer");

export default function CreateBalance({ onSuccess }: CreateBalanceInterface) {
    const {
        mutateAsync: createBalance,
        isPending: isPendingCreateBalance,
        isError: isErrorCreateBalance
    } = useCreateBalance();

    const currentYear = getYear(new Date());

    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const defaultDateRange: DateRangePickerState = {
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
        error: false
    };

    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRangePickerState>(defaultDateRange);

    const [isRequestingByCompetence, setIsRequestingByCompetence] =
        useState<boolean>(false);

    const [includeProvisional, setIncludeProvisional] =
        useState<boolean>(false);

    const handleSelectYearChange = (newSelectedYear: number) => {
        const newSelectedYearDate = setYear(new Date(), newSelectedYear);
        setSelectedDateRange({
            startDate: startOfYear(newSelectedYearDate),
            endDate: setYear(
                newSelectedYear === currentYear
                    ? new Date()
                    : endOfYear(newSelectedYearDate),
                newSelectedYear
            ),
            error: selectedDateRange.error
        });
        setSelectedYear(newSelectedYear);
    };

    const handleDateRangePickerChange = (
        newDateRange: DateRangePickerState
    ) => {
        setSelectedDateRange(newDateRange);
    };

    const handleSelectedEntryModeChange = () => {
        setIsRequestingByCompetence(!isRequestingByCompetence);
    };

    const handleIncludeProvisionalChange = () => {
        setIncludeProvisional(!includeProvisional);
    };

    const handleCreateBalance = async () => {
        await createBalance({
            fromDate: selectedDateRange.startDate
                ? format(selectedDateRange.startDate, "yyyy-MM-dd")
                : "",
            toDate: selectedDateRange.endDate
                ? format(selectedDateRange.endDate, "yyyy-MM-dd")
                : "",
            accrual: isRequestingByCompetence,
            includeVariance: includeProvisional
        });

        if (!isErrorCreateBalance) {
            onSuccess && onSuccess();
        }
    };

    return (
        <>
            <Box
                padding={3}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
            >
                <Stack gap={3}>
                    <Grid container columnSpacing={3}>
                        <Grid item lg={4} xs={8}>
                            <SelectYear
                                numberOfYears={5}
                                onChange={handleSelectYearChange}
                                startingYear={currentYear}
                                value={selectedYear}
                            />
                        </Grid>
                        <Grid item>
                            <DateRangePicker
                                startDate={selectedDateRange.startDate}
                                endDate={selectedDateRange.endDate}
                                onChange={handleDateRangePickerChange}
                                isRequired={true}
                                errorStartDate={isNil(
                                    selectedDateRange.startDate
                                )}
                                errorEndDate={isNil(selectedDateRange.endDate)}
                                validate={(date) => !isNil(date)}
                            />
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{ marginX: "0 !important" }}
                        variant="middle"
                        orientation="horizontal"
                    />
                    <Stack>
                        <FormControl>
                            <FormControlLabel
                                label={
                                    <Typography>
                                        {fs("viewByCompetence")}
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={isRequestingByCompetence}
                                        onChange={handleSelectedEntryModeChange}
                                    />
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                label={
                                    <Typography>
                                        {fs("includeProvisional")}
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={includeProvisional}
                                        onChange={
                                            handleIncludeProvisionalChange
                                        }
                                    />
                                }
                            />
                        </FormControl>
                    </Stack>
                </Stack>
            </Box>
            <VaporToolbar
                contentRight={
                    <CustomButton
                        loading={isPendingCreateBalance}
                        variant="contained"
                        onClick={handleCreateBalance}
                        disabled={selectedDateRange.error}
                    >
                        {fs("buttonLabelReport")}
                    </CustomButton>
                }
            />
        </>
    );
}
