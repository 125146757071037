import { Spinner } from "@comic/precog-components";
import { faObjectGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box } from "@vapor/react-material/Box";
import { Button } from "@vapor/react-material/Button";
import { Stack } from "@vapor/react-material/Stack";
import React, { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { useDraftAsset } from "../../../core/contexts/DraftAsset";
import { AssetLineDto } from "../../../core/usecases/dtos/AssetLinesDto";

import { isEmpty, xor } from "lodash";
import {
    IDataToPostGroup,
    usePostDraftAssetGroup
} from "../../../core/usecases/usePostDraftAssetGroup";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import InfoBanner from "../../components/InfoBanner";
import AssetDrawer from "./Drawer";
import AssetTable from "./Table";

interface AssetsProps {
    businessId: string | undefined;
    draftId: string | null;
    isActive: boolean;
    refCurrency: string | null;
    readOnly?: boolean;
}
const fs = getFormattedStringWithScope("views.Detail.assets");

const Assets = ({
    businessId,
    draftId,
    isActive,
    refCurrency,
    readOnly
}: AssetsProps) => {
    const [checkedArticlesForGrouping, setCheckedArticlesForGrouping] =
        useState<{ [key: string]: AssetLineDto[] } | undefined>(undefined);
    const [dataToPostGroup, setDataToPostGroup] = useState<
        IDataToPostGroup[] | null
    >(null);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [assetIdOpened, setAssetIdOpened] = useState<string | null>(null);
    const [assetInfoOpened, setAssetInfoOpened] = useState<
        AssetLineDto | undefined
    >(undefined);
    const [isGroupingAsset, setIsGroupingAsset] = useState<boolean>(false);
    const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
    const [selectedAssetAccountCode, setSelectedAssetAccountCode] = useState<{
        code: string;
        numOcc: number;
    }>({ code: "", numOcc: 0 });
    const [isIncrementalSpendingGrouped, setIsIncrementalSpendingGrouped] =
        useState<boolean | undefined>(false);

    const {
        assetLinesList,
        loading: loadingAssetLines,
        get: getDraftAsset,
        update
    } = useDraftAsset();

    const { fetch: fetchPostData, result: newParentGroupId } =
        usePostDraftAssetGroup({
            draftId: draftId,
            dataToPost: dataToPostGroup,
            lazy: true
        });

    useEffect(() => {
        setIsIncrementalSpendingGrouped(
            assetLinesList?.assetLines
                ?.filter((assetLine) =>
                    selectedAssetIds.includes(assetLine.uuid)
                )
                .every(
                    (assetLine) =>
                        assetLine.increaseExistingAsset ===
                        assetLinesList.assetLines[0].increaseExistingAsset
                )
        );
    }, [assetLinesList?.assetLines, selectedAssetIds]);

    const previousNewParentGroupId = usePrevious(newParentGroupId);

    useEffect(() => {
        if (isEmpty(selectedAssetIds)) return;

        setDataToPostGroup(
            assetLinesList?.assetLines
                .filter((assetLine) =>
                    selectedAssetIds.includes(assetLine.uuid)
                )
                .map((assetLine) => ({
                    assetId: assetLine.uuid,
                    additionalCharge: assetLine.hasAdditionalCharge
                })) ?? []
        );
    }, [assetLinesList?.assetLines, selectedAssetIds]);

    useEffect(() => {
        if (newParentGroupId && previousNewParentGroupId !== newParentGroupId) {
            setCheckedArticlesForGrouping(undefined);
            setAssetIdOpened(newParentGroupId ?? null);
            setOpenDrawer(!openDrawer);
            setAssetIdOpened(newParentGroupId ?? null);
            setSelectedAssetAccountCode({ code: "", numOcc: 0 });

            const retriveArticle = assetLinesList?.assetLines.filter(
                (articleInList: AssetLineDto) => {
                    return articleInList.parentId === newParentGroupId;
                }
            );

            if (retriveArticle)
                for (let item of retriveArticle) {
                    item.parentId = newParentGroupId;
                }
        }
    }, [
        newParentGroupId,
        openDrawer,
        previousNewParentGroupId,
        assetLinesList?.assetLines
    ]);

    useEffect(() => {
        if (checkedArticlesForGrouping && newParentGroupId) {
            // instead of redoing the get to get the updated data, I modify the assetLine by updating
            // the parentId

            Object.values(checkedArticlesForGrouping)[0].map(
                (article: AssetLineDto) => {
                    const retriveArticle = assetLinesList?.assetLines.find(
                        (articleInList: AssetLineDto) => {
                            return articleInList.uuid === article.uuid;
                        }
                    );
                    if (retriveArticle)
                        retriveArticle.parentId = newParentGroupId;
                    setCheckedArticlesForGrouping(undefined);
                    return null;
                }
            );
        }
    }, [
        checkedArticlesForGrouping,
        newParentGroupId,
        assetLinesList?.assetLines
    ]);

    /** I need to group by accountCode and show different tables */
    const groupedAccountCode = assetLinesList?.assetLines.reduce((acc, obj) => {
        const accountCode: string = obj.accountCode;
        // Checks whether the accountCode already exists as a key in the accumulator
        if (!acc[accountCode]) {
            // If the accountCode does not exist, create a new array with the current object as the first element
            acc[accountCode] = [obj];
        } else {
            // If the accountCode exists, add the current object to the existing array
            acc[accountCode].push(obj);
        }
        return acc;
    }, {} as { [key: string]: AssetLineDto[] });

    const handleAssetDetailClick = (
        parentId?: string | null,
        uuid?: string | null
    ) => {
        // Save the object info to send it on drawer
        if (uuid) {
            const assetSelectedIndex =
                assetLinesList?.assetLines.findIndex(
                    (assetObj: AssetLineDto) => assetObj.uuid === uuid
                ) ?? -1;
            if (assetSelectedIndex > -1)
                setAssetInfoOpened(
                    assetLinesList?.assetLines[assetSelectedIndex]
                );
        } else {
            setAssetInfoOpened(undefined);
        }
        setAssetIdOpened((parentId || uuid) ?? null);
        setOpenDrawer(!openDrawer);
        if (isGroupingAsset) {
            setSelectedAssetIds([]);
        }
        if (openDrawer) {
            getDraftAsset();
        }
        setIsGroupingAsset(false);
    };

    const handleToggleAsset = (asset: AssetLineDto, checked: boolean) => {
        setSelectedAssetIds(xor(selectedAssetIds, [asset?.uuid]));
        const currentCode = selectedAssetAccountCode.code;
        const newCode = asset?.accountCode;

        if (currentCode === "" && checked) {
            setSelectedAssetAccountCode({ code: newCode, numOcc: 1 });
        } else if (currentCode === newCode) {
            const newNumOcc = checked
                ? selectedAssetAccountCode.numOcc + 1
                : selectedAssetAccountCode.numOcc - 1;
            setSelectedAssetAccountCode({
                code: newNumOcc > 0 ? newCode : "",
                numOcc: newNumOcc > 0 ? newNumOcc : 0
            });
        }

        const alreadyHasIncreaseExistingAsset = assetLinesList?.assetLines
            ?.filter((asset) => selectedAssetIds?.includes(asset?.uuid))
            .some((asset) => asset?.increaseExistingAsset);

        if (selectedAssetIds?.length > 0) {
            update(
                { increaseExistingAsset: alreadyHasIncreaseExistingAsset },
                asset?.uuid
            );
        }
    };

    return (
        <>
            <Stack direction="row" justifyContent="flex-end">
                <Button
                    variant="contained"
                    sx={{ ml: 1 }}
                    disabled={
                        selectedAssetIds.length < 2 ||
                        isGroupingAsset ||
                        !isIncrementalSpendingGrouped
                    }
                    onClick={() => {
                        fetchPostData();
                        setIsGroupingAsset(true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faObjectGroup}
                        style={{ marginRight: "16px" }}
                    />
                    {fs("buttons.newAssetGroup")}
                </Button>
            </Stack>
            <Spinner loading={loadingAssetLines}>
                {assetLinesList?.assetLines.length === 0 && (
                    <InfoBanner message={fs("alert.noAssets")} />
                )}
                {groupedAccountCode &&
                    Object.keys(groupedAccountCode).map((assetGroup: any) => (
                        <React.Fragment
                            key={groupedAccountCode[assetGroup][0]?.uuid}
                        >
                            <Box sx={{ margin: "16px 0" }}>
                                <Typography
                                    variant="bodyLarge500"
                                    color="primary.textTitleColor"
                                >
                                    {
                                        groupedAccountCode[assetGroup][0]
                                            ?.accountDesc
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: "16px" }}>
                                <AssetTable
                                    draftId={draftId}
                                    groupedAccountCodeGroup={
                                        groupedAccountCode[assetGroup]
                                    }
                                    isActive={isActive}
                                    onAssetDetailClick={handleAssetDetailClick}
                                    refCurrency={refCurrency}
                                    onToggleAsset={handleToggleAsset}
                                    selectedAssetIds={selectedAssetIds}
                                    selectedAssetAccountCode={
                                        selectedAssetAccountCode
                                    }
                                    isGroupingAsset={isGroupingAsset}
                                    newParentGroupId={newParentGroupId}
                                    readOnly={readOnly}
                                />
                            </Box>
                            {openDrawer && (
                                <AssetDrawer
                                    assetIdOpened={assetIdOpened}
                                    assetInfoOpened={assetInfoOpened}
                                    businessId={businessId}
                                    draftId={draftId}
                                    handleDrawer={handleAssetDetailClick}
                                    isIncrementalSpending={
                                        !!assetInfoOpened?.increaseExistingAsset
                                    }
                                    isActive={isActive}
                                    openDrawer={openDrawer}
                                    refCurrency={refCurrency}
                                    newParentGroupId={newParentGroupId}
                                    assetGroupName={
                                        assetLinesList?.assetLines.find(
                                            (assetLine) =>
                                                assetLine.parentId ===
                                                assetIdOpened
                                        )?.assetGroupName ?? undefined
                                    }
                                    isGroupingAsset={isGroupingAsset}
                                    onCancelGrouping={() => {
                                        setSelectedAssetIds([]);
                                        setSelectedAssetAccountCode({
                                            code: "",
                                            numOcc: 0
                                        });
                                        setIsGroupingAsset(false);
                                    }}
                                    readOnly={readOnly ?? false}
                                />
                            )}
                        </React.Fragment>
                    ))}
            </Spinner>
        </>
    );
};

export default Assets;
