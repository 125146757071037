import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { intersection, sumBy } from "lodash";
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm
} from "react-hook-form";
import { usePaymentsTotalAmount } from "../../../../core/domain/Draft/Payments/hooks";
import {
    useCreatePayment,
    usePayments,
    useTotalToPay
} from "../../../../core/domain/Draft/Payments/queries";

import { useDraft } from "../../../../core/domain/Draft/queries";
import { useFiscalPayments } from "../../../../core/domain/FiscalPayment/queries";
import { FiscalPayment as FiscalPaymentsDto } from "../../../../core/usecases/dtos/FiscalPaymentDto";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import { PaymentType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../CustomButton";
import FormattedAmount from "../../FormattedAmount";
import FormattedDate from "../../FormattedDate";
import FormCurrencyField from "../../FormFields/FormCurrencyField";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";

interface PaymentAmount {
    paymentId: string;
    amount: number;
    selected: boolean;
    fiscalPayment: FiscalPaymentsDto;
}

interface FormValues {
    payments: PaymentAmount[];
}

const fs = getFormattedStringWithScope("components.Accounting.PaymentsDrawer");

function getAlreadySelectedPaymentIds(
    payments: Payment[],
    fiscalPayments: FiscalPaymentsDto[]
) {
    return intersection(
        payments.map((payment) => payment.registeredPaymentId),
        fiscalPayments.map((fiscalPayment) => fiscalPayment.key)
    );
}

interface FiscalPaymentsFormProps {
    draftId: string;
    payments: Payment[];
    fiscalPayments: FiscalPaymentsDto[];
    onSubmit?: () => void;
    paymentType: PaymentType;
    refCurrency: string;
    totalToPay: number;
}
const FiscalPaymentsForm = (props: FiscalPaymentsFormProps) => {
    const {
        draftId,
        fiscalPayments,
        payments,
        paymentType,
        refCurrency,
        totalToPay
    } = props;

    const { mutateAsync, isPending: isCreatingPayments } =
        useCreatePayment(draftId);

    const alreadySelectedPaymentIds = getAlreadySelectedPaymentIds(
        payments,
        fiscalPayments
    );

    const methods = useForm<FormValues>({
        defaultValues: {
            payments: fiscalPayments.map((fiscalPayment) => ({
                amount:
                    payments.find(
                        (payment) =>
                            payment.registeredPaymentId === fiscalPayment.key
                    )?.amount ?? 0,
                paymentId: fiscalPayment.key,
                selected: alreadySelectedPaymentIds.includes(fiscalPayment.key),
                fiscalPayment: fiscalPayment
            }))
        }
    });

    const onCancel = () => methods.reset();

    const paymentAmounts = methods.watch("payments");

    const remainingAmount =
        totalToPay -
        sumBy(
            paymentAmounts.filter(
                (payment) => payment.selected && !!payment.amount
            ),
            "amount"
        );

    const onSubmit: SubmitHandler<FormValues> = async ({ payments }) => {
        await mutateAsync({
            data: payments
                .filter((payment) => payment.selected)
                .map((payment) => ({
                    type: payment.fiscalPayment.type,
                    amount: payment.amount,
                    date: payment.fiscalPayment.docDate,
                    docNumber: payment.fiscalPayment.docNumber,
                    registeredPaymentId: payment.fiscalPayment.key
                })),
            overwrite: true
        });
        props.onSubmit && props.onSubmit();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Typography variant="titleSmall" color="#005075">
                    {fs(`title.${paymentType}`)}
                </Typography>
                <Table
                    sx={{
                        marginTop: "16px"
                    }}
                >
                    <TableHead shadow>
                        <TableHeadCell></TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("docDate")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("description")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>{fs("amount")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>
                                {fs("associatedAmount")}
                            </TableHeadText>
                        </TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {paymentAmounts.map((payment, index) => {
                            const fiscalPayment = fiscalPayments.find(
                                (fiscalPayment) =>
                                    fiscalPayment.key === payment.paymentId
                            );

                            if (!fiscalPayment) {
                                return null;
                            }

                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            maxWidth: "48px"
                                        }}
                                    >
                                        <Controller
                                            control={methods.control}
                                            name={`payments.${index}.selected`}
                                            render={({ field }) => {
                                                return (
                                                    <Checkbox
                                                        checked={field.value}
                                                        onChange={(
                                                            _,
                                                            checked
                                                        ) =>
                                                            field.onChange(
                                                                checked
                                                            )
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedDate
                                            date={fiscalPayment.date}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {`${fiscalPayment.reasonDescription} ${fiscalPayment.docNumber}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <FormattedAmount
                                            amount={
                                                fiscalPayment.remainingAmount
                                                    .amount
                                            }
                                            currency={
                                                fiscalPayment.remainingAmount
                                                    .currency
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <FormCurrencyField
                                            name={`payments.${index}.amount`}
                                            required={true}
                                            currency={
                                                fiscalPayment.remainingAmount
                                                    .currency
                                            }
                                            readOnly={!payment.selected}
                                            validate={(amount) => {
                                                if (!payment.selected) {
                                                    return true;
                                                }

                                                return !!amount && amount > 0;
                                            }}
                                            isAllowed={({ floatValue }) =>
                                                (floatValue ?? 0) <=
                                                fiscalPayment.remainingAmount
                                                    .amount
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="flex-end"
                    marginTop="16px"
                    paddingRight="16px"
                    spacing={1}
                >
                    <Typography variant="body700">
                        {fs("residualAmount")}
                    </Typography>
                    <FormattedAmount
                        amount={remainingAmount}
                        currency={refCurrency}
                    />
                </Stack>
                <Typography variant="body" color="red">
                    {remainingAmount < 0 &&
                        fs("paymentSumCannotExceedDocumentAmount")}
                </Typography>
                <Box marginLeft="-16px">
                    <VaporToolbar
                        contentRight={[
                            <Button variant="outlined" onClick={onCancel}>
                                {fs("cancel")}
                            </Button>,
                            <CustomButton
                                loading={isCreatingPayments}
                                variant="contained"
                                disabled={remainingAmount < 0}
                                type="submit"
                            >
                                {fs("save")}
                            </CustomButton>
                        ]}
                    />
                </Box>
            </form>
        </FormProvider>
    );
};

type FiscalPaymentsProps = {
    draftId: string;
    type: "CREDIT_NOTE" | "ADVANCE";
    onCreationSuccess?: () => void;
    onCancel?: () => void;
};
export default function FiscalPayments({
    draftId,
    type,
    onCreationSuccess
}: FiscalPaymentsProps) {
    const {
        data: payments,
        isLoading: isLoadingPayments,
        isSuccess: isSuccessPayments
    } = usePayments({
        draftId,
        paymentType: type
    });
    const paymentType =
        type === "CREDIT_NOTE" ? PaymentType.CREDIT_NOTE : PaymentType.PAYMENT;
    const {
        data: totalToPay,
        isLoading: isLoadingTotalToPay,
        isSuccess: isSuccessTotalToPay
    } = useTotalToPay(draftId);
    const paymentsTotalAmount = usePaymentsTotalAmount(draftId);
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    const { refCurrency } = draft ?? { refCurrency: "EUR" };

    const {
        data: allFiscalPayments,
        isLoading: isLoadingFiscalPayments,
        isSuccess: isSuccessFiscalPayments
    } = useFiscalPayments({
        active: draft?.doc.active!,
        businessId: draft?.businessId!,
        draftId: draft?.id!,
        factType: draft?.factType!,
        reasonId: draft?.reasonId!,
        types: [paymentType!]
    });

    const fiscalPayments = allFiscalPayments.filter((fiscalPayment) =>
        [draft?.customerTaxId, draft?.supplierTaxId].includes(
            fiscalPayment.taxId
        )
    );

    if (
        isLoadingFiscalPayments ||
        isLoadingTotalToPay ||
        isLoadingPayments ||
        isLoadingDraft ||
        isLoadingFiscalPayments
    ) {
        return <CircularProgress />;
    }

    if (
        !isSuccessTotalToPay ||
        !isSuccessPayments ||
        !isSuccessDraft ||
        !isSuccessFiscalPayments
    ) {
        return null;
    }

    return (
        <FiscalPaymentsForm
            draftId={draftId}
            fiscalPayments={fiscalPayments}
            payments={payments}
            paymentType={paymentType}
            refCurrency={refCurrency}
            totalToPay={
                totalToPay - (paymentsTotalAmount - sumBy(payments, "amount"))
            }
            onSubmit={onCreationSuccess ? onCreationSuccess : undefined}
        />
    );
}
