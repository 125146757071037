import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet, useRequest } from "@onefront/react-sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ASYNC_API_URL, POLLING_INTERVAL_MS } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { BusinessSettlement, WorkspaceSettlement } from "./dto";

const useGetWorkspaceSettlements = () => {
    const { fetch } = useGet<WorkspaceSettlement[]>(`ignored`, {
        ...useAxiosConfigRequestParams("workspace-settlements"),
        lazy: true
    });

    return async (workspaceId: string, year?: number) => {
        return fetch({
            url: `${ASYNC_API_URL}/vat/workspace-settlements`,
            params: { workspaceId, year }
        }).then((response) => response.data);
    };
};

export const workspaceSettlementsKeys = createQueryKeys(
    "workspace-settlements",
    {
        get: (workspaceId: string, year?: number) => [{ workspaceId, year }]
    }
);

export const useWorkspaceSettlements = (year?: number) => {
    const { workspaceId } = useCustomerData();
    const fetchWorkspaceSettlements = useGetWorkspaceSettlements();

    const { queryKey } = workspaceSettlementsKeys.get(workspaceId, year);

    return useQuery({
        queryKey,
        refetchInterval: ({ state: { data: settlements = [] } }) => {
            const shouldPoll = settlements.some(
                (settlement) =>
                    settlement.state === "CLOSING" ||
                    settlement.state === "OPENING"
            );

            return shouldPoll && POLLING_INTERVAL_MS;
        },
        queryFn: () => fetchWorkspaceSettlements(workspaceId, year)
    });
};

const useGetBusinessSettlements = () => {
    const { fetch } = useGet<BusinessSettlement[]>(`ignored`, {
        ...useAxiosConfigRequestParams("business-settlements"),
        lazy: true
    });

    return async (
        workspaceId: string,
        businessId: string,
        year: number,
        ordinal: string
    ) => {
        return fetch({
            url: `${ASYNC_API_URL}/vat/business-settlements`,
            params: { workspaceId, businessId, year, ordinal }
        }).then((response) => response.data);
    };
};

export const businessSettlementsKeys = createQueryKeys("business-settlements", {
    get: (
        workspaceId: string,
        businessId: string,
        year: number,
        ordinal: string
    ) => [{ workspaceId, businessId, year, ordinal }]
});

export const useBusinessSettlements = (
    businessId: string,
    year: number,
    ordinal: string
) => {
    const { workspaceId } = useCustomerData();
    const fetchBusinessSettlements = useGetBusinessSettlements();

    const { queryKey } = businessSettlementsKeys.get(
        workspaceId,
        businessId,
        year,
        ordinal
    );

    return useQuery({
        queryKey,
        queryFn: () =>
            fetchBusinessSettlements(workspaceId, businessId, year, ordinal)
    });
};

const usePostMutateSettlement = () => {
    const { fetch } = useRequest({
        ...useAxiosConfigRequestParams("mutate-workspace-settlement"),
        method: "POST",
        lazy: true
    });

    return async (
        action: string,
        workspaceId: string,
        year: number,
        ordinal: string
    ) => {
        return fetch({
            url: `${ASYNC_API_URL}/vat/workspace-settlements/${action}`,
            data: { workspaceId, year, ordinal }
        });
    };
};

export const useMutateSettlement = (year: number, ordinal: string) => {
    const { workspaceId } = useCustomerData();
    const mutatePostSettlement = usePostMutateSettlement();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (action: "open" | "close") =>
            mutatePostSettlement(action, workspaceId, year, ordinal),
        onSettled: () =>
            queryClient.invalidateQueries(
                workspaceSettlementsKeys.get(workspaceId)
            )
    });
};
