import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { IconButton, Skeleton, Stack, TableCell } from "@vapor/react-material";
import { capitalize, isNil, some } from "lodash";

import { useMutateAccountingLine } from "../../../../../core/domain/AccountingLines/queries";
import { UseAccountsOptions } from "../../../../../core/domain/Accounts/queries";
import { useDraft } from "../../../../../core/domain/Draft/queries";
import { AccountDto } from "../../../../../core/usecases/dtos/AccountsDto";
import { AccountingGroup } from "../../../../../core/usecases/interfaces/AccountingGroup";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import { hasAccountingLines } from "../../../../../utils/reasonUtils";
import AccountType from "../../../../components/AccountType";
import FormattedAmount from "../../../../components/FormattedAmount";
import SelectAccount from "../SelectAccount";

interface AccountCellProps extends Omit<UseAccountsOptions, "searchString"> {
    draftId: string;
    isOpen: boolean;
    onToggleRow: () => void;
    accountingGroup: AccountingGroup;
    readOnly?: boolean;
    showAccountType?: boolean;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable"
);

export default function AccountCell({
    draftId,
    isOpen,
    onToggleRow,
    accountingGroup,
    readOnly,
    showAccountType = true,
    ...rest
}: AccountCellProps) {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutate } = useMutateAccountingLine({
        draftId,
        accountingLineId: accountingGroup.children.map((line) => line.uuid!)
    });

    const handleChangeAccount = (account: AccountDto) => {
        mutate({
            accountCode: account.code,
            accountDesc: account.description
        });
    };

    if (isLoading) {
        return <Skeleton variant="rectangular" />;
    }

    if (isSuccess) {
        const { refCurrency } = draft;

        const isBank = accountingGroup.children.some(
            (accountingLine) => accountingLine.isBank
        );
        const canRowExpand =
            hasAccountingLines(draft.reasonId) &&
            !isBank &&
            accountingGroup.children.length !== 0;

        const isAccountingReadOnly = some(
            accountingGroup.children,
            (accountingLine) => accountingLine.isReadOnly && !isBank
        );

        return (
            <TableCell
                size="small"
                sx={{
                    minWidth: "300px"
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack width="100%" justifyContent="center">
                        <Stack
                            direction="row"
                            width="100%"
                            alignItems="center"
                            // height is set to 40px to have a coherent height with the other table rows
                            height="40px"
                            sx={{
                                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                    height: "16px",
                                    minHeight: "0px"
                                },
                                "& input": {
                                    padding: "0 !important"
                                },
                                "& fieldset": {
                                    marginTop: "-4px",
                                    marginBottom: "-4px",
                                    marginLeft: "-2px",
                                    marginRight: "24px",
                                    border: "none"
                                }
                            }}
                        >
                            {canRowExpand && !isAccountingReadOnly && (
                                <IconButton size="small" onClick={onToggleRow}>
                                    <VaporIcon
                                        icon={
                                            isOpen ? faChevronUp : faChevronDown
                                        }
                                    />
                                </IconButton>
                            )}
                            {!isAccountingReadOnly ? (
                                <SelectAccount
                                    draftId={draftId}
                                    value={{
                                        title: capitalize(
                                            accountingGroup.accountDesc
                                        ),
                                        code: accountingGroup.accountCode,
                                        description: accountingGroup.accountDesc
                                    }}
                                    onChange={(account) => {
                                        handleChangeAccount(account);
                                    }}
                                    readOnly={readOnly}
                                    {...rest}
                                />
                            ) : (
                                <Typography
                                    variant="body"
                                    sx={{
                                        textWrap: "nowrap",
                                        marginRight: "8px"
                                    }}
                                >
                                    {capitalize(accountingGroup.accountDesc)}
                                </Typography>
                            )}
                        </Stack>
                        {!isNil(accountingGroup.previousBalance) ? (
                            <Stack direction="row">
                                <Typography
                                    variant="bodySmall"
                                    marginRight="4px"
                                >
                                    {fs("previousBalance")}:
                                </Typography>
                                <FormattedAmount
                                    variant="bodySmall"
                                    amount={accountingGroup.previousBalance}
                                    currency={refCurrency}
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                    {showAccountType && (
                        <AccountType type={accountingGroup.type} />
                    )}
                </Stack>
            </TableCell>
        );
    }

    return null;
}
