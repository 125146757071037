import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { API_URL, POLLING_INTERVAL_MS } from "../../../config";
import { TaskStatus } from "../../usecases/dtos/TaskStatus";
import useGetAccountingPeriodTasks from "../../usecases/useGetAccountingPeriodTasks";
import { AccountingPeriodsDto } from "../../usecases/dtos/AccountingPeriodsDto";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { useGet } from "@onefront/react-sdk";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";

//API

export const useGetAccountingPeriods = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<AccountingPeriodsDto>(
        `${API_URL}/v1/ledger/accountingPeriods`,
        {
            ...useAxiosConfigRequestParams("GetAccountingPeriods"),
            lazy: true
        }
    );

    return async () =>
        fetch({
            params: {
                ...customerData
            }
        }).then(({ data }) => data);
};

export const accountingPeriodQueryKeys = createQueryKeys("accounting-periods", {
    all: null,
    getTasks: (accountingPeriodId: string) => [accountingPeriodId],
    getAll: () => ["allAccountingPeriods"],
    getOne: (accountingPeriodId: string) => [accountingPeriodId]
});

export const useAccountingPeriodTasks = (accountingPeriodId: string) => {
    const { fetch } = useGetAccountingPeriodTasks();
    return useQuery({
        ...accountingPeriodQueryKeys.getTasks(accountingPeriodId),
        refetchInterval: ({ state }) => {
            const { data } = state;

            const pendingTaskStatuses: TaskStatus[] = [
                TaskStatus.REOPENING,
                TaskStatus.SIMULATING,
                TaskStatus.GENERATING,
                TaskStatus.CANCELING
            ];
            const shouldPoll = data?.tasks.some(({ state }) =>
                pendingTaskStatuses.includes(state)
            );

            return shouldPoll && POLLING_INTERVAL_MS;
        },
        queryFn: () => fetch(accountingPeriodId)
    });
};

export const useAccountingPeriod = (accountingPeriodId?: string) => {
    const fetch = useGetAccountingPeriods();
    return useQuery({
        ...accountingPeriodQueryKeys.getOne(accountingPeriodId!),
        queryFn: fetch,
        select: (data) =>
            data.periods.find((period) => period.id === accountingPeriodId),
        enabled: !!accountingPeriodId
    });
};

export const useAccountingPeriods = () => {
    const fetch = useGetAccountingPeriods();
    return useQuery({
        ...accountingPeriodQueryKeys.getAll(),
        queryFn: fetch,
        select: (data) => data.periods
    });
};
