import { QueryParam } from "../../utils/appEnums";
import { makeUrl, url } from "../../utils/urlBuilder";

export const BALANCE_BASE_URL = "bilancio";

const routes = {
    home: () => "/",
    balance: (startDate?: string, endDate?: string) =>
        makeUrl(
            url(BALANCE_BASE_URL)
                .queryParam(QueryParam.startDate, startDate)
                .queryParam(QueryParam.endDate, endDate)
        ),
    vat: (businessId?: string, vatCode?: string, journalType?: string) =>
        makeUrl(
            url("iva")
                .queryParam(QueryParam.businessId, businessId)
                .queryParam(QueryParam.vatCode, vatCode)
                .queryParam(QueryParam.journalType, journalType)
        ),
    vatSettlements: () => "/vat-settlements",
    ledger: (
        accountId?: string,
        accountName?: string,
        startDate?: string,
        endDate?: string,
        callbackUrl?: string
    ) =>
        makeUrl(
            url("partitario")
                .queryParam(QueryParam.accountId, accountId)
                .queryParam(QueryParam.accountName, accountName)
                .queryParam(QueryParam.startDate, startDate)
                .queryParam(QueryParam.endDate, endDate)
                .queryParam(QueryParam.callbackUrl, callbackUrl)
        ),
    chartAccounts: () => "/chartAccounts",
    dashboard: () => "/dashboard",
    assetsList: () => "/assetsList",
    withholdings: () => "/withholdings",
    usedGoods: () => "/usedGoods",
    detail: (draftId?: string) =>
        draftId ? `/detail?draftId=${draftId}` : "/detail",
    accountingPeriods: () => "/accountingPeriods",
    newRegistration: () => "/nuova-registrazione",
    settleAccount: (businessId?: string) =>
        makeUrl(
            url("settle-account").queryParam(QueryParam.businessId, businessId)
        ),
    accountingSetup: () => "/accounting-setup",
    error: () => makeUrl(url("error"))
};

export default routes;
