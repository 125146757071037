import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { ASYNC_API_URL } from "../../../config";
import { useGet } from "@onefront/react-sdk";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

//API

export const useGetLastResultDestination = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<string>(
        `${ASYNC_API_URL}/accounting-periods/last-result-destination`,
        {
            ...useAxiosConfigRequestParams("PostLastResultDestination"),
            lazy: true
        }
    );

    return async (accountingPeriodId: string): Promise<string> =>
        fetch({ params: { ...customerData, accountingPeriodId } }).then(
            ({ data }) => data
        );
};

export const lastResultDestinationQueryKeys = createQueryKeys(
    "accounting-periods",
    {
        all: null,
        getLastResultDestination: (accountingPeriodId: string) => [
            accountingPeriodId
        ]
    }
);

export const useLastResultDestination = (accountingPeriodId: string) => {
    const fetch = useGetLastResultDestination();
    return useQuery({
        ...lastResultDestinationQueryKeys.getLastResultDestination(
            accountingPeriodId
        ),
        queryFn: () => fetch(accountingPeriodId)
    });
};
