import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { POLLING_INTERVAL_MS } from "../../../config";
import { TaskStatus } from "../../usecases/dtos/TaskStatus";
import { useCancelRegister as useCancelRegisterApi } from "../../usecases/useCancelRegister";
import { useGenerateRegister as useGenerateRegisterApi } from "../../usecases/useGenerateRegister";
import { useGetAvailableRegisters } from "../../usecases/useGetAvailableRegisters";

export const registerQueryKeys = createQueryKeys("registers", {
    all: null,
    get: (year: string) => [year]
});

export const useRegisters = ({ year }: { year: string }) => {
    const { fetch } = useGetAvailableRegisters();

    return useQuery({
        ...registerQueryKeys.get(year),
        queryFn: () => fetch(year),
        refetchInterval: ({ state }) => {
            const { data } = state;
            if (!data) {
                return false;
            }
            const register = data[0];
            const generating =
                register?.generated?.status === TaskStatus.PENDING;
            const canceling = register?.deleted?.status === TaskStatus.PENDING;
            const pendingOperation = generating || canceling;

            return pendingOperation && POLLING_INTERVAL_MS;
        }
    });
};

export const useGenerateRegister = (year: string) => {
    const { fetch } = useGenerateRegisterApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => fetch(year),
        onSuccess: () => {
            queryClient.invalidateQueries(registerQueryKeys.get(year));
        }
    });
};

export const useCancelRegister = (year: string) => {
    const { fetch } = useCancelRegisterApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => fetch(year),
        onSuccess: () => {
            queryClient.invalidateQueries(registerQueryKeys.get(year));
        }
    });
};
