import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import { AvailableRegistersDto } from "../../../../core/usecases/dtos/AvailableRegistersDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";

const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");

interface DeletedProps {
    deleted: AvailableRegistersDto["deleted"];
}

export default function Deleted({ deleted }: DeletedProps) {
    if (!deleted) return null;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
        >
            <Typography variant="body">{fs("deletionInProgress")}</Typography>

            <CustomButton
                variant="outlined"
                loading
                startIcon={<FontAwesomeIcon icon={faFileLines} />}
            >
                {fs("deletionInProgress")}
            </CustomButton>
        </Stack>
    );
}
