import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import { uniq } from "lodash";
import { useGenerateRegister } from "../../../../core/domain/Registers/queries";
import { AvailableRegistersDto } from "../../../../core/usecases/dtos/AvailableRegistersDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";

const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");
const fsPeriods = getFormattedStringWithScope("commons.period");

interface CreatableProps {
    creatable: AvailableRegistersDto["creatable"];
    year: string;
}

export default function Creatable({ creatable, year }: CreatableProps) {
    const { mutate, isPending } = useGenerateRegister(year);

    if (!creatable) return null;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
        >
            <Typography variant="body">
                {`${uniq(creatable?.periods.map(fsPeriods)).join(
                    " - "
                )}  ${year}`}
            </Typography>

            <CustomButton
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faFileLines} />}
                loading={isPending}
                onClick={() => mutate()}
            >
                {fs("generate")}
            </CustomButton>
        </Stack>
    );
}
