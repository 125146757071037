import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { POLLING_INTERVAL_MS } from "../../../config";
import useCreateDraft from "../../usecases/useCreateDraft";

import { DraftAccountingStatus, Reason } from "../../../utils/appEnums";
import useGetSetupActions from "../../usecases/useGetSetupActions";
import { useDeleteDraft } from "../Draft/queries";

export const setupActions = createQueryKeys("setup-actions", {
    all: null
});

export const useSetupActions = () => {
    const { fetch } = useGetSetupActions();

    return useQuery({
        ...setupActions.all,
        queryFn: () => fetch(),
        refetchInterval: ({ state }) => {
            const { data } = state;

            if (data) {
                const isSomeRegistrationProcessing =
                    data.PREVIOUS_BALANCES.registrations.some((action) =>
                        [
                            DraftAccountingStatus.PROCESS_DELETE,
                            DraftAccountingStatus.PROCESS_CONFIRM,
                            DraftAccountingStatus.PROCESS_UPDATE
                        ].includes(action.status)
                    );

                return isSomeRegistrationProcessing
                    ? POLLING_INTERVAL_MS
                    : false;
            }

            return false;
        }
    });
};

export const useCreateSetupDraft = () => {
    const queryClient = useQueryClient();

    const { create } = useCreateDraft();

    return useMutation({
        mutationKey: ["create-setup-draft"],
        mutationFn: () => create(Reason.CA21),
        onSuccess: () => {
            queryClient.invalidateQueries(setupActions.all);
        }
    });
};

export const useDeleteSetupDraft = (draftId: string) => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useDeleteDraft(draftId);

    return useMutation({
        mutationKey: ["delete-setup-draft"],
        mutationFn: mutateAsync,
        onSuccess: () => {
            queryClient.invalidateQueries(setupActions.all);
        }
    });
};
